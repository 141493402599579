import React from 'react';
import { CircularProgress } from '@mui/material';

const Loader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',zIndex:"1"}}>
      <CircularProgress sx={{color : "#52459f" }} />
    </div>
  );
};

export default Loader;
