import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAccessToken, setAccessToken, setRefreshToken,fetchUserInfo,setUserName, setAccessTokenForMobile} from '../Services/authService';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

const Callback = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const fetchToken = async () => {
            const query = new URLSearchParams(window.location.search);
            const code = query.get('code');

            if (code) {
                try {
                    const data = await fetchAccessToken(code);
                    setAccessToken(data.access_token);
                    const userInfo = await fetchUserInfo(data.access_token);
                    setUserName(userInfo)                   
                    setRefreshToken(data.refresh_token);
                    
                    navigate('/');
                } catch (error) {
                    console.error('Error during token exchange:', error);
                }
            }
        };

        fetchToken();
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            if (Capacitor.isNativePlatform()) {
                const { value } = await Preferences.get({ key: 'code' });
                if (value) {
                    try {
                        const data = await fetchAccessToken(value);
                        setAccessTokenForMobile(data.access_token);
    
                        const userInfo = await fetchUserInfo(data.access_token);
                        setUserName(userInfo);
    
                        setRefreshToken(data.refresh_token);
                        navigate('/');
                    } catch (error) {
                        console.error('Error during token exchange:', error);
                    }
                }
            }
        };
    
        fetchData(); 
    }, []);

    return <div>Loading...</div>;
};

export default Callback;
