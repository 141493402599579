import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
// import closeIcon from "../../../assets/images/close.svg";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const DrawerMenu = (props) => {
  const navigate = useNavigate();

  const { open, onClose } = props;
  const classes = useStyles();
  const [listOpen, setListOpen] = useState(true);
  const [nesMenu, setNesMenu] = useState(true);

  const handleClick = () => {
    setListOpen(!listOpen);
    setNesMenu(false);
  };
  const handleDrawerClose = () => {
    onClose();
    setListOpen(true);
    setNesMenu(true);
  };
  function navPanelButtonClick(pageName) {
    setListOpen(false);
    setNesMenu(false);
  }
  const handleClickSubMenu = () => {
    setNesMenu(!nesMenu);
  };
  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <>
      <Drawer
        variant="temporary"
        anchor="bottom"
        open={open}
        onClose={handleDrawerClose}
        className={classes.drawer}
        ModalProps={{
          keepMounted: true,
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
          >
            <CloseIcon style={{ color: "#fff" }} onClick={handleDrawerClose} />
          </div>
          <List className={classes.mainMenu} onClick={handleDrawerClose}>
            <ListItemIcon className={classes.menuIcon}>
              <FiberManualRecordIcon className={classes.menuBullet} />
            </ListItemIcon>
            <ListItem key="Accounts'">
              <Link to="accounts" className={classes.linkStyle}>
                Accounts
              </Link>
            </ListItem>
            {/* </div> */}
          </List>

          <List style={{ paddingBottom: "0px" }}>
            <div onClick={handleClick} className={classes.mainMenu}>
              <ListItemIcon
                className={classes.menuIcon}
                style={{
                  cursor: "pointer",
                  marginLeft: "-6px",
                }}
              >
                {listOpen ? (
                  <KeyboardArrowDownIcon
                    style={{ color: "#aca3e6" }}
                    fontSize="medium"
                  />
                ) : (
                  <ChevronRightIcon
                    style={{ color: "#aca3e6" }}
                    fontSize="medium"
                  />
                )}
              </ListItemIcon>
              <ListItem style={{ paddingLeft: "6px", color: "#fff" }}>
                <ListItemText
                  primary="Pay & Transfer"
                  className={classes.txt}
                />
              </ListItem>
            </div>
            {/* submenu */}
            <Collapse in={listOpen} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                style={{ paddingBottom: "0px" }}
              >
                <ListItem className={classes.nestedList}>
                  <span className={classes.nes1}>
                    <FiberManualRecordIcon className={classes.menuBullet} />
                  </span>
                  <ListItemText primary="Pay a Bill" className={classes.txt} />
                </ListItem>

                <ListItem className={classes.nestedList}>
                  <span className={classes.nes1}>
                    <FiberManualRecordIcon className={classes.menuBullet} />
                  </span>
                  <ListItemText
                    primary="Domestic Transfer"
                    className={classes.txt}
                  />
                </ListItem>

                <ListItem
                  className={classes.nestedList}
                  onClick={handleClickSubMenu}
                >
                  <span className={classes.nes1}>
                    {nesMenu ? (
                      <KeyboardArrowDownIcon
                        style={{ color: "#aca3e6", marginLeft: "-6px" }}
                        fontSize="medium"
                      />
                    ) : (
                      <ChevronRightIcon
                        style={{ color: "#aca3e6", marginLeft: "-6px" }}
                        fontSize="medium"
                      />
                    )}
                  </span>
                  <ListItemText
                    primary="International Transfer"
                    className={classes.txt}
                    style={{ marginLeft: "-6px" }}
                  />
                </ListItem>

                <Collapse in={nesMenu} timeout="auto" unmountOnExit>
                  <List
                    style={{ paddingBottom: "0px" }}
                    component="div"
                    disablePadding
                    onClick={handleDrawerClose}
                  >
                    <ListItem
                      className={classes.nestedList}
                      key="Recent Payments"
                      style={{ paddingLeft: "50px" }}
                    >
                      <span className={classes.nes1}>
                        <FiberManualRecordIcon className={classes.menuBullet} />
                      </span>
                      <Link to="recentpayments" className={classes.linkStyle}>
                        <ListItemText
                          primary="Recent Payments"
                          className={classes.txt}
                        />
                      </Link>
                    </ListItem>
                    <ListItem
                      className={classes.nestedList}
                      onClick={() => navPanelButtonClick("Recent Payments")}
                      key="Add/modify"
                      style={{ paddingLeft: "50px" }}
                    >
                      <span className={classes.nes1}>
                        <FiberManualRecordIcon className={classes.menuBullet} />
                      </span>
                      <Link
                        to="addmodifybeneficiary"
                        className={classes.linkStyle}
                      >
                        <ListItemText
                          primary="Add/Modify Beneficiary"
                          className={classes.txt}
                        />
                      </Link>
                    </ListItem>
                    <ListItem
                      className={classes.nestedList}
                      onClick={() => navPanelButtonClick("Recent Payments")}
                      key="Send payment"
                      style={{ paddingLeft: "50px" }}
                    >
                      <span className={classes.nes1}>
                        <FiberManualRecordIcon className={classes.menuBullet} />
                      </span>
                      <Link to="sendpayment" className={classes.linkStyle}>
                        <ListItemText
                          primary="Send Payment"
                          className={classes.txt}
                        />
                      </Link>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Collapse>
          </List>

          <List style={{ paddingBottom: "0px", color: "#fff" }}>
            <div className={classes.mainMenu}>
              <ListItemIcon className={classes.menuIcon}>
                <FiberManualRecordIcon className={classes.menuBullet} />
              </ListItemIcon>
              <ListItem>
                <ListItemText
                  primary="Rewards & Deals"
                  className={classes.txt}
                />
              </ListItem>
            </div>
          </List>

          <List style={{ paddingBottom: "0px", color: "#fff" }}>
            <div className={classes.mainMenu}>
              <ListItemIcon className={classes.menuIcon}>
                <FiberManualRecordIcon className={classes.menuBullet} />
              </ListItemIcon>
              <ListItem>
                <ListItemText
                  primary="Tools & Investing"
                  className={classes.txt}
                />
              </ListItem>
            </div>
          </List>

          <List style={{ paddingBottom: "0px", color: "#fff" }}>
            <div className={classes.mainMenu}>
              <ListItemIcon className={classes.menuIcon}>
                <FiberManualRecordIcon className={classes.menuBullet} />
              </ListItemIcon>
              <ListItem>
                <ListItemText
                  primary="Security Center"
                  className={classes.txt}
                />
              </ListItem>
            </div>
          </List>

          <List style={{ paddingBottom: "0px", color: "#fff" }}>
            <div className={classes.mainMenu}>
              <ListItemIcon className={classes.menuIcon}>
                <FiberManualRecordIcon className={classes.menuBullet} />
              </ListItemIcon>
              <ListItem>
                <ListItemText
                  primary="Open an Account"
                  className={classes.txt}
                />
              </ListItem>
            </div>
          </List>

          <List style={{ paddingBottom: "0px", color: "#fff" }}>
            <div className={classes.mainMenu}>
              <ListItemIcon className={classes.menuIcon}>
                <FiberManualRecordIcon className={classes.menuBullet} />
              </ListItemIcon>
              <ListItem>
                <ListItemText
                  primary="Help & Support"
                  className={classes.txt}
                />
              </ListItem>
            </div>
          </List>
          <List style={{ paddingBottom: "15%", color: "#fff" }}>
            <ListItem className={classes.flexCenter}>
              <Button
                variant="contained"
                className={classes.logoutBtn}
                onClick={logout}
              >
                Logout
              </Button>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};
export default DrawerMenu;
