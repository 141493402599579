import axios from "axios";
import {
  getAccessToken,
  getAccessTokenForMobile,
  isTokenExpired,
  refreshAccessToken,
} from "../Authorization/Services/authService";
import endPoints, { BASE_URL } from "../../config/Api";
import { Capacitor } from "@capacitor/core";

// Generic debounce function
export function debounce(func, delay) {
  let timer;
  let resolveCallback;

  return (...args) => {
    clearTimeout(timer);

    // Create a new promise but resolve with `null` for cancellations
    const promise = new Promise((resolve, reject) => {
      resolveCallback = { resolve, reject };
    });

    timer = setTimeout(async () => {
      try {
        const result = await func(...args);
        resolveCallback?.resolve(result);
      } catch (error) {
        resolveCallback?.reject(error);
      } finally {
        resolveCallback = null;
      }
    }, delay);

    return promise;
  };
}

const getHeaders = async (etag = null) => {
  let token;
  if (Capacitor.isNativePlatform()) {
    token = await getAccessTokenForMobile();
    console.log("token", token);
  } else {
    token = getAccessToken();
  }

  if (isTokenExpired(token)) {
    token = await refreshAccessToken();
  }

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  if (Capacitor.isNativePlatform()) {
    headers["x-origin-channel"] = "Mobile";
  }

  if (etag) {
    headers["If-Match"] = etag;
  }

  return headers;
};

export const fetchAccountsData = async () => {
  const payload = {
    caseTypeID: "EVN-EPP-Work-SendPayment",
    processID: "pyStartCase",
    content: {},
  };

  try {
    const headers = await getHeaders();

    return await axios.post(`${BASE_URL}cases`, payload, {
      params: {
        viewType: "page",
      },
      headers: headers,
    });
  } catch (error) {
    console.error("Error fetching accounts data:", JSON.stringify(error));
    throw error;
  }
};

export const getDataForFromAccount = async (
  fromAccount,
  nextAssignmentId,
  eTag,
  isPayAgain
) => {
  const payload = {
    content: { FromAccount: fromAccount },
    pageInstructions: [],
  };

  const actionName = isPayAgain ? "PayAgain" : "ActionInitiatePayment";
  try {
    const headers = await getHeaders(eTag);

    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/${actionName}/refresh`,
      payload,
      {
        params: {
          refreshFor: ".FromAccount",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for FromAccount:", error);
    throw error;
  }
};

export const getDataForSendAmount = async (
  fromAccount,
  sendAmount,
  ReceiveCurrency,
  ReceiversCountryName,
  countryCode,
  nextAssignmentId,
  eTag,
  actionName
) => {
  const payload = {
    content: {
      EnterAmount: "",
      FromAccount: fromAccount,
      PaymentInfo: {
        CountryCode: countryCode,
        PaymentsPurposeCode: "",
      },
      ReceiveAmount: "",
      ReceiveCurrency: ReceiveCurrency,
      ReceiversCountryName: ReceiversCountryName,
      SendAmount: sendAmount,
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/${actionName}/refresh`,
      payload,
      {
        params: {
          refreshFor: ".SendAmount",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for SendAmount:", error);
    throw error;
  }
};

export const debouncedGetDataForSendAmount = debounce(
  getDataForSendAmount,
  800
);

export const getDataForReceiverCurrency = async (
  fromAccount,
  sendAmount,
  ReceiveAmount,
  receiverCountryName,
  receiveCurrency,
  countryCode,
  nextAssignmentId,
  eTag,
  actionName
) => {
  const payload = {
    content: {
      EnterAmount: "",
      FromAccount: fromAccount,
      PaymentInfo: {
        CountryCode: countryCode,
        PaymentsPurposeCode: "",
      },
      ReceiveAmount: ReceiveAmount,
      ReceiveCurrency: receiveCurrency,
      ReceiversCountryName: receiverCountryName,
      SendAmount: sendAmount,
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/${actionName}/refresh`,
      payload,
      {
        params: {
          refreshFor: ".ReceiveCurrency",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for ReceiveCurrency:", error);
    throw error;
  }
};

export const getDataForReceiverAmount = async (
  fromAccount,
  sendAmount,
  receiveAmount,
  receiveCurrency,
  receiverCountryName,
  countryCode,
  nextAssignmentId,
  eTag,
  actionName
) => {
  console.log("receiveAmount:--", receiveAmount);
  const payload = {
    content: {
      EnterAmount: "",
      FromAccount: fromAccount,
      PaymentInfo: {
        CountryCode: countryCode,
        PaymentsPurposeCode: "",
      },
      ReceiveAmount: receiveAmount,
      ReceiveCurrency: receiveCurrency,
      ReceiversCountryName: receiverCountryName,
      SendAmount: "",
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/${actionName}/refresh`,
      payload,
      {
        params: {
          refreshFor: ".ReceiveAmount",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for ReceiveAmount:", error);
    throw error;
  }
};

export const debouncedGetDataForReceiverAmount = debounce(
  getDataForReceiverAmount,
  800
);

export const getDataForReceiversCountryName = async (
  fromAccount,
  sendAmount,
  receiverCurrency,
  receiverCountryName,
  countryCode,
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      EnterAmount: "",
      FromAccount: fromAccount,
      PaymentInfo: {
        CountryCode: countryCode,
      },
      ReceiveAmount: receiverCurrency,
      ReceiveCurrency: "",
      ReceiversCountryFlagImg: "",
      ReceiversCountryName: receiverCountryName,
      Remarks: "",
      SendAmount: sendAmount,
      SendCurrencyFlagImg: "",
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/ActionInitiatePayment/refresh`,
      payload,
      {
        params: {
          refreshFor: ".ReceiversCountryName",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for FromAccount:", error);
    throw error;
  }
};

export const getDataForReceiversCountryNameRefresh = async (
  fromAccount,
  sendAmount,
  receiverCurrency,
  receiverCountryName,
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      EnterAmount: "",
      FromAccount: fromAccount,
      ReceiveAmount: "",
      ReceiveCurrency: receiverCurrency,
      ReceiversCountryFlagImg: "",
      ReceiversCountryName: receiverCountryName,
      Remarks: "",
      SendAmount: sendAmount,
      SendCurrencyFlagImg: "",
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/ActionInitiatePayment/refresh`,
      payload,
      {
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for ReceiversCountryName:", error);
    throw error;
  }
};

export const getDataForPurposeCodeChange = async (
  fromAccount,
  sendAmount,
  receiverCurrency,
  receiverCountryName,
  receiveAmount,
  paymentsPurposeCode,
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      EnterAmount: "",
      FromAccount: fromAccount,
      PaymentInfo: {
        CountryCode: "",
        PaymentsPurposeCode: paymentsPurposeCode,
      },
      ReceiveAmount: receiveAmount,
      ReceiveCurrency: receiverCurrency,
      ReceiversCountryFlagImg: "",
      ReceiversCountryName: receiverCountryName,
      Remarks: "",
      SendAmount: sendAmount,
      SendCurrencyFlagImg: "",
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/ActionInitiatePayment/refresh`,
      payload,
      {
        params: {
          refreshFor: ".PaymentInfo.PaymentsPurposeCode",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error(
      "Error fetching data for PaymentInfo.PaymentsPurposeCode:",
      error
    );
    throw error;
  }
};

export const getDataAfterFirstContinue = async (
  fromAccount,
  sendAmount,
  receiverCurrency,
  receiveAmount,
  receiverCountryName,
  countryCode,
  purposeCode,
  remarks,
  nextAssignmentId,
  eTag
) => {
  // payload condition
  let payload;
  if (purposeCode) {
    payload = {
      content: {
        EnterAmount: "",
        FromAccount: fromAccount,
        PaymentInfo: {
          CountryCode: countryCode,
          PaymentsPurposeCode: purposeCode,
        },
        PurposeofPaymentVisibility: true,
        ReceiveAmount: receiveAmount,
        ReceiveCurrency: receiverCurrency,
        ReceiversCountryName: receiverCountryName,
        SendAmount: sendAmount,
        Remarks: remarks,
      },
      pageInstructions: [],
    };
  } else {
    payload = {
      content: {
        EnterAmount: "",
        FromAccount: fromAccount,
        PaymentInfo: {
          CountryCode: countryCode,
        },
        ReceiveAmount: receiveAmount,
        ReceiveCurrency: receiverCurrency,
        ReceiversCountryName: receiverCountryName,
        SendAmount: sendAmount,
        Remarks: remarks,
      },
      pageInstructions: [],
    };
  }
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/ActionInitiatePayment`,
      payload,
      {
        params: {
          viewType: "page",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for getDataAfterFirstContinue:", error);
    throw error;
  }
};

export const getListOfBeneficiaries = async (receiverCountryName) => {
  const payload = {
    dataViewParameters: {
      Country: receiverCountryName,
      SearchText: "",
    },
  };
  try {
    const headers = await getHeaders();
    return await axios.post(
      `${BASE_URL}data_views/${endPoints.sendPaymentBeneficiaries.D_Page_Name}`,
      payload,
      {
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for getListOfBeneficiaries:", error);
    throw error;
  }
};

export const getDataForReviewBeneficiary = async (
  pyGuid,
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      AvailableRecipients: {
        pyGUID: pyGuid,
      },
      AddNewBeneficiary: "",
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/ActionEnterBeneficiaryDetails`,
      payload,
      {
        params: {
          viewType: "page",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error(
      "Error fetching data for getDataForReviewBeneficiary:",
      error
    );
    throw error;
  }
};

export const validateBeneficiaryDetails = async (
  selectedBeneDetails,
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      SelectedBeneDetails: selectedBeneDetails,
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/ValidateBeneficiaryDetails/refresh`,
      payload,
      {
        params: {
          refreshFor: ".ValidateAndSave",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for validateBeneficiaryDetails:", error);
    throw error;
  }
};
export const validateAndSaveDetails = async (
  selectedBeneDetails,
  nextAssignmentId,
  eTag,
  refreshFor
) => {
  const payload = {
    content: {
      SelectedBeneDetails: selectedBeneDetails,
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/ValidateBeneficiaryDetails/refresh`,
      payload,
      {
        params: {
          refreshFor: refreshFor,
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for validateBeneficiaryDetails:", error);
    throw error;
  }
};

export const validateBeneficiaryDetailsReviewScreen = async (
  nextAssignmentId,
  eTag,
  editableBeneDetails
) => {
  const {
    Edit,
    ValidateAndSave,
    AddressMessage,
    message,
    classID,
    Save,
    ValidBeneficiaryAddress,
    HideEdit,
    HideValidateButton,
    Country,
    ...rest
  } = editableBeneDetails;
  const payload = {
    content: {
      //SelectedBeneDetails: editableBeneDetails,
      SelectedBeneDetails: {
        Edit: true,
        ValidateAndSave: true,
        ...rest,
      },
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/ValidateBeneficiaryDetails`,
      payload,
      {
        params: {
          viewType: "page",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error(
      "Error fetching data for validateBeneficiaryDetailsReviewScreen:",
      error
    );
    throw error;
  }
};

export const getDataForFirstPreviousPage = async (nextAssignmentId, eTag) => {
  const payload = {
    content: {
      AvailableRecipients: {
        pyGUID: "",
      },
      AddNewBeneficiary: "",
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/navigation_steps/previous`,
      payload,
      {
        params: {
          viewType: "page",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error(
      "Error fetching data for getDataForFirstPreviousPage:",
      error
    );
    throw error;
  }
};

export const sendPaymentProcessSubmit = async (
  nextAssignmentId,
  eTag,
  actionName
) => {
  const payload = {
    content: {
      ConfirmAcknowledgement: true,
      PayAllCharges: true,
    },
    pageInstructions: [],
  };

  const viewType = actionName === "PayAgain" ? "form" : "page";
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/${actionName}`,
      payload,
      {
        params: {
          viewType: viewType,
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for sendPaymentProcessSubmit:", error);
    throw error;
  }
};

export const payAgainProcessSubmit = async (
  payload,
  nextAssignmentId,
  eTag,
  actionName
) => {
  const viewType = actionName === "PayAgain" ? "form" : "page";

  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/actions/${actionName}`,
      payload,
      {
        params: {
          viewType: viewType,
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for payAgainProcessSubmit:", error);
    throw error;
  }
};

export const sendPaymentFinalSubmit = async (eTag, caseID) => {
  try {
    const headers = await getHeaders(eTag);
    return await axios.get(
      `${BASE_URL}cases/${caseID}/views/PaymentCompleted`,
      {
        headers: headers,
      }
    );
  } catch (error) {
    console.error("Error fetching data for sendPaymentFinalSubmit:", error);
    throw error;
  }
};

export const getDataForReviewBenefiToAddBenefiPage = async (
  beneAddress,
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      SelectedBeneDetails: {
        BeneBankAddress: beneAddress,
        Edit: false,
      },
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/navigation_steps/previous`,
      payload,
      {
        params: {
          viewType: "page",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error(
      "Error fetching data for getDataForReviewBenefiToAddBenefiPage:",
      error
    );
    throw error;
  }
};

export const getDataForReviewToReviewBeneficiaryPage = async (
  nextAssignmentId,
  eTag
) => {
  const payload = {
    content: {
      ConfirmAcknowledgement: false,
      PayAllCharges: false,
    },
    pageInstructions: [],
  };
  try {
    const headers = await getHeaders(eTag);
    return await axios.patch(
      `${BASE_URL}assignments/${nextAssignmentId}/navigation_steps/previous`,
      payload,
      {
        params: {
          viewType: "page",
        },
        headers: headers,
      }
    );
  } catch (error) {
    console.error(
      "Error fetching data for getDataForReviewToReviewBeneficiaryPage:",
      error
    );
    throw error;
  }
};

// this method is used to get the country names in add/modify beneficiary
export const getListOfCountries = async () => {
  const payload = {
    dataViewParameters: {},
  };
  try {
    const headers = await getHeaders();
    return await axios.post(`${BASE_URL}data_views/D_GetCountryName`, payload, {
      headers: headers,
    });
  } catch (error) {
    console.error("Error fetching data for getListOfCountries:", error);
    throw error;
  }
};
