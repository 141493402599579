import React from "react";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import { useState, useEffect } from "react";
import {
  sendPaymentProcessSubmit,
  getDataForReviewToReviewBeneficiaryPage,
  sendPaymentFinalSubmit,
} from "../../CommonService/sendPaymentService";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import checkMark from "../../../assets/images/check_mark_green.svg";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CountrySpecificComponent from "./CountrySpecificComponent";
import warningIcon from "../../../assets/images/warningicon.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Loader from "../Loader";

const Review = ({
  onContinue,
  nextAssignmentId,
  eTag,
  setEtag,
  reviewDetails,
  onPrevious,
  setPreviousData,
  setFinalResponse,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [Checkcount, setcheckcount] = useState(0);
  const [checkbox, setcheckbox] = useState({
    check1: false,
    check2: false,
  });
  const [review_Details, setReviewDetails] = useState(
    reviewDetails.data.caseInfo.content
  );
  const [checkedItems, setCheckedItems] = useState({});
  const [checkbox_Details, setCheckboxDetails] = useState(
    reviewDetails.uiResources.resources.views?.SendPayment[0]?.children[0]
      .children
  );
  const [visible, setvisibile] = useState(true);
  const [transaction, settransaction] = useState(true);
  const [beniDetails, setbeniDetails] = useState(true);
  const [icon, seticon] = useState(false);
  const [removeicon, setremoveicon] = useState(false);
  const [beniIcon, setbeniIcon] = useState(false);
  const [open, setOpen] = useState(false);
  const actionName = "ActionSendPayment";
  const [loading, setLoading] = useState(false);
  const [toolopen, setToolOpen] = useState(false);

  const handleTipOpen = () => {
    setToolOpen(true);
  };
  const handleTipClose = () => {
    setToolOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePrevious = async () => {
    try {
      const response = await getDataForReviewToReviewBeneficiaryPage(
        nextAssignmentId,
        eTag
      );
      setEtag(response.headers["etag"]);
      const resourceData = response.data.data;
      setPreviousData(resourceData);
      onPrevious();
    } catch {}
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await sendPaymentProcessSubmit(
        nextAssignmentId,
        eTag,
        actionName
      );
      if (response.status === 200) {
        const res = await sendPaymentFinalSubmit(
          eTag,
          response?.data?.data?.caseInfo?.ID
        );
        if (res?.data?.data?.caseInfo) {
          setLoading(false);
          navigate("/moneysent", {
            state: {
              response: res?.data?.data?.caseInfo,
            },
          });
          // setFinalResponse(res?.data?.data?.caseInfo);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  /*   const handleCheckbox = (event) => {
    setcheckbox((check) => ({
      ...check,
      [event.target.name]: event.target.checked,
    }));
    setcheckcount((prev) => (event.target.checked ? prev + 1 : prev - 1));
  }; */

  const handlevisibility = () => {
    if (visible) {
      setvisibile(false);
    } else {
      setvisibile(true);
    }
    seticon((prev) => !prev);
  };
  const TransactioinVisible = () => {
    if (transaction) {
      settransaction(false);
    } else {
      settransaction(true);
    }
    setremoveicon((prev) => !prev);
  };
  const beneficiaryVisible = () => {
    if (beniDetails) {
      setbeniDetails(false);
    } else {
      setbeniDetails(true);
    }
    setbeniIcon((prev) => !prev);
  };

  useEffect(() => {
    const initialCheckedState = checkbox_Details.reduce((acc, data) => {
      if (data?.type === "Checkbox" && data?.config?.value) {
        // Assuming initial checked state can be based on some condition (e.g., if value starts with '@P .')
        acc[data.config.value.replace(/^@P \./, "")] = false; // Or true if you want it checked initially
      }
      return acc;
    }, {});
    setCheckedItems(initialCheckedState); // Set initial checked state
  }, [checkbox_Details]);

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked, // Toggle the checked state of the checkbox
    }));
  };
  const handleCancel = () => {
    navigate("/");
  };

  return (
    <div>
      <Grid container className={classes.header}>
        <Grid size={{ xs: 12 }} className={classes.headerDetails}>
          <Typography variant="body2" className={classes.headerBene}>
            Account Details
          </Typography>{" "}
          {!icon && (
            <RemoveCircleRoundedIcon
              onClick={handlevisibility}
              style={{ width: "9%" }}
            />
          )}
          {icon && (
            <AddCircleRoundedIcon
              onClick={handlevisibility}
              style={{ width: "9%" }}
            />
          )}
        </Grid>
      </Grid>
      {visible && (
        <>
          <Box /* className={classes.spacing} */>
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Account to be Debited
              </Typography>
              <Box>
                <Typography variant="body2" className={classes.contentstyle}>
                  {review_Details?.MaskAccountNumber}
                </Typography>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Available Balance
              </Typography>
              <Box className={classes.flex}>
                <Typography variant=" body2" className={classes.contentstyle}>
                  {review_Details?.AccountBalance}
                </Typography>
                <Box className={classes.fontCurrency}>
                  {review_Details?.SendCurrency}
                </Box>
                <CurrencyFlag
                  currency={review_Details?.SendCurrency}
                  className={classes.currencyStyle}
                />
              </Box>
            </Grid>
          </Box>
        </>
      )}
      <Grid container className={classes.header}>
        <Grid size={{ xs: 12 }} className={classes.headerDetails}>
          <Typography variant="body2" className={classes.headerBene}>
            {" "}
            Transaction Details
          </Typography>{" "}
          {!removeicon && (
            <RemoveCircleRoundedIcon
              onClick={TransactioinVisible}
              style={{ width: "9%" }}
            />
          )}
          {removeicon && (
            <AddCircleRoundedIcon
              onClick={TransactioinVisible}
              style={{ width: "9%" }}
            />
          )}
        </Grid>
      </Grid>
      {transaction && (
        <>
          <Box /* className={classes.spacing} */>
            <Grid container className={classes.flex}>
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Amount to be Sent
                </Typography>
                <Box className={classes.flex}>
                  <Typography className={classes.contentstyle}>
                    {review_Details?.SendAmount}
                  </Typography>
                  <Box className={classes.fontCurrency}>
                    {review_Details?.SendCurrency}
                  </Box>
                  <CurrencyFlag
                    currency={review_Details?.SendCurrency}
                    className={classes.currencyStyle}
                  />
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Transaction Fee
                </Typography>
                <Box className={classes.flex}>
                  <Typography variant="body2" className={classes.textFont}>
                    {" "}
                    {review_Details?.TransactionDesc}
                  </Typography>
                  <CurrencyFlag
                    currency={review_Details?.SendCurrency}
                    className={classes.currencyStyle}
                  />
                </Box>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Foreign Exchange Rate
                </Typography>
                <Typography className={classes.textFont}>
                  {review_Details?.CurrencyConvDesc}
                </Typography>
              </Grid>

              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Amount to be Received
                </Typography>
                <Box className={classes.flex}>
                  <Typography className={classes.contentstyle}>
                    {reviewDetails?.data?.caseInfo?.content.ReceiveCurrency ===
                    "JPY"
                      ? (reviewDetails?.data?.caseInfo?.content.ReceiveAmount).toFixed(
                          0
                        )
                      : reviewDetails?.data?.caseInfo?.content
                          .ReceiveAmount}{" "}
                    {reviewDetails?.data?.caseInfo?.content.ReceiveCurrency}
                  </Typography>
                  <CurrencyFlag
                    currency={
                      reviewDetails?.data?.caseInfo?.content.ReceiveCurrency
                    }
                    style={{
                      marginLeft: "5px",
                      width: "20px",
                      height: "20px",
                      borderRadius: "50px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      <Grid size={{ xs: 12, md: 6 }}>
        <Typography
          variant="body2"
          className={`${classes.headerstyle} ${classes.flexAlignCenter}`}
        >
          Estimated Delivery Time
          <Tooltip
            open={toolopen}
            onClose={handleTipClose}
            onOpen={handleTipOpen}
            disableHoverListener
            PopperProps={{
              disableInteractive: true,
            }}
            title="This is an estimated delivery time frame only based on how long similar payments have taken in the past. It is not a gaurantee that your payment will be delivered within this timeframe, which could take more or less time than this estimate."
          >
            <InfoOutlinedIcon
              className={classes.InfoOutlinedIcon}
              onClick={handleTipOpen}
            />
          </Tooltip>
        </Typography>
        <Typography className={classes.textFont}>
          {review_Details?.EstimatedDeliveryTime}
        </Typography>
      </Grid>

      {/*   <Box className={classes.spacing}>
            <Box className={classes.flex}>
                <InfoOutlined style={{color:"blue", fontSize:"1.2rem"}} /><Typography variant='body2' style={{marginLeft:"5px"}}>Enter BIC Code or ABA Number</Typography>
            </Box>  
        </Box> */}
      <Grid size={{ xs: 12, md: 6 }}>
        <Typography variant="body2" className={classes.headerstyle}>
          Exchange Rate Valid For
        </Typography>
        <Typography className={classes.textFont}>
          {review_Details?.FXRateGoodForText}
        </Typography>
      </Grid>
      {review_Details?.PaymentPurposeCode_RVW && (
        <Grid size={{ xs: 12, md: 6 }}>
          <Typography variant="body2" className={classes.headerstyle}>
            Purpose of Payment
          </Typography>
          <Typography variant="small">
            {review_Details?.PaymentPurposeCode_RVW}
          </Typography>
        </Grid>
      )}
      <Grid size={{ xs: 12, md: 6 }}>
        <Typography variant="body2" className={classes.headerstyle}>
          Remarks
        </Typography>
        <Typography className={classes.textFont}>
          {review_Details?.Remarks}
        </Typography>
      </Grid>

      <Grid container className={classes.header}>
        <Grid size={{ xs: 12 }} className={classes.headerDetails}>
          <Typography variant="body2" className={classes.headerBene}>
            {" "}
            Beneficiary and Bank Details
          </Typography>{" "}
          {!beniIcon && (
            <RemoveCircleRoundedIcon
              onClick={beneficiaryVisible}
              style={{ width: "9%" }}
            />
          )}
          {beniIcon && (
            <AddCircleRoundedIcon
              onClick={beneficiaryVisible}
              style={{ width: "9%" }}
            />
          )}
        </Grid>
      </Grid>
      {beniDetails && (
        <>
          {/*  <Box className={classes.spacing}>
            <Grid container size={{xs:12,md:12}}>
              <Grid size={{xs:12,md:6}}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Name
                </Typography>
                <Typography className={classes.textFont}>
                  {reviewDetails?.data?.caseInfo?.content?.SelectedBeneDetails?.FullName}
                </Typography>
              </Grid>
              <Grid size={{xs:12,md:6}}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Account Number
                </Typography>
                <Typography variant="body2" className={classes.textFont}>
                   {reviewDetails?.data?.caseInfo?.content?.SelectedBeneDetails?.MaskAccNumb} 
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.spacing}>
            <Grid container size={{xs:12,md:12}}>
              <Grid size={{xs:12,md:6}}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank BIC Code
                </Typography>
                <Typography className={classes.textFont}>
                  {reviewDetails?.data?.caseInfo?.content?.SelectedBeneDetails?.BICFI}
                </Typography>
              </Grid>
              <Grid size={{xs:12}}>
                <Typography className={classes.headerstyle}>
                  ABA Routing Number
                </Typography>
                <Typography className={classes.contentstyle}>--</Typography>
              </Grid>
              <Grid size={{xs:12,md:6}}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank Address
                </Typography>
                <Typography variant="body2" className={classes.textFont}>
                  {" "}
                  {reviewDetails?.data?.caseInfo?.content?.SelectedBeneDetails?.BeneBankAddress}
                </Typography>
              </Grid>
            </Grid>
          </Box> */}
          <CountrySpecificComponent
            reviewDetails={review_Details}
            review={true}
            flag={true}
          />
        </>
      )}
      {/* <Box className={classes.spacing}>
            <Typography variant='body2' className={classes.headerstyle}>Account Type</Typography>
            <Typography variant='body2' style={{fontSize:'13px'}}> {reviewDetails.SelectedBeneDetails.CdtrAcctTpPrtry}</Typography>
        </Box>
        <Box className={classes.spacing}>
                <Grid className={classes.flex}>
                    <Typography variant='body2' className={classes.headerstyle}>Payment Purpose Code</Typography>
                    <Typography variant='body2' style={{marginLeft:"34%"}}> {reviewDetails.PaymentPurposeCode_RVW}</Typography>
                </Grid>
        </Box> 
        */}
      {checkbox_Details.map((data, index) => {
        if (data?.type === "RadioButtons") {
          return (
            <Grid
              container
              className={classes.spacing}
              spacing={0.5}
              style={{ fontSize: "14px" }}
            >
              <Grid size={{ md: 12 }} className={classes.flexCenterAlign}>
                <img src={warningIcon} className={classes.warningIcon} />
                &nbsp;&nbsp;
                <Typography className={classes.message}>
                  {data.config.label?.replace(/@L\s*/, "")}
                </Typography>
              </Grid>
              <Grid size={{ md: 12, xs: 6 }} ml={5}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    row
                    style={{ display: "flex" }}
                  >
                    <div style={{ display: "flex" }}>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio size="small" />}
                        label={
                          <Typography className={classes.contentstyle}>
                            Yes
                          </Typography>
                        }
                        className={classes.RadioBtn}
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio size="small" />}
                        label={
                          <Typography className={classes.contentstyle}>
                            No
                          </Typography>
                        }
                        className={classes.RadioBtn}
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          );
        }
        if (data?.type === "Checkbox") {
          const checkboxName = data?.config?.value.replace(/^@P \./, "");
          return (
            <Box key={data.id} className={classes.spacing}>
              <Grid className={classes.flex}>
                <Checkbox
                  name={checkboxName}
                  className={classes.checkboxStyles}
                  checked={!!checkedItems[checkboxName]}
                  onChange={handleCheckbox}
                  style={{ color: "#52459f" }}
                />
                <Typography variant="body2" className={classes.textFont}>
                  {data.config.caption.replace(/@L\s*/, "")}
                </Typography>
              </Grid>
            </Box>
          );
        }
        return null;
      })}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.spacing}
        size={{ xs: 12, md: 12 }}
      >
        <Box className={classes.flex}>
          <Button className={classes.cancel} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className={classes.cancel}
            onClick={handlePrevious}
            style={{ marginLeft: "10px" }}
          >
            Back
          </Button>
        </Box>
        {loading && <Loader />}
        <Grid>
          <Button
            className={classes.continue}
            onClick={handleSubmit}
            disabled={!checkedItems.ConfirmAcknowledgement}
          >
            Send Payment
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.successDialog}
      >
        <DialogTitle id="alert-dialog-title">
          <Link to={"/recentpayments"}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              className={classes.closeiconPosition} // Position the close icon
            >
              <CloseIcon color="error" />
            </IconButton>
          </Link>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={classes.textCenter}>
              <img src={checkMark} width={100} alt="checkmark" height={100} />
              <Typography className={classes.txt1}>Money Sent</Typography>
              {/* <Typography className={classes.txt2}>Your transfer will be processed in next 30 minutes</Typography> */}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Home</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Review;
