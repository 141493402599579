import React from "react";
import CurrencyFlag from "react-currency-flags";
import flagsCode from "../CommonService/flagServies";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { TextareaAutosize } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { validateEditBeneficiaryFields } from "../CommonService/commonAppService";
import BeneficiaryAddress from "./CommonFields/BeneficiaryAddress";
import MobileStyles from "./MobileStyles";
import WebStyles from "./WebStyles";
import { useSelector } from "react-redux";
import BeneficiaryAddressReadOnly from "./CommonFields/BeneficiaryAddressReadOnly";
import AgenciaNumberField from "./CommonFields/AgenciaNumberField";
import Flag from "react-world-flags";
import AddressMessage from "./CommonFields/AddressMessage";
import { getCountryCode } from "../CommonUtils/CommonUtils";
import NickNameField from "./CommonFields/NickNameField";
import BeneficiaryNameField from "./CommonFields/BeneficiaryNameField";

const Brazil = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const newBeneData = useSelector((data) => data?.addNewBeneficiary);
  const errors = newBeneData?.error;

  const checkErrors = (value) => {
    if (!errors?.message?.data?.errorDetails) return null;

    const errorDetail = errors.message.data.errorDetails.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };
  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    nextAssignmentId,
    etag,
    // below is for  add/new beneficiary
    addNewBene,
    newBeneCountryName,
    handleChange,
    handleRefreshInputBlur,
    handleValidate,
  } = props;

  const SelectedBeneDetails = reviewDetails?.SelectedBeneDetails;

  const handleEditChange = async (event) => {
    const { name, value } = event.target;
    if (name === "BICFI" || name === "CdtrAcctIdIBAN") {
      const refereshFor = ".SelectedBeneDetails." + name;
      try {
        const payLoad = {
          content: {
            SelectedBeneDetails: {
              [name]: value,
              Edit: isEditable,
            },
          },
          pageInstructions: [],
        };
        const response = await validateEditBeneficiaryFields(
          nextAssignmentId,
          payLoad,
          etag,
          refereshFor
        );
        if (name === "BICFI" || name === "CdtrAcctIdIBAN") {
          setEditableBeneDetails((prevDetails) => ({
            ...prevDetails,
            BICFI:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BICFI,
            BankName:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BankName,
            BeneBankAddress:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.BeneBankAddress,
            CdtrAcctIdIBAN:
              response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
                ?.CdtrAcctIdIBAN,
            Edit: isEditable,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setEditableBeneDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
        Edit: isEditable,
      }));
    }
  };

  return (
    <>
      {review && !addNewBene && (
        <>
          <Grid container size={{ xs: 12, md: 12 }}>
            <BeneficiaryNameField
              beneDetails={SelectedBeneDetails}
              isEditable={false}
            />
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary IBAN
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.MaskIBANNumb
                  ? SelectedBeneDetails?.MaskIBANNumb
                  : "---"}
              </Typography>
            </Grid>
            <AgenciaNumberField
              editableBeneDetails={SelectedBeneDetails}
              flag={flag}
            />
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Account Type
              </Typography>
              <Typography className={classes.textFont}>
              
                {SelectedBeneDetails?.CdtrAcctTpPrtry
                  ? SelectedBeneDetails?.CdtrAcctTpPrtry
                  : "---"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Bank BIC Code
              </Typography>
              <Typography className={classes.textFont}>
                {SelectedBeneDetails?.BICFI
                  ? SelectedBeneDetails?.BICFI
                  : "---"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                CPF / Tax ID
              </Typography>
              <Typography className={classes.textFont}>
                {SelectedBeneDetails?.TaxID
                  ? SelectedBeneDetails?.TaxID
                  : "---"}
              </Typography>
            </Grid>
            <BeneficiaryAddressReadOnly reviewDetails={reviewDetails} />
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Bank Address
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {SelectedBeneDetails?.BeneBankName &&
                  SelectedBeneDetails?.BeneBankName}
              </Typography>
              <TextareaAutosize
                variant="outlined"
                fullWidth
                size="small"
                value={SelectedBeneDetails?.BeneBankAddress || "--"}
                readOnly
                className={classes.textarea}
              />
            </Grid>
          </Grid>
        </>
      )}
      {addNewBene ? (
        <>
          {newBeneData?.formFlag && (
            <>
              <Grid container spacing={1}>
                <Box sx={{ minWidth: 120 }}>
                  <Typography
                    pt={2}
                    style={{ color: "#52459f" }}
                    className={classes.headerstyle}
                  >
                    Please enter Beneficiary details:
                  </Typography>
                </Box>
                <NickNameField
                  handleChange={handleChange}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />
                <BeneficiaryNameField
                  beneDetails={newBeneData?.formState}
                  handleChange={handleChange}
                  isEditable={true}
                  checkErrors={checkErrors}
                  flag={true}
                  disableFlag={newBeneData?.submitFlag}
                />
                <Grid
                  size={{ md: 6 }}
                  className={`${classes.spacing} ${classes.flagAlignments}`}
                >
                  <Box>
                    <FormControl>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Beneficiary IBAN
                      </Typography>

                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="CdtrAcctIdIBAN"
                        error={Boolean(checkErrors("CdtrAcctIdIBAN"))}
                        helperText={checkErrors("CdtrAcctIdIBAN")}
                        disabled={newBeneData?.submitFlag}
                        onChange={handleChange}
                        onBlur={handleRefreshInputBlur}
                      />
                    </FormControl>
                  </Box>
                  {newBeneData?.formState?.IsValidBeneIBANURL && (
                    <Box sx={{ pt: 3 }}>
                      <img
                        src={newBeneData?.formState?.IsValidBeneIBANURL}
                        className={classes.checkMark}
                      />
                    </Box>
                  )}
                </Grid>
                <BeneficiaryAddress
                  Country={newBeneData?.countryName}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />
                <Grid size={{ md: 4, xs: 12 }} className={classes.spacing}>
                  <FormControl fullWidth>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Account Type
                    </Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="CdtrAcctTpPrtry"
                      error={Boolean(checkErrors("CdtrAcctTpPrtry"))}
                      helperText={checkErrors("CdtrAcctTpPrtry")}
                      disabled={newBeneData?.submitFlag}
                      onChange={handleChange}
                      size="small"
                    >
                      <MenuItem value={"Savings/General"}>
                        Savings/General
                      </MenuItem>
                      <MenuItem value={"Savings/Deposit"}>
                        Savings/Deposit
                      </MenuItem>
                      <MenuItem value={"Checking/Current"}>
                        Checking/Current
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid size={{ md: 2 }}></Grid>
                <Grid size={{ md: 6, xs: 12 }} className={`${classes.spacing} ${classes.flagAlignments}`}>
                <Box>
                  <FormControl>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Agencia Number
                    </Typography>

                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      name="ClrSysMmbId"
                      error={Boolean(checkErrors("ClrSysMmbId"))}
                      helperText={checkErrors("ClrSysMmbId")}
                      onBlur={handleRefreshInputBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                  </Box>
                  {newBeneData?.formState?.IsValidABANumberURL && (
                      <Box sx={{ pt: 3 }}>
                        <img
                          src={newBeneData?.formState?.IsValidABANumberURL}
                          className={classes.checkMark}
                        />
                      </Box>
                    )}
                </Grid>
                <Grid
                  size={{ md: 6 }}
                  className={`${classes.spacing} ${classes.flagAlignments}`}
                >
                  <Box>
                    <FormControl>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Beneficiary Bank BIC Code
                      </Typography>

                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="BICFI"
                        readOnly
                        onChange={handleChange}
                        value={newBeneData?.formState?.BICFI}
                        className={classes.textFieldReadOnly}
                      />
                    </FormControl>
                  </Box>

                  {newBeneData?.formState?.IsValidBeneBICCodeURL && (
                    <Box sx={{ pt: 3 }}>
                      <img
                        src={newBeneData?.formState?.IsValidBeneBICCodeURL}
                        className={classes.checkMark}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid size={{ md: 6, xs: 12 }} className={classes.spacing}>
                  <FormControl>
                    <Typography variant="body2" className={classes.headerstyle}>
                      CPF / Tax ID
                    </Typography>

                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      name="TaxID"
                      error={Boolean(checkErrors("TaxID"))}
                      helperText={checkErrors("TaxID")}
                      disabled={newBeneData?.submitFlag}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid size={{ md: 6, xs: 12 }} className={`${classes.spacing} `}>
                  <FormControl>
                    <Typography variant="body2" className={classes.headerstyle}>
                      Beneficiary Bank Name
                    </Typography>

                    <TextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      disabled
                      name="BeneBankName"
                      value={newBeneData?.formState?.BeneBankName || "---"}
                      className={classes.textFieldReadOnly}
                    />
                  </FormControl>
                </Grid>
                {newBeneData?.formState?.BeneBankAddress && (
                  <Grid
                    size={{ md: 6, xs: 12 }}
                    className={`${classes.spacing} ${classes.flagAlignments}`}
                  >
                    <Box>
                      <FormControl>
                        <Typography
                          variant="body2"
                          className={classes.headerstyle}
                        >
                          Beneficiary Bank Address
                        </Typography>
                        <TextareaAutosize
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={
                            newBeneData?.formState?.BeneBankAddress || "---"
                          }
                          readOnly
                          className={classes.textarea}
                        />
                      </FormControl>
                    </Box>
                    {newBeneData?.formState?.IsValidBeneAddressURL && (
                      <Box sx={{ pt: 3 }}>
                        <img
                          src={newBeneData?.formState?.IsValidBeneAddressURL}
                          className={classes.checkMark}
                        />
                      </Box>
                    )}
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </>
      ) : (
        (payAgainOrRepeatTransaction || !review) && (
          <>
            {!isMobile && !payAgainOrRepeatTransaction && (
              <Grid container className={classes.spacing}>
                <Grid size={{ xs: 6 }} className={classes.flexCenterAlign}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    <b>Pay to Country :</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    className={`${classes.flexCenterAlign} ${classes.Gap}`}
                  >
                    {editableBeneDetails?.Country} &nbsp;
                    <Flag
                      code={getCountryCode(editableBeneDetails?.Country)}
                      className={classes.countryFlag}
                    />
                  </Typography>
                </Grid>
                <Grid size={{ xs: 6 }}></Grid>
              </Grid>
            )}
            <NickNameField
              beneDetails={editableBeneDetails}
              isEditable={isEditable}
              handleChange={handleInputChange}
              handleBlurChange={handleEditChange}
              payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
            />
            <Grid container size={{ xs: 12, md: 12 }}>
              <BeneficiaryNameField
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                handleBlurChange={handleEditChange}
                isEditable={isEditable}
                flag={flag}
                checkErrors={checkErrors}
              />
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary IBAN
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.CdtrAcctIdIBAN}
                    onChange={handleInputChange}
                    onBlur={handleEditChange}
                    name="CdtrAcctIdIBAN"
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.CdtrAcctIdIBAN
                      ? editableBeneDetails?.CdtrAcctIdIBAN
                      : "---"}
                  </span>
                )}
                {flag && editableBeneDetails?.IsValidBeneIBANURL && (
                  <img
                    src={editableBeneDetails?.IsValidBeneIBANURL}
                    alt="checkmark"
                    className={classes.checkMark}
                  />
                )}
              </Grid>
              {!payAgainOrRepeatTransaction && (
                <BeneficiaryAddress
                  beneDetails={editableBeneDetails}
                  handleChange={handleInputChange}
                  isEditable={isEditable}
                  checkErrors={checkErrors}
                />
              )}
              {payAgainOrRepeatTransaction && (
                <BeneficiaryAddressReadOnly
                  reviewDetails={editableBeneDetails}
                />
              )}
              <AgenciaNumberField
                editableBeneDetails={editableBeneDetails}
                flag={flag}
              />
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Account Type
                </Typography>
                {isEditable ? (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    style={{ width: "14.5vw" }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="CdtrAcctTpPrtry"
                      onChange={handleInputChange}
                      onBlur={handleEditChange}
                      size="small"
                    >
                      <MenuItem value={"Savings/General"}>
                        Savings/General
                      </MenuItem>
                      <MenuItem value={"Savings/Deposit"}>
                        Savings/Deposit
                      </MenuItem>
                      <MenuItem value={"Checking/Current"}>
                        Checking/Current
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.CdtrAcctTpPrtry
                      ? editableBeneDetails?.CdtrAcctTpPrtry
                      : "---"}
                  </span>
                )}
                {flag && editableBeneDetails?.IsValidAccountTypeURL && (
                  <img
                    src={editableBeneDetails?.IsValidAccountTypeURL}
                    alt="checkmark"
                    className={classes.checkMark}
                  />
                )}
              </Grid>
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank BIC Code
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.BICFI}
                    onChange={handleInputChange}
                    onBlur={handleEditChange}
                    name="BICFI"
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.BICFI
                      ? editableBeneDetails?.BICFI
                      : "---"}
                  </span>
                )}
                {!isEditable &&
                  flag &&
                  editableBeneDetails?.IsValidBeneBICCodeURL && (
                    <img
                      src={editableBeneDetails?.IsValidBeneBICCodeURL}
                      alt="checkmark"
                      className={classes.checkMark}
                    />
                  )}
              </Grid>
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  CPF / Tax ID
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.TaxID}
                    onChange={handleInputChange}
                    onBlur={handleEditChange}
                    name="TaxID"
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.TaxID
                      ? editableBeneDetails?.TaxID
                      : "---"}
                  </span>
                )}
              </Grid>
              {!payAgainOrRepeatTransaction && (
                <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Beneficiary Bank Name
                  </Typography>
                  <Typography variant="body2" className={classes.textFont}>
                    {editableBeneDetails?.BeneBankName
                      ? editableBeneDetails?.BeneBankName
                      : "---"}
                  </Typography>
                  {!isEditable &&
                    flag &&
                    editableBeneDetails?.IsValidBeneAddressURL && (
                      <img
                        src={editableBeneDetails?.IsValidBeneAddressURL}
                        alt="checkmark"
                        className={classes.checkMark}
                      />
                    )}
                </Grid>
              )}
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank Address
                </Typography>
                <TextareaAutosize
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={editableBeneDetails?.BeneBankAddress || "--"}
                  readOnly
                  className={classes.textarea}
                />
                {!isEditable &&
                  flag &&
                  editableBeneDetails?.IsValidBeneAddressURL && (
                    <img
                      src={editableBeneDetails?.IsValidBeneAddressURL}
                      alt="checkmark"
                      className={classes.checkMark}
                    />
                  )}
              </Grid>
            </Grid>
            <AddressMessage
              editableBeneDetails={editableBeneDetails}
              setEditableBeneDetails={setEditableBeneDetails}
            />

            {!isMobile && editableBeneDetails?.message && (
              <Box className={classes.spacing} sty>
                <Typography variant="body2" className={classes.headerstyle}>
                  Validation message
                </Typography>
                <Typography className={classes.textFont}>
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.message}
                  </span>
                </Typography>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
export default Brazil;
