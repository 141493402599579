import { BorderBottom } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
// import { fontGrid } from "@mui/material/styles/cssUtils";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    boxShadow: theme.shadows[3],
    borderRadius: "30px",
    overflow: "hidden",
  },
  title: {
    color: "#52459f !important",
    fontSize: "1.3rem!important",
    marginTop: "16px !important",
    fontWeight: "bold !important",
  },
  divider: {
    marginTop: "5px !important",
  },
  PayCountry: {
    marginTop: "2% !important",
    display: "flex !important",
    aliginItems: "center !important ",
    gap: "15px !important ",
  },
  submit: {
    backgroundColor: "#51459E !important",
    color: "#FFFF !important",
    borderRadius: "20px !important",
    fontSize: " 14px !important",
    padding: " 0 16px 0 16px !important",
    height: "32px !important",
    textTransform: "none !important",
  },
  cancel: {
    border: "1px solid #BC64C7 !important",
    backgroundColor: " #FFFFFF !important",
    height: "32px !important",
    padding: " 0 16px 0 16px !important",
    fontSize: "14px",
    color: "#87269B !important",
    fontWeight: "600 !important",
    borderRadius: "20px !important",
    outline: "none !important",
    textTransform: "none !important",
  },
  countryFlag: {
    width: "20px !important ",
    height: "20px !important",
    borderRadius: "50% !important",
    objectFit: "cover !important ",
  },
  textstyle: {
    marginTop: "5px !important ",
  },
  contentPadding: {
    padding: "2% !important",
  },
}));
export default useStyles;
