import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import React, { useEffect } from "react";
import ProtectedRoute from "./config/protectedRoute";
import { Capacitor } from "@capacitor/core";
import Navigation from "./components/WebApp/Navigation/index";
import MobileNavigation from "./components/Mobile/MobileNavigation";
import Loader from "./components/Loader";
import "./global";

import Dataview from "./components/WebApp/DataView";
import Home from "./components/WebApp/Home";
import Accounts from "./components/WebApp/Accounts";
import RecentPayments from "./components/WebApp/RecentPayments";
import Login from "./components/Login/Login";
import Callback from "./components/Authorization/OAuth/Callback";

import MobileDataview from "./components/Mobile/MobileDataView";
import MobileHome from "./components/Mobile/MobileHome";
import MobileAccounts from "./components/Mobile/MobileAccounts";
import MobileRecentPayments from "./components/Mobile/MobileRecentPayments";
import AddModifyBeneficiary from "./components/WebApp/AddModifyBeneficiary";
import MobileAddModifyBeneficiary from "./components/Mobile/MobileAddModifyBeneficiary";
import MobileSendPayment from "./components/Mobile/MobileSendPayment";
import SendPayment from "./components/WebApp/SendPayment";
import PayAgain from "./components/WebApp/PayAgain";
import MobilePayAgain from "./components/Mobile/MobilePayAgain";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { setIsMobile } from "./Redux/Reducers/mobileReducer";
import RepeatTransaction from "./components/WebApp/RepeatTransaction";
import MobileRepeatTransaction from "./components/Mobile/MobileRepeatTransaction";
import FinalResponse from "./components/WebApp/SendPayment/FinalResponse";
import MobileFinalResponse from "./components/Mobile/MobileSendPayment/FinalResponse";
import AddNewBeneficiary from "./components/WebApp/AddNewBene/AddNewBeneficiary";
import MobileAddNewBeneficiary from "./components/Mobile/MobileAddNewBene/MobileAddNewBeneficiary";
const theme = createTheme();

function App() {
  const dipatch = useDispatch();
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = Capacitor.isNativePlatform();
  //const isMobile = true;
  useEffect(() => {
    dipatch(setIsMobile(isMobile));
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        {isMobile ? (
          <>
            <Router>
              <NavigationWrapper isMobile={isMobile} />
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/callback" element={<Callback />} />
                <Route
                  path="/"
                  element={<ProtectedRoute element={<MobileDataview />} />}
                >
                  <Route
                    index
                    element={<ProtectedRoute element={<MobileHome />} />}
                  />
                  <Route
                    path="accounts"
                    element={<ProtectedRoute element={<MobileAccounts />} />}
                  />
                  <Route
                    path="recentpayments"
                    element={
                      <ProtectedRoute element={<MobileRecentPayments />} />
                    }
                  />
                  <Route
                    path="addmodifybeneficiary"
                    element={
                      <ProtectedRoute
                        element={<MobileAddModifyBeneficiary />}
                      />
                    }
                  />
                  <Route
                    path="sendpayment"
                    element={<ProtectedRoute element={<MobileSendPayment />} />}
                  />
                  <Route
                    path="payagain"
                    element={<ProtectedRoute element={<MobilePayAgain />} />}
                  />
                  <Route
                    path="repeattransaction"
                    element={
                      <ProtectedRoute element={<MobileRepeatTransaction />} />
                    }
                  />
                  <Route
                    path="moneysent"
                    element={
                      <ProtectedRoute element={<MobileFinalResponse />} />
                    }
                  />
                  <Route
                    path="addnewbene"
                    element={
                      <ProtectedRoute element={<MobileAddNewBeneficiary />} />
                    }
                  />
                </Route>
              </Routes>
            </Router>
          </>
        ) : (
          <>
            <Router>
              <NavigationWrapper isMobile={isMobile} />
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/callback" element={<Callback />} />
                <Route
                  path="/"
                  element={<ProtectedRoute element={<Dataview />} />}
                >
                  <Route
                    index
                    element={<ProtectedRoute element={<Home />} />}
                  />
                  <Route
                    path="accounts"
                    element={<ProtectedRoute element={<Accounts />} />}
                  />
                  <Route
                    path="recentpayments"
                    element={<ProtectedRoute element={<RecentPayments />} />}
                  />
                  <Route
                    path="addmodifybeneficiary"
                    element={
                      <ProtectedRoute element={<AddModifyBeneficiary />} />
                    }
                  />
                  <Route
                    path="sendpayment"
                    element={<ProtectedRoute element={<SendPayment />} />}
                  />
                  <Route
                    path="payagain"
                    element={<ProtectedRoute element={<PayAgain />} />}
                  />
                  <Route
                    path="repeattransaction"
                    element={<ProtectedRoute element={<RepeatTransaction />} />}
                  />
                  <Route
                    path="moneysent"
                    element={<ProtectedRoute element={<FinalResponse />} />}
                  />
                  <Route
                    path="addnewbene"
                    element={<ProtectedRoute element={<AddNewBeneficiary />} />}
                  />
                </Route>
              </Routes>
            </Router>
          </>
        )}
      </ThemeProvider>
    </>
  );
}

function NavigationWrapper({ isMobile }) {
  const location = useLocation();
  const shouldShowNavigation = !location.pathname.startsWith("/login");

  return (
    <>
      {shouldShowNavigation &&
        (isMobile ? <MobileNavigation /> : <Navigation />)}
    </>
  );
}
export default App;
