import { makeStyles } from "@mui/styles";
// import { fontGrid } from "@mui/material/styles/cssUtils";

const useStyles = makeStyles((theme) => ({
  // Amount Screen Stylings Start -- gopi

  CurrencyFlag: {
    borderRadius: "50% !important ",
    width: "20px !important ",
    height: "20px !important ",
  },
  Sendcurrency: {
    marginLeft: "5px",
  },
  borderstyle: {
    borderRadius: "8px !important ",
  },
  SwaphorizIcon: {
    fill: "#52459f !important",
    width: "1.3em !important",
    height: "2em !important ",
  },
  purpose: {
    marginTop: "10px !important ",
  },
  mandatory: {
    color: "red ",
  },
  option: {
    display: "flex !important ",
    alignItems: "center !important ",
  },
  optionValue: {
    marginLeft: "10px !important ",
  },
  //Amount Screen Stlylings end -- gopi

  rootContainer: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    boxShadow: theme.shadows[3],
    //borderRadius: "30px",
    overflow: "hidden",
  },
  flex: {
    display: "flex",
  },
  headerDetails: {
    display: "flex !important ",
    justifyContent: "space-between !important",
    alignItems: "center !important ",
    width: "100%",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexCenterAlign: {
    display: "flex",
    alignItems: "center",
  },
  accountDetailsPad: {
    padding: "20px 20px 0px 20px !important ",
  },
  title: {
    fontSize: "1.25rem !important",
    fontWeight: "bold !important",
    color: "#7270a0 !important",
  },
  spacingCont: {
    margin: "10px 0px !important",
  },
  purposeCodeDropdown: {
    width: "60% !important",
    borderRadius: "15px !important",
  },
  spacing: {
    marginTop: "17px !important ",
   // borderRadius: "15px",
  },
  cancel: {
    border: "1px solid #BC64C7 !important",
    borderRadius: "40px !important",
    background: "#ffff !important",
    color: "#51459E !important",
    padding: "7px 13px !important",
    textTransform: "none !important",
    marginTop: "20px !important",
    "&:hover": {
      background: "#ededed !important",
    },
  },
  continue: {
    color: "#ffffff !important",
    padding: "7px 13px !important",
    background: "#51459E !important",
    textTransform: "none !important",
    borderRadius: "40px !important",
    "&:hover": {
      backgroundColor: "black !important",
      color: "#ffffff !important",
    },
    marginTop: "20px !important",
  },
  flexgrid: {
    display: "flex !important ",
    alignItems: "center !important ",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "flex-start",
  },
  textcontent: {
    fontSize: "0.7rem !important ",
    whiteSpace: "nowrap !important",
  },
  inputvalue: {
   // width: "50% !important ",
    // padding: "5px !important ",
    display: "flex !important ",
    alignItems: "center !important ",
    fontWeight: "bold !important ",
    fontSize: "0.67rem !important ",
  },
  headerstyle: {
    fontWeight: "600 !important",
    fontSize: "14px !important",
    lineHeight: "2em !important",
  },
  contentstyle: {
    fontSize: "13px !important",
  },
  remarks: {
    width: "90vw",
  },
  remarksText: {
    fontSize: "0.7rem !important ",
  },
  checkboxStyles: {
    "&.MuiCheckbox-root": {
      color: "#52459F !important",
    },
    "&[class*='MuiCheckbox-root']": {
      color: "#52459F !important",
    },
  },
  conversionFields: {
    background: "#edecf5 !important ",
    padding: "5px !important",
    marginLeft: "0px !important",
  },
  textFont: {
    fontSize: "13px !important ",
  },
  helpIconCont: {
    display: "flex",
    justifyContent: "flex-end",
  },
  helpIcon: {
    color: "#52459f !important",
  },
  header: {
    marginTop: "17px !important",
    background: "#52459f !important",
    color: "#fff !important ",
    padding: "2% !important ",
  },
  warningIcon: {
    width: "20px !important ",
    height: "20px !important ",
  },
  CountryFlag: {
    marginLeft: "5px !important ",
    width: "20px !important ",
    height: "20px !important ",
    borderRadius: "50% !important",
    objectFit: "cover !important",
  },
  "@media screen and (min-width:300px) and (max-width:480px)": {
    title: {
      fontSize: "1rem !important",
      fontWeight: "bold !important",
      color: "#7270a0 !important",
    },
    rootContainer: {
      backgroundColor: "#fff",
      boxShadow: theme.shadows[3],
      maxWidth: "100vw !important ",
      overflow: "hidden",
      boxSizing: "border-box",
    },
    textcontent: {
      fontSize: "0.7rem !important ",
      whiteSpace: "nowrap !important",
    },
    header: {
      marginTop: "17px !important",
      background: "#52459f !important",
      color: "#fff !important ",
      padding: "2% !important ",
      fontSize: "0.8rem !important ",
    },
    headerstyle: {
      fontWeight: "600 !important",
      fontSize: "0.7rem !important",
      lineHeight: "2em !important",
    },
    accountDetailsPad: {
      padding: "7px 7px 0px 7px !important ",
    },
    contentstyle: {
      fontSize: "0.65rem !important",
    },
    CurrencyFlag: {
      borderRadius: "50% !important ",
      width: "15px !important ",
      height: "15px !important ",
    },
    cancel: {
      border: "1px solid #BC64C7 !important",
      borderRadius: "40px !important",
      background: "#ffff !important",
      color: "#51459E !important",
      padding: "3px 12px 3px 12px !important",
      fontSize: "0.7rem !important ",
      textTransform: "none !important",
      marginTop: "20px !important",
      "&:hover": {
        background: "#ededed !important",
      },
    },
    continue: {
      color: "#ffffff !important",
      fontSize: "0.7rem !important ",
      padding: "3px 12px 3px 12px !important",
      background: "#51459E !important",
      textTransform: "none !important",
      borderRadius: "40px !important",
      "&:hover": {
        backgroundColor: "black !important",
        color: "#ffffff !important",
      },
      marginTop: "20px !important",
    },
    textFont: {
      fontSize: "0.7rem !important ",
    },
    warningIcon: {
      width: "15px !important ",
      height: "15px !important ",
      //marginTop: "5px !important "
    },
    message: {
      fontSize: "0.7rem !important ",
      fontWeight: "600 !important ",
    },
    CountryFlag: {
      marginLeft: "5px !important ",
      width: "20px !important ",
      height: "20px !important ",
      objectFit: "cover !important",
      borderRadius: "50% !important",
    },
    inputvalue: {
     // width: "100% !important ",
      // padding: "5px !important ",
      display: "flex !important ",
      alignItems: "center !important ",
      fontWeight: "bold !important ",
      fontSize: "0.6rem !important ",
    },
  },
  "@media screen and (min-width:600px) and (max-width:1024px)": {
    title: {
      fontSize: "1.5rem !important",
      fontWeight: "bold !important",
      color: "#7270a0 !important",
    },
    header: {
      marginTop: "17px !important",
      background: "#52459f !important",
      color: "#fff !important ",
      padding: "2% !important ",
      fontSize: "1.4rem !important ",
    },
    headerstyle: {
      fontWeight: "600 !important",
      fontSize: "1.4rem !important",
      lineHeight: "2em !important",
    },
    SwaphorizIcon: {
      fill: "#52459f !important",
      width: "2em !important",
    },
    accountDetailsPad: {
      padding: "7px 7px 0px 7px !important ",
    },
    contentstyle: {
      fontSize: "1.3rem !important",
    },
    CurrencyFlag: {
      borderRadius: "50% !important ",
      width: "25px !important ",
      height: "25px !important ",
    },
    cancel: {
      border: "1px solid #BC64C7 !important",
      borderRadius: "40px !important",
      background: "#ffff !important",
      color: "#51459E !important",
      fontSize: "1.3rem !important ",
      padding: "14px 30px !important",
      textTransform: "none !important",
      marginTop: "20px !important",
      "&:hover": {
        background: "#ededed !important",
      },
    },
    continue: {
      color: "#ffffff !important",
      fontSize: "1.3rem !important ",
      padding: "14px 30px !important",
      background: "#51459E !important",
      textTransform: "none !important",
      borderRadius: "40px !important",
      "&:hover": {
        backgroundColor: "black !important",
        color: "#ffffff !important",
      },
      marginTop: "20px !important",
    },
    textFont: {
      fontSize: "1.3rem !important ",
    },
    warningIcon: {
      width: "25px !important",
      height: "25px !important",
    },
    CountryFlag: {
      marginLeft: "5px !important ",
      width: "25px !important ",
      height: "25px !important ",
      objectFit: "cover !important",
      borderRadius: "50% !important",
    },
    textcontent: {
      fontSize: "1.2rem !important ",
      whiteSpace: "nowrap !important",
    },
    inputvalue: {
      //width: "50% !important ",
      // padding: "5px !important ",
      display: "flex !important ",
      alignItems: "center !important ",
      fontWeight: "bold !important ",
      fontSize: "1.2rem !important ",
    },
    remarksText: {
      fontSize: "1.3rem !important ",
    },
    headerBene: {
      fontSize: "1.4rem !important ",
    },
    message: {
      fontSize: "1.4rem !important",
      fontWeight: "600 !important",
    },
  },
}));
export default useStyles;
