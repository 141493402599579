import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  btnContainer: {
    display: "flex",
    justifyContent: "end",
  },
  searchIcon: {
    position: "absolute",
    right: "35px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  closeIcon: {
    position: "absolute",
    right: "10px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  circleIcon: {
    fill: "#51459E !important",
  },
  addtxt: {
    fontSize: "16px !important ",
    fontWeight: "bold !important ",
  },
  inputInput: {
    width: "100%",
    padding: "10px !important ",
    border: "1px solid #dcdcdc",
    borderRadius: "12px",
    fontSize: "14px",
    //background: "#f5f7ff",
    "&:focus": {
      border: "1px solid #dcdcdc",
      outline: "none",
    },
  },
  inputLable: {
    margin: "-3px 0 0px 0px !important ",
    fontSize: "14px !important",
    color: "#222222 !important",
  },
  cell: {
    whiteSpace: "nowrap",
    color: "#52459f",
  },
  title: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    color: "#52459f",
    fontSize: "16px",
    fontWeight: "bold",
    borderBottom: "1.4px solid #dddd",
  },
  b_heading: {
    color: "#52459f ",
    padding: "10px  ",
    // margin: "10px 0px",
    fontSize: "16px",
    fontWeight: "bold !important ",
    backgroundColor: "#dddd",
    display: "flex",
    alignItems: "center",
  },
  image: {
    width: "20px",
    height: "20px",
    marginLeft: "25px",
  },
  avatar: {
    marginRight: "15px",
  },
  flag: {
    marginTop: "-1px",
    display: "flex",
    justifyContent: "center",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "end",
    width: "15%",
  },
  accountCard: {
    border: "1px solid #DDDDDD  ",
    borderRadius: "16px !important ",
    marginTop: "10px !important ",
    padding: "4px !important ",
    "&:hover": {
      backgroundColor: "#51459e20 !important", // Hover effect for Card
      borderRadius: "16px",
    },
  },
  acContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent", // Ensure no conflicting background color
    padding: "20px !important",
    // "& .MuiCardContent-root:last-child ": { paddingBottom: 0 },
  },
  inputLabel: {
    fontSize: "14px !important",
    color: "grey !important",
    paddingLeft: "8px !important",
    marginTop: "6px !important ",
  },
  /* styles for dialog */
  Dialog: {
    "& .MuiDialog-paper": {
      width: "600px",
      borderRadius: "25px !important",
    },
    "& .MuiDialog-container": {
      height: "90vh !important",
    },
  },
  dialogTitle: {
    background: "#51459E !important ",
    color: "#FCFBFA",
    borderRadius: "20px",
    margin: "10px !important",
    height: "2vh !important",
    display: "flex",
  },
  popup: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: " 10px 5px",
    width: "100%",
  },
  popupTitle: {
    fontFamily: " OpenSans, sans-serif !important ",
    fontSize: "16px !important ",
    color: "inherit !important",
    fontWeight: "bold !important",
    //textDecoration: "inherit !important",
    textTransform: "none !important",
    paddingLeft: "10px !important",
  },
  main: {
    display: "flex !important",
    justifyContent: "flex-end !important",
    gap: "10px !important",
  },
  editIcon: {
    fill: "#fff !important",
    background: "#52439E",
    borderRadius: "7px",
    padding: "3px",
    cursor: "pointer",
    height: "15px",
    width: " 18px",
  },
  submit: {
    backgroundColor: "#51459E !important",
    color: "#FFFF !important",
    borderRadius: "20px !important",
    fontSize: " 14px !important",
    padding: " 0 16px 0 16px !important",
    height: "32px !important",
    textTransform: "none !important",
  },
  cancel: {
    border: "1px solid #BC64C7 !important",
    backgroundColor: " #FFFFFF !important",
    height: "32px !important",
    padding: " 0 16px 0 16px !important",
    fontSize: "14px",
    color: "#87269B !important",
    fontWeight: "600 !important",
    borderRadius: "20px !important",
    outline: "none !important",
    textTransform: "none !important",
  },
  formselect: {
    minWidth: "35vw !important ",
  },
  payToCountry: {
    marginTop: "5px !important",
    fontSize: "0.8rem !important",
  },
  "@media screen and (min-width:300px) and (max-width:480px)": {
    Dialog: {
      "& .MuiDialog-paper": {
        width: "600px",
        borderRadius: "25px !important",
      },
    },
  },
}));

export default useStyles;
