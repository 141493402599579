import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  fetchAccounts_Data,
  fetchDataForSendAmount,
  fetchDataFromAccount,
  fetchDataForReceiverAmount,
  fetchDataForReceiverCurrency,
} from "./SendPaymentApiBuilders";

// reducers
const SendPaymentSlice = createSlice({
  name: "sendPaymentDataFlow",
  initialState: {
    accounts: [],
    data: null,
    availableToPayCurrencyList: [],
    nextAssignmentId: null,
    caseId: null,
    eTag: null,
    availableToPayCountryNamesList: [],
    receiversCountryName: "",
    selectedBeneDetails: null,
    previousData: null,
    purposeCodeList: [],
    reviewDetails: [],
    finalResponse: null,
    showReviewBeneficiary: false,
    loading: true,
    tabIndex: 0,
  },
  reducers: {
    setAccountsData(state, action) {
      state.accounts = action.payload;
    },
    setAvailable_ToPayCurrencyList(state, action) {
      state.availableToPayCurrencyList = action.payload;
    },
    setAvailable_ToPayCountryNamesList(state, action) {
      state.availableToPayCountryNamesList = action.payload;
    },
    setData(state, action) {
      state.data = { ...state.data, ...action.payload };
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setTabIndex(state, action) {
      state.tabIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccounts_Data.fulfilled, (state, action) => {
        state.eTag = action.payload.etag;
        state.caseId = action.payload?.data?.data?.caseInfo?.ID.split(" ")[1];
        state.nextAssignmentId = action.payload?.data?.nextAssignmentInfo?.ID;
        state.data = action.payload?.data?.data?.caseInfo?.content;
      })
      .addCase(fetchDataFromAccount.fulfilled, (state, action) => {
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(fetchDataForSendAmount.fulfilled, (state, action) => {
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(fetchDataForReceiverAmount.fulfilled, (state, action) => {
        state.data = { ...state.data, ...action.payload };
      })
      .addCase(fetchDataForReceiverCurrency.fulfilled, (state, action) => {
        state.data = { ...state.data, ...action.payload };
      });
  },
});

export const {
  setAccountsData,
  setTabIndex,
  setData,
  setLoading,
  setAvailable_ToPayCurrencyList,
  setAvailable_ToPayCountryNamesList,
} = SendPaymentSlice.actions;
export default SendPaymentSlice.reducer;
