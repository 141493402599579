import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    backgroundColor: "#fff",
    boxShadow: theme.shadows[3],
    overflow: "hidden",
    padding: theme.spacing(2),
  },
  headerContent: {
    padding: "20px 20px 0px 20px !important",
  },
  inputLabel: {
    fontSize: "14px !important",
    color: "grey !important",
    paddingLeft: "8px !important",
    marginTop: "6px !important ",
  },
  acctInput: {
    padding: "2.5% !important ",
    border: "1px solid #d5d5d5 !important",
    borderRadius: "10px !important",
    fontSize: "14px !important ",
  },
  CurrencyFlag: {
    borderRadius: "50% !important ",
    width: "20px !important ",
    height: "20px !important ",
  },
  textstyle: {
    fontSize: "14px !important ",
    fontWeight: "600 !important ",
  },
  borderstyle: {
    borderRadius: "8px !important ",
  },
  Swaphorizicon: {
    marginTop: "32px !important",
    fill: "#52459f !important",
    width: "1.3em !important",
    height: "2em !important ",
  },
  purpose: {
    marginTop: "10px !important ",
  },
  remarks: {
    width: "90vw !important",
  },
  mandatory: {
    color: "red ",
  },
  option: {
    display: "flex !important ",
    alignItems: "center !important ",
  },
  optionValue: {
    marginLeft: "10px !important ",
  },
  flex: {
    display: "flex",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "flex-start",
  },
  flexCenterAlign: {
    display: "flex",
    alignItems: "center",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  tooltip: {
    color: "#52459f !important ",
    padding: "0.1px !important ",
  },
  title: {
    fontSize: "1.25rem !important",
    fontWeight: "bold !important",
    color: "#7270a0 !important",
  },
  inputBase: {
    width: "70% !important ",
    padding: "5px !important ",
    display: "flex",
    alignItems: "center !important ",
    fontWeight: "bold !important ",
    fontSize: "0.7rem !important ",
  },
  exchangefields: {
    background: "#edecf5 !important ",
    padding: "5px !important",
    marginLeft: "0px !important",
  },
  sendAmountTextField: {
    borderRadius: "15px !important",
  },
  itemWidth: {
    width: "40vw !important",
  },
  spacingCont: {
    margin: "10px 0px !important",
  },
  purposeCodeDropdown: {
    width: "60% !important",
    borderRadius: "15px !important",
  },
  currencyCodeSelect: {
    "& .custom__control": {
      width: "175px !important",
      borderRadius: "8px !important",
      border: "none !important",
      boxShadow: "none",
      "&:hover": {
        border: "none",
      },
    },
    "& .custom__menu-list": {
      height: "160px !important",
      overflowY: "auto !important",
      "&::-webkit-scrollbar": {
        width: "5px !important",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#52459f !important",
        borderRadius: "4px !important",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555 !important",
      },
    },
  },
  spacing: {
    marginTop: "17px !important ",
    //borderRadius: "15px",
  },
  info: {
    fontSize: "0.6rem !important",
    margin: "8px 0px !important",
  },
  cancel: {
    border: "1px solid #BC64C7 !important",
    borderRadius: "40px !important",
    background: "#ffff !important",
    color: "#51459E !important",
    padding: "7px 13px !important",
    textTransform: "none !important",
    marginTop: "20px !important",
    "&:hover": {
      background: "#ededed !important",
    },
  },
  continue: {
    color: "#ffffff !important",
    padding: "7px 13px !important",
    background: "#51459E !important",
    textTransform: "none !important",
    borderRadius: "40px !important",
    "&:hover": {
      backgroundColor: "black !important",
      color: "#ffffff !important",
    },
    marginTop: "20px !important",
  },
  headerstyle: {
    fontWeight: "600 !important",
    fontSize: "14px !important",
    lineHeight: "2em !important",
  },
  headerDetails: {
    display: "flex !important ",
    justifyContent: "space-between !important",
    alignItems: "center !important ",
  },
  contentstyle: {
    fontSize: "13px !important",
  },
  checkboxStyles: {
    "&.MuiCheckbox-root": {
      color: "#52459F !important",
    },
    "&[class*='MuiCheckbox-root']": {
      color: "#52459F !important",
    },
  },
  header: {
    marginTop: "15px !important",
    background: "#52459f !important",
    color: "#fff !important ",
    padding: "2% !important ",
  },
  flexgrid: {
    display: "flex !important ",
    alignItems: "center !important ",
  },
  textcontent: {
    fontSize: "0.7rem !important ",
  },
  inputvalue: {
    width: "50% !important ",
    padding: "5px !important ",
    display: "flex !important ",
    alignItems: "center !important ",
    fontWeight: "bold !important ",
    fontSize: "0.76rem !important ",
  },
  spacingdesc: {
    margin: "17px 0 0 10px",
  },
  warningIcon: {
    width: "20px !important",
    height: "20px !important",
  },
  textFont: {
    fontSize: "13px !important",
  },
  CountryFlag: {
    marginLeft: "5px !important ",
    width: "20px !important ",
    height: "20px !important ",
    borderRadius: "50% !important ",
    objectFit: "cover !important ",
  },
  helpIconCont: {
    display: "flex",
    justifyContent: "flex-end",
  },
  helpIcon: {
    color: "#52459f !important",
  },
  "@media screen and (min-width:300px) and (max-width:480px)": {
    rootContainer: {
      /* maxwidth: "100vw !important ",
      boxSizing:"border-box",
      backgroundColor: "#fff",
      boxShadow: theme.shadows[3],
      overflow: "hidden !important ",
      //padding: theme.spacing(2), */
      backgroundColor: "#fff",
      boxShadow: theme.shadows[3],
      maxWidth: "100vw !important ",
      overflow: "hidden",
      boxSizing: "border-box",
    },
    headerstyle: {
      fontWeight: "600 !important",
      fontSize: "0.7rem !important",
      lineHeight: "2em !important",
    },
    contentstyle: {
      fontSize: "0.65rem !important",
    },
    title: {
      fontSize: "1rem !important",
      fontWeight: "bold !important",
      color: "#7270a0 !important",
    },
    header: {
      marginTop: "15px !important",
      background: "#52459f !important",
      fontSize: "0.8rem !important",
      color: "#fff !important ",
      padding: "2% !important ",
    },
    inputLabel: {
      fontSize: "12px !important",
      color: "grey !important",
      paddingLeft: "8px !important",
      marginTop: "6px !important ",
    },
    acctInput: {
      padding: "2.5% !important ",
      border: "1px solid #d5d5d5 !important",
      borderRadius: "10px !important",
      fontSize: "12px !important ",
    },
    tooltip: {
      color: "#52459f !important ",
      padding: "0px !important ",
      fontSize: "0.9rem !important",
    },
    inputBase: {
      width: "70% !important ",
      padding: "3px !important ",
      display: "flex",
      alignItems: "center !important ",
      fontWeight: "bold !important ",
      fontSize: "0.6rem !important ",
    },
    textstyle: {
      fontSize: "0.7rem !important ",
      fontWeight: "600 !important ",
    },
    CurrencyFlag: {
      borderRadius: "50% !important ",
      width: "15px !important ",
      height: "15px !important ",
    },
    headerContent: {
      padding: "7px 7px 0px 7px !important",
    },
    textFont: {
      fontSize: "0.7rem !important",
    },
    currencyCodeSelect: {
      "& .custom__control": {
        width: "100px !important",
        borderRadius: "8px !important",
        border: "none !important",
        boxShadow: "none",
        "&:hover": {
          border: "none",
        },
      },
      "& .css-1xc3v61-indicatorContainer": {
        padding: "5px !important",
      },
      "& .css-15lsz6c-indicatorContainer": {
        padding: "5px !important",
      },
      "& .custom__menu-list": {
        height: "160px !important",
        overflowY: "auto !important",
        "&::-webkit-scrollbar": {
          width: "5px !important",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#52459f !important",
          borderRadius: "4px !important",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555 !important",
        },
      },
    },
    textfeild: {
      fontSize: "0.7rem !important",
    },
    optionValue: {
      marginLeft: "5px !important ",
      fontSize: "0.7rem !important",
    },
    message: {
      fontSize: "0.7rem !important",
      fontWeight: "600 !important",
    },
    warningIcon: {
      width: "15px !important",
      height: "15px !important",
    },
    itemWidth: {
      width: "38vw !important",
    },
    textcontent: {
      fontSize: "0.7rem !important ",
      whiteSpace: "nowrap",
    },
    purposeCodeDropdown: {
      width: "100% !important",
      borderRadius: "15px !important",
    },
    cancel: {
      border: "1px solid #BC64C7 !important  ",
      borderRadius: "40px !important ",
      background: "#ffff !important ",
      padding: "3px 12px 4px 12px !important",
      fontSize: "0.7rem !important ",
      textTransform: "none !important ",
    },
    continue: {
      color: "#ffffff !important",
      padding: "3px 12px 4px 12px !important",
      fontSize: "0.7rem !important ",
      background: "#51459E !important",
      textTransform: "none !important",
      borderRadius: "40px !important",
      "&:hover": {
        backgroundColor: "black !important ",
        color: "#ffffff !important ",
      },
    },
    CountryFlag: {
      marginLeft: "5px !important ",
      width: "20px !important ",
      height: "20px !important ",
      borderRadius: "50% !important ",
      objectFit: "cover !important ",
    },
  },
  "@media screen and (min-width:600px) and (max-width:1024px)": {
    header: {
      marginTop: "15px !important",
      background: "#52459f !important",
      color: "#fff !important ",
      padding: "2% !important ",
      fontSize: "1.4rem !important ",
    },
    headerBene: {
      fontSize: "1.4rem !important ",
    },
    headerstyle: {
      fontWeight: "600 !important",
      fontSize: "1.4rem !important",
      lineHeight: "2em !important",
    },
    contentstyle: {
      fontSize: "1.3rem !important",
    },
    acctInput: {
      padding: "2.5% !important ",
      border: "1px solid #d5d5d5 !important",
      borderRadius: "20px !important",
      fontSize: "1.3rem !important ",
    },
    inputLabel: {
      fontSize: "1.3rem !important",
      color: "grey !important",
      paddingLeft: "8px !important",
      marginTop: "0px !important ",
    },
    CountryFlag: {
      marginLeft: "5px !important ",
      width: "25px !important ",
      height: "25px !important ",
      borderRadius: "50% !important ",
      objectFit: "cover !important ",
    },
    info: {
      fontSize: "1.1rem !important",
      margin: "8px 0px !important",
    },
    textfeild: {
      fontSize: "1.2rem !important",
    },
    textcontent: {
      fontSize: "1.2rem !important ",
    },
    inputBase: {
      width: "70% !important ",
      padding: "5px !important ",
      display: "flex",
      alignItems: "center !important ",
      fontWeight: "bold !important ",
      fontSize: "1.1rem !important ",
    },
    message: {
      fontSize: "1.4rem !important",
      fontWeight: "600 !important",
    },
    warningIcon: {
      width: "25px !important",
      height: "25px !important",
    },
    textFont: {
      fontSize: "1.5em !important",
    },
    cancel: {
      border: "1px solid #BC64C7 !important",
      borderRadius: "40px !important",
      background: "#ffff !important",
      color: "#51459E !important",
      fontSize: "1.3rem !important ",
      padding: "14px 30px !important",
      textTransform: "none !important",
      marginTop: "20px !important",
      "&:hover": {
        background: "#ededed !important",
      },
    },
    continue: {
      color: "#ffffff !important",
      padding: "14px 30px !important",
      background: "#51459E !important",
      textTransform: "none !important",
      borderRadius: "40px !important",
      fontSize: "1.3rem !important ",
      "&:hover": {
        backgroundColor: "black !important",
        color: "#ffffff !important",
      },
      marginTop: "20px !important",
    },
    CurrencyFlag: {
      borderRadius: "50% !important ",
      width: "25px !important ",
      height: "25px !important ",
    },
    title: {
      fontSize: "1.5rem !important",
      fontWeight: "bold !important",
      color: "#7270a0 !important",
    },
    remarksText: {
      width: "50vw !important",
      fontSize: "1.3rem !important ",
    },
  },
}));

export default useStyles;
