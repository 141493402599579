import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";

const NickNameField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    beneDetails,
    isEditable,
    disableFlag,
    handleChange,
    handleBlurChange,
    payAgainOrRepeatTransaction,
  } = props;

  return (
    <>
      {payAgainOrRepeatTransaction && beneDetails?.NickName !== "" && (
        <Grid size={{ md: 12, xs: 12 }} className={classes.spacing}>
          <Typography variant="body2" className={classes.headerstyle}>
            Nickname (Optional)
          </Typography>
          {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              value={beneDetails?.NickName}
              onChange={handleChange}
              onBlur={handleBlurChange}
              name="NickName"
              disabled={disableFlag}
            />
          ) : beneDetails?.NickName ? (
            <span className={classes.contentstyle}>
              {beneDetails?.NickName}
            </span>
          ) : (
            "---"
          )}
        </Grid>
      )}

      {!payAgainOrRepeatTransaction && (
        <Grid size={{ md: 12, xs: 12 }} className={classes.spacing}>
          <Typography variant="body2" className={classes.headerstyle}>
            Nickname (Optional)
          </Typography>
          {isEditable ? (
            <TextField
              variant="outlined"
              size="small"
              value={beneDetails?.NickName}
              onChange={handleChange}
              onBlur={handleBlurChange}
              name="NickName"
              disabled={disableFlag}
            />
          ) : beneDetails?.NickName ? (
            <span className={classes.contentstyle}>
              {beneDetails?.NickName}
            </span>
          ) : (
            "---"
          )}
        </Grid>
      )}
    </>
  );
};

export default NickNameField;
