import React from "react";
import Grid from "@mui/material/Grid2";
import { TextareaAutosize, Typography } from "@mui/material";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { useSelector } from "react-redux";

const BeneficiaryAddressReadOnly = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const { reviewDetails } = props;
console.log(reviewDetails)
  return (
    <>
      <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Beneficiary Address
        </Typography>
        {reviewDetails?.RecipentAddress ? (
          <TextareaAutosize
            variant="outlined"
            fullWidth
            size="small"
            value={reviewDetails?.RecipentAddress || "---"}
            readOnly
            className={classes.textarea}
          />
        ) : (
          <>
            {" "}
            <div>
              {(reviewDetails?.BuildingNumber ||
                reviewDetails?.BuildingName) && (
                <span className={classes.contentstyle}>
                  {reviewDetails?.BuildingNumber}, &nbsp;
                  {reviewDetails?.BuildingName}
                  <br />
                </span>
              )}

              {(reviewDetails?.StreetName ||
                reviewDetails?.TownName) && (
                <span className={classes.contentstyle}>
                  {reviewDetails?.StreetName},&nbsp;
                  {reviewDetails?.TownName}
                  <br />
                </span>
              )}

              {(reviewDetails?.ReceiversCountryName ||
                reviewDetails?.PostCode) && (
                <span className={classes.contentstyle}>
                  {reviewDetails?.Country}&nbsp;-&nbsp;
                  {reviewDetails?.PostCode}
                  <br />
                </span>
              )}
            </div>
          </>
        )}
      </Grid>
    </>
  );
};

export default BeneficiaryAddressReadOnly;
