import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import InfoIcon from "@mui/icons-material/Info";
import flagsCode from "../../CommonService/flagServies";
import {
  getDataForFromAccount,
  getDataForSendAmount,
  getDataForReceiverCurrency,
  getDataForReceiverAmount,
  getDataAfterFirstContinue,
  getDataForReceiversCountryName,
  debouncedGetDataForSendAmount,
  debouncedGetDataForReceiverAmount,
} from "../../CommonService/sendPaymentService";
import ReactFlagsSelect from "react-flags-select";
import iso3166 from "iso-3166-1";
import SelectFeild from "react-select";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  Tooltip,
  FormHelperText,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import EuroCountries from "../../CommonService/EuroCountries";

const AmountSection = ({
  onContinue,
  accounts,
  eTag,
  nextAssignmentId,
  avaiableToPayCurrencyList,
  availableToPayCountryNamesList,
  setEtag,
  setReceiversCountryName,
  previousData,
}) => {
  const classes = useStyles();
  const [account, setAccount] = useState("");
  const [isShowWarning, setShowWarning] = useState(false);
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [showAmountFields, setShowAmountFields] = useState(false);
  const [showExchangeFields, setShowExchangeFields] = useState(false);
  const [purposeCode, setPurposeCode] = useState("");
  const [purposeCodeList, setPurposeCodeList] = useState("");
  const [validateCurrency, setValidateCurrency] = useState("");
  const [flagselect, setflagselect] = useState("");
  const [flagCode, setFlagCode] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const actionName = "ActionInitiatePayment";
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState();

  const [accountDetails, setAccountDetails] = useState({
    FromAccount: "",
    AccountBalance: "",
    SendAmount: "",
    ReceiveAmount: "",
    SendCurrency: "",
    CurrencyConvDesc: "",
    FXRateGoodForText: "",
    ReceiversCountryName: "",
    ReceiveCurrency: "",
  });

  const [open, setOpen] = useState(false);
  const [toolopen, setToolOpen] = useState(false);
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTipOpen = () => {
    setToolOpen(true);
  };
  const handleTipClose = () => {
    setToolOpen(false);
  };

  useEffect(() => {
    if (accounts && accounts.length === 1) {
      setAccount(accounts[0].AccountNumber);
    }
  }, [accounts]);

  useEffect(() => {
    if (previousData) {
      const fmAccount = previousData.caseInfo.content.FromAccount;
      const pxresults = getAccountsList(previousData.shared.D_GetAccountList);
      const maskNumber = pxresults.find(
        (item) => item.AccountNumber === fmAccount
      );
      if (maskNumber) {
        setAccount(maskNumber.AccountNumber);
      }
      setSelectedCountryName(
        previousData.caseInfo.content.ReceiversCountryName
      );
      setFlagCode(
        flagsCode[previousData?.caseInfo?.content?.ReceiversCountryName]
      );
      setflagselect(previousData?.caseInfo?.content?.PaymentInfo?.CountryCode);
      if (purposeCodeList) {
        const list = getPurposeCodeList(previousData?.shared);
        setPurposeCodeList(list);
        setPurposeCode(
          previousData?.caseInfo?.content?.PaymentInfo?.PaymentsPurposeCode
        );
      }
      if (previousData?.shared) {
        const list = getPurposeCodeList(previousData?.shared);
        setPurposeCodeList(list);
        setPurposeCode(
          previousData?.caseInfo?.content?.PaymentInfo?.PaymentsPurposeCode
        );
      }

      const option = {
        label: previousData?.caseInfo?.content?.ReceiveCurrency,
        value: previousData?.caseInfo?.content?.ReceiveCurrency,
      };
      setSelectedCurrency(option);
      setRemarks(previousData?.caseInfo?.content?.Remarks);
      const newDetails = previousData.caseInfo.content;
      setShowAmountFields(true);
      setShowExchangeFields(true);
      setAccountDetails((prevDetails) => ({
        ...prevDetails,
        ...newDetails,
      }));
    }
  }, [previousData]);

  useEffect(() => {
    const selectedvalue = selectedCurrency?.value?.slice(0, 2);
    if (flagselect && selectedvalue) {
      if (selectedvalue === "EU") {
        setShowWarning(!EuroCountries.includes(flagselect));
      } else {
        const isMismatch = flagselect !== selectedvalue;
        setShowWarning(isMismatch);
      }
    } else {
      setShowWarning(false);
    }
  }, [flagselect, selectedCurrency]);

  function getPurposeCodeList(response) {
    const list = response.D_PurposeCodeList;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_PurposeCodeList")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  useEffect(() => {
    if (accountDetails.ReceiveAmount) {
      setError((prevState) => ({
        ...prevState,
        amountReceiveError: "",
      }));
    }
  }, [accountDetails.ReceiveAmount]);

  const handlePurposeCode = (event) => {
    const purposeCode = event.target.value;
    setPurposeCode(purposeCode);
    if (purposeCode) {
      setError((prevState) => ({
        ...prevState,
        purposeCodeError: "",
      }));
    }
  };

  const handleRemarksChange = (event) => {
    //setRemarks(event.target.value);
    if (event.target.value.length <= 140) {
      setRemarks(event.target.value);
      setError((prevState) => ({
        ...prevState,
        remarksError: "",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        remarksError: "Value should be less than 140 characters.",
      }));
    }
  };

  function updatePurposeCodeListForJapan(
    resData,
    setPurposeCodeList,
    getPurposeCodeList,
    response
  ) {
    if ("Japan" === resData.ReceiversCountryName) {
      setPurposeCodeList(
        resData.ReceiveAmount > 1000000
          ? getPurposeCodeList(response?.data?.data?.shared)
          : ""
      );
    }
  }

  function getAccountsList(list) {
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetAccountList_")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handleAccountChange = (event) => {
    const accountValue = event.target.value;
    setAccount(accountValue);
    if (accountValue) {
      setError((prev) => ({
        ...prev,
        accountError: "",
        sendCountry: "",
      }));
    }
    getDataForFromAccount(event.target.value, nextAssignmentId, eTag, false)
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          AccountBalance: resData.AccountBalance,
          SendCurrency: resData.SendCurrency,
          FromAccount: resData.FromAccount,
        });
        setflagselect("");
      })
      .catch((error) => {
        if (error.status != "401") {
        } else {
          navigate("/login");
        }
      });
  };

  const handleAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (value === "" || !isNaN(parsedValue)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        SendAmount: value,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Please enter a valid number.",
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError:
          "Send Amount should not greater than available balance",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }

    setLoading(true);
    let formattedValue;
    if (value && !value?.includes(".")) {
      formattedValue = `${value}.00`;
    }
    const amount = formattedValue || value;
    if (!isNaN(amount) && !error?.amountYouSendError) {
      debouncedGetDataForSendAmount(
        account,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            SendAmount: resData.SendAmount,
            ReceiveAmount: resData.ReceiveAmount,
            POPVisibility: resData.POPVisibility,
          });
          updatePurposeCodeListForJapan(
            resData,
            setPurposeCodeList,
            getPurposeCodeList,
            response
          );
        })
        .catch((error) => {
          if (error.status == "401") {
            navigate("/login");
          } else {
            console.log("Promise rejected with error:", error.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // const handleAmountInputBlur = () => {
  //   setLoading(true);
  //   let formattedValue;
  //   if (
  //     accountDetails.SendAmount &&
  //     !accountDetails?.SendAmount?.includes(".")
  //   ) {
  //     formattedValue = `${accountDetails?.SendAmount}.00`;
  //   }
  //   const amount = formattedValue || accountDetails.SendAmount;
  //   if (!isNaN(amount) && !error?.amountYouSendError) {
  //     getDataForSendAmount(
  //       account,
  //       amount,
  //       accountDetails.ReceiveCurrency,
  //       accountDetails.ReceiversCountryName,
  //       flagselect,
  //       nextAssignmentId,
  //       eTag,
  //       actionName
  //     )
  //       .then((response) => {
  //         const resData = response.data.data.caseInfo.content;
  //         setAccountDetails({
  //           ...accountDetails,
  //           CurrencyConvDesc: resData.CurrencyConvDesc,
  //           FXRateGoodForText: resData.FXRateGoodForText,
  //           TransactionDesc: resData.TransactionDesc,
  //           SendAmount: resData.SendAmount,
  //           ReceiveAmount: resData.ReceiveAmount,
  //           POPVisibility: resData.POPVisibility,
  //         });
  //         updatePurposeCodeListForJapan(
  //           resData,
  //           setPurposeCodeList,
  //           getPurposeCodeList,
  //           response
  //         );
  //       })
  //       .catch((error) => {
  //         if (error.status == "401") {
  //           navigate("/login");
  //         } else {
  //           console.log("Promise rejected with error:", error.message);
  //         }
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // };

  const handleReceiveAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (!isNaN(value) || value === "") {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        ReceiveAmount: value,
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError: "Amount Entered must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError:
          "Send Amount should not greater than available balance",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }

    setLoading(true);
    if ((!isNaN(value) && parsedValue) || value === "") {
      debouncedGetDataForReceiverAmount(
        account,
        accountDetails.SendAmount,
        value,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            ReceiveAmount: resData.ReceiveAmount,
            SendAmount: resData.SendAmount,
            POPVisibility: resData.POPVisibility,
          });
          updatePurposeCodeListForJapan(
            resData,
            setPurposeCodeList,
            getPurposeCodeList,
            response
          );
        })
        .catch((error) => {
          console.error("Error fetching receiver amount:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // const handleReceiveAmountInputBlur = () => {
  //   const amount = parseFloat(accountDetails.ReceiveAmount);
  //   if (!isNaN(amount) && accountDetails.ReceiveCurrency) {
  //     getDataForReceiverAmount(
  //       account,
  //       accountDetails.SendAmount,
  //       amount,
  //       accountDetails.ReceiveCurrency,
  //       accountDetails.ReceiversCountryName,
  //       flagselect,
  //       nextAssignmentId,
  //       eTag,
  //       actionName
  //     ).then((response) => {
  //       const resData = response.data.data.caseInfo.content;
  //       setAccountDetails({
  //         ...accountDetails,
  //         CurrencyConvDesc: resData.CurrencyConvDesc,
  //         FXRateGoodForText: resData.FXRateGoodForText,
  //         TransactionDesc: resData.TransactionDesc,
  //         ReceiveAmount: resData.ReceiveAmount,
  //         SendAmount: resData.SendAmount,
  //         POPVisibility: resData.POPVisibility,
  //       });
  //       updatePurposeCodeListForJapan(
  //         resData,
  //         setPurposeCodeList,
  //         getPurposeCodeList,
  //         response
  //       );
  //     });
  //   }
  //   const parsedValue = parseFloat(accountDetails.SendAmount);
  //   if (parsedValue < 0) {
  //     setError((prevState) => ({
  //       ...prevState,
  //       amountYouSendError: "Amount Entered must be greater than zero.",
  //     }));
  //   } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
  //     setError((prevState) => ({
  //       ...prevState,
  //       amountYouSendError:
  //         "Amount Entered should not greater than available balance.",
  //     }));
  //   }
  // };

  const handleReceiverCurrencyChange = (option) => {
    setLoading(true);
    const newCurrency = option.value;
    setSelectedCurrency(option);
    setAccountDetails((prev) => ({
      ...prev,
      ReceiveCurrency: newCurrency,
    }));
    getDataForReceiverCurrency(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      accountDetails.ReceiversCountryName,
      newCurrency,
      flagselect,
      nextAssignmentId,
      eTag,
      actionName
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
          POPVisibility: resData.POPVisibility,
        });
        updatePurposeCodeListForJapan(
          resData,
          setPurposeCodeList,
          getPurposeCodeList,
          response
        );
        setShowExchangeFields(true);
        //setSelectedCountryName(resData.ReceiversCountryName);
      })
      .catch((err) => {
        console.log(err.response.data.errorDetails[0].message);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReceiverCountryName = (event) => {
    const value = event.target.value;
    setSelectedCountryName(value);
    setFlagCode(flagsCode[value]);
    getDataForReceiversCountryName(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      value,
      nextAssignmentId,
      eTag
    ).then((response) => {
      const resData = response.data.data.caseInfo.content;
      setAccountDetails({
        ...accountDetails,
        CurrencyConvDesc: resData.CurrencyConvDesc,
        FXRateGoodForText: resData.FXRateGoodForText,
        TransactionDesc: resData.TransactionDesc,
        ReceiveAmount: resData.ReceiveAmount,
        ReceiveCurrency: resData.ReceiveCurrency,
        ReceiversCountryName: resData.ReceiversCountryName,
        SendAmount: resData.SendAmount,
      });
      if (response?.data?.data?.shared?.D_PurposeCodeList) {
        setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
      }
    });

    setShowAmountFields(true);
  };

  const countriesToShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      let countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();

        return isoCountryName === normalizedCountry;
      });

      if (!countryObj) {
        countryObj = iso3166.all().find((item) => {
          const isoCountryName = item.country.trim().toLowerCase();
          return isoCountryName.includes(normalizedCountry);
        });
      }

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return countryCode;
    })
    .filter(Boolean);

  const countriesShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      let countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();
        return isoCountryName === normalizedCountry;
      });

      if (!countryObj) {
        countryObj = iso3166.all().find((item) => {
          const isoCountryName = item.country.trim().toLowerCase();
          return isoCountryName.includes(normalizedCountry);
        });
      }

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return { [countryCode]: country };
    })
    .filter(Boolean)
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  const handleReceiverCountrySelect = (code) => {
    setflagselect(code);
    setSelectedCurrency(null);
    const countryName = countriesShow[code];
    setShowExchangeFields(false);
    setRemarks("");
    setPurposeCode("");
    setError((prev) => ({
      amountYouSendError: "",
      remarksError: "",
      amountReceiveError: "",
      purposeCodeError: "",
      sendCountry: "",
    }));
    setAccountDetails((prev) => ({
      ...prev,
      ReceiversCountryName: countryName,
      SendAmount: "",
      ReceiveAmount: "",
      CurrencyConvDesc: "",
      FXRateGoodForText: "",
      TransactionDesc: "",
      ReceiveCurrency: "",
    }));

    getDataForReceiversCountryName(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      countryName,
      code,
      nextAssignmentId,
      eTag
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
          POPVisibility: resData?.POPVisibility,
        });
        if (response?.data?.data?.shared?.D_PurposeCodeList) {
          setPurposeCodeList(getPurposeCodeList(response.data.data.shared));
        } else {
          setPurposeCodeList("");
        }
      })
      .catch((err) => {
        console.log(err);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      });
  };

  const validateCountry = () => {
    if (flagselect === "") {
      return flagselect === "" ? "Select a country" : "";
    } else {
      return false;
    }
  };

  const validateAmount = () => {
    const sendAmount = parseFloat(accountDetails?.SendAmount);

    let amountError = "";
    if (sendAmount <= 0) {
      amountError = "Send Amount entered must be greater than zero.";
    } else if (isNaN(sendAmount)) {
      amountError = "Value cannot be blank.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than Available balance.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than available balance";
    }
    return amountError;
  };
  const validateReceiveAmount = () => {
    const receiveAmount = parseFloat(accountDetails?.ReceiveAmount);
    let amountError = "";

    if (receiveAmount === 0) {
      amountError = "Amount entered must be greater than zero.";
    } else if (isNaN(receiveAmount) || receiveAmount < 0) {
      amountError = "Value cannot be blank.";
    } else if (receiveAmount > accountDetails?.AccountBalance) {
      amountError = "Amount Entered should not greater than available balance.";
    }

    return amountError;
  };

  const validateRemarks = () => {
    return remarks.trim() === "" ? "Value cannot be blank." : "";
  };

  const validatePurposeCode = () => {
    return purposeCode === "" ? "Value cannot be blank." : "";
  };

  const validateAccount = () => {
    if (account === "") {
      return account === "" ? "Select an account to continue." : "";
    } else {
      return false;
    }
  };
  // Main form validation
  const validateForm = () => {
    const amountError = validateAmount();
    const amountReceiveError = validateReceiveAmount();
    const remarksError = validateRemarks();
    const accountError = validateAccount();
    const sendCountry = validateCountry();
    let purposeCodeError;
    if (purposeCode) {
      purposeCodeError = validatePurposeCode();
    } else {
      purposeCodeError = false;
    }

    if (
      amountError ||
      remarksError ||
      amountReceiveError ||
      purposeCodeError ||
      accountError ||
      sendCountry
    ) {
      setError({
        amountYouSendError: amountError,
        remarksError: remarksError,
        amountReceiveError: amountReceiveError,
        accountError: accountError,
        sendCountry: sendCountry,
      });

      return false;
    }

    handleContinue();
    return true;
  };
  const checkErrors = (value) => {
    //console.log(validate);
    if (!validate) return null;

    const errorDetail = validate.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );
    console.log(errorDetail);

    return errorDetail?.message || null;
  };

  const handleContinue = async () => {
    try {
      // setLoading(true);
      const response = await getDataAfterFirstContinue(
        accountDetails.FromAccount,
        accountDetails.SendAmount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiveAmount,
        accountDetails.ReceiversCountryName,
        flagselect,
        purposeCode,
        remarks,
        nextAssignmentId,
        eTag
      );
      setEtag(response.headers["etag"]);
      const receiversCountryName = accountDetails.ReceiversCountryName;
      setReceiversCountryName(receiversCountryName);
      onContinue(nextAssignmentId, eTag, setEtag);
    } catch (error) {
    } finally {
      // setLoading(false);
    }
  };

  const uniqueCurrencies = avaiableToPayCurrencyList.filter(
    (currency, index, self) =>
      index ===
      self.findIndex(
        (element) => element.CurrencyName === currency.CurrencyName
      )
  );

  const options = uniqueCurrencies.map((currency) => ({
    value: currency.CurrencyName,
    label: currency.CurrencyName,
  }));

  const formatOptionLabel = ({ value }) => (
    <div className={classes.option}>
      {value && (
        <CurrencyFlag currency={value} className={classes.CurrencyFlag} />
      )}
      <span className={classes.optionValue}>{value}</span>
    </div>
  );

  const handleCancel = () => {
    navigate("/");
  };

  return (
    <>
      <Grid container>
        <Grid size={{ xs: 12 }}>
          <FormControl
            fullWidth
            variant="standard"
            className={classes.fullWidth}
            error={Boolean(checkErrors(".FromAccount"))}
          >
            <InputLabel className={classes.inputLabel}>
              Select Account To Make Payment From:
            </InputLabel>
            <Select
              id="Account"
              value={account}
              onChange={handleAccountChange}
              inputProps={{ className: classes.acctInput }}
              label="Select Account To Make Payment From "
              disableUnderline
              displayEmpty
            >
              {accounts &&
                accounts.map((acc) => (
                  <MenuItem key={acc.AccountNumber} value={acc.AccountNumber}>
                    {acc.MaskAccountNumber} {/* Masked account number shown */}
                  </MenuItem>
                ))}
            </Select>
            {checkErrors(".FromAccount") && (
              <FormHelperText>{checkErrors(".FromAccount")}</FormHelperText>
            )}
            {error?.accountError && (
              <span className={classes.errorMsg}>{error.accountError}</span>
            )}
          </FormControl>
        </Grid>
      </Grid>

      {account ? (
        <>
          <Grid container className={classes.spacingCont}>
            <Grid size={{ md: 6, xs: 12 }} className={classes.flexAlignCenter}>
              <Typography className={classes.availablebalance}>
                Available Balance :
              </Typography>
              <Typography variant="body2" className={classes.acountbal}>
                &nbsp;{accountDetails.AccountBalance}
              </Typography>
              &nbsp;
              <CurrencyFlag
                currency={accountDetails.SendCurrency}
                className={classes.CurrencyFlag}
              />
              <span className={classes.SendCurrency}>
                {accountDetails.SendCurrency}
              </span>
            </Grid>
          </Grid>
          <Divider />
          <Grid container className={classes.spacingCont}>
            <Grid size={{ xs: 12 }}>
              <Typography variant="body2" className={classes.availablebalance}>
                What country are the funds being sent to ?{" "}
                {/*  <span className={classes.mandatory}>* </span> */}
              </Typography>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <ReactFlagsSelect
                  selected={flagselect}
                  onSelect={handleReceiverCountrySelect}
                  countries={countriesToShow}
                  searchable
                  searchPlaceholder="Search by Country Name"
                  className={classes.sent}
                />
              </Box>
              {error?.sendCountry && (
                <span className={classes.errorMsg}>{error?.sendCountry}</span>
              )}
            </Grid>
            <Grid size={{ xs: 12 }}>
              {isShowWarning && (
                <Box className={classes.flexCenter}>
                  <InfoIcon className={classes.Infoicon} />
                  <Typography variant="body2" className={classes.warningMsg}>
                    Paying funds to a country in a currency other than its
                    domestic currency may result in the funds being converted by
                    the beneficiary bank before they are applied to the
                    beneficiary's account if that account is not held in the
                    currency of the payment. Please be sure you want to proceed
                    before continuing.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          {flagselect && (
            <>
              <Grid container className={classes.spacingCont}>
                <Grid className={classes.flexCenterAlign}>
                  <Typography
                    variant="body2"
                    className={classes.availablebalance}
                  >
                    Amount You Send
                  </Typography>
                  &nbsp;
                  <Tooltip
                    open={open}
                    onClose={handleTooltipClose}
                    onOpen={handleTooltipOpen}
                    disableHoverListener
                    PopperProps={{
                      disableInteractive: true,
                    }}
                    title="Enter the amount that you want to send in the currency of your account. The beneficiary will receive the equivalent of this amount."
                  >
                    <HelpOutlineOutlinedIcon
                      className={classes.tooltip}
                      fontSize="small"
                      onClick={handleTooltipOpen}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container className={classes.spacingCont}>
                <Grid size={{ xs: 12 }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleAmountInputChange}
                    // onBlur={handleAmountInputBlur}
                    value={accountDetails.SendAmount}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={classes.flexAlignCenter}
                          >
                            <CurrencyFlag
                              currency={accountDetails.SendCurrency}
                              className={classes.CurrencyFlag}
                            />
                            <span className={classes.send}>
                              &nbsp;{accountDetails.SendCurrency}
                            </span>
                          </InputAdornment>
                        ),
                        className: `${classes.textfeild} ${classes.fullWidth}`,
                      },
                    }}
                  />
                  {error.amountYouSendError && (
                    <div className="error-text">{error.amountYouSendError}</div>
                  )}
                </Grid>
              </Grid>
              <Grid container className={classes.spacingCont}>
                <Grid className={classes.flexCenterAlign}>
                  <Typography variant="body2" className={classes.Beneficiary}>
                    Beneficiary Gets
                  </Typography>
                  &nbsp;
                  <Tooltip
                    open={toolopen}
                    onClose={handleTipClose}
                    onOpen={handleTipOpen}
                    disableHoverListener
                    PopperProps={{
                      disableInteractive: true,
                    }}
                    title="Enter the amount that you want the beneficiary to receive. Your account will be debited for the equivalent amount, plus the transaction fee."
                  >
                    <HelpOutlineOutlinedIcon
                      className={classes.tip}
                      fontSize="small"
                      onClick={handleTipOpen}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid size={{ md: 5, xs: 12 }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleReceiveAmountInputChange}
                    // onBlur={handleReceiveAmountInputBlur}
                    // value={
                    //   accountDetails.ReceiveCurrency.includes("JPY")
                    //     ? accountDetails.ReceiveAmount &&
                    //       !isNaN(parseFloat(accountDetails.ReceiveAmount))
                    //       ? parseFloat(accountDetails.ReceiveAmount).toFixed(0)
                    //       : ""
                    //     : accountDetails.ReceiveAmount
                    // }
                    value={accountDetails.ReceiveAmount}
                    slotProps={{
                      input: {
                        className: `${classes.textfeild} ${classes.fullWidth}`,
                        endAdornment: (
                          <InputAdornment position="end">
                            <FormControl fullwidth variant="standard">
                              <SelectFeild
                                options={options}
                                value={selectedCurrency}
                                onChange={handleReceiverCurrencyChange}
                                formatOptionLabel={formatOptionLabel}
                                isSearchable={true}
                                placeholder="Select Currency"
                                className={classes.currencyCodeSelect}
                                classNamePrefix="custom"
                              />
                            </FormControl>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Grid>
                {error.amountReceiveError && (
                  <div className="error-text">{error.amountReceiveError}</div>
                )}
              </Grid>
            </>
          )}
          {loading ? (
            <Loader />
          ) : (
            flagselect &&
            showExchangeFields && (
              <Grid
                container
                className={`${classes.spacing} ${classes.exchangefields}`}
              >
                <Grid size={{ xs: 12 }} className={classes.flexAlignCenter}>
                  <Grid size={{ xs: 6 }}>
                    <Typography className={classes.text}>
                      Transaction Fee
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <Typography className={classes.inputBase}>
                      &nbsp;&nbsp;{accountDetails?.TransactionDesc}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid size={{ xs: 12 }} className={classes.flexAlignCenter}>
                  <Grid size={{ xs: 6 }}>
                    <Typography className={classes.text}>
                      Exchange Rate
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <Typography className={classes.inputBase}>
                      &nbsp;&nbsp;{accountDetails?.CurrencyConvDesc}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid size={{ xs: 12 }} className={classes.flexAlignCenter}>
                  <Grid size={{ xs: 6 }}>
                    <Typography className={classes.text}>
                      Exchange Rate Valid For
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <Typography className={classes.inputBase}>
                      &nbsp;&nbsp;{accountDetails?.FXRateGoodForText}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )
          )}
          <Grid container spacing={1}>
            {purposeCodeList && accountDetails?.POPVisibility && (
              <Grid size={{ xs: 12, md: 6 }} sx={{ marginTop: "10px" }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Purpose of Payment
                </Typography>
                <TextField
                  id="PurposeCode"
                  variant="outlined"
                  value={purposeCode}
                  size="small"
                  onChange={handlePurposeCode}
                  slotProps={{
                    input: { className: classes.purposeCodeDropdown },
                  }}
                  //label="Select Pay Purpose"
                  select
                  fullWidth
                >
                  {purposeCodeList &&
                    purposeCodeList.map((pCode) => (
                      <MenuItem
                        key={pCode.PayPurPCodeDesc}
                        value={pCode.PayPurPCodeDesc}
                      >
                        {pCode.PayPurPCode}
                      </MenuItem>
                    ))}
                </TextField>
                {error.purposeCodeError && (
                  <div className="error-text">{error.purposeCodeError}</div>
                )}
              </Grid>
            )}
            {flagselect && (
              <Grid size={{ md: 6, xs: 12 }} sx={{ marginTop: "10px" }}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Remarks {/* <span className={classes.mandatory}>* </span> */}
                </Typography>
                <TextField
                  id="remarks"
                  // label="Remarks"
                  size="small"
                  multiline
                  rows={1}
                  fullWidth
                  value={remarks}
                  onChange={handleRemarksChange}
                  slotProps={{
                    input: {
                      className: classes.purposeCodeDropdown,
                      maxLength: 140,
                    },
                  }}
                />
                {error.remarksError && (
                  <div className="error-text">{error.remarksError}</div>
                )}
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <></>
      )}
      <Grid container className={classes.spacing}>
        <Grid size={{ md: 12, xs: 12 }} className={classes.flexBetween}>
          <Button className={classes.cancel} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.continue}
            // onClick={handleContinue}
            onClick={validateForm}
          >
            Continue
          </Button>
        </Grid>
        {/*  <p className={classes.requiredfeild}>
          <span className={classes.required}>*</span> Fields are required
        </p> */}
      </Grid>
    </>
  );
};

export default AmountSection;
