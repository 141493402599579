import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";

const SortCodeField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    editableBeneDetails,
    SelectedBeneDetails,
    isEditable,
    flag,
    handleEditChange,
    handleInputChange,
  } = props;

  return (
    <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
      <Typography variant="body2" className={classes.headerstyle}>
        Sort Code
      </Typography>
      {isEditable ? (
        <TextField
          variant="outlined"
          size="small"
          value={editableBeneDetails?.ClrSysMmbId}
          onChange={handleInputChange}
          onBlur={handleEditChange}
          name="ClrSysMmbId"
        />
      ) : editableBeneDetails?.ClrSysMmbId ? (
        <span className={classes.contentstyle}>
          {editableBeneDetails?.ClrSysMmbId}
        </span>
      ) : SelectedBeneDetails?.ClrSysMmbId ? (
        <span className={classes.contentstyle}>
          {SelectedBeneDetails?.ClrSysMmbId}
        </span>
      ) : (
        "---"
      )}
      {flag && editableBeneDetails?.IsValidAccountTypeURL && (
        <img
          src={editableBeneDetails?.IsValidAccountTypeURL}
          alt="checkmark"
          className={classes.checkMark}
        />
      )}
    </Grid>
  );
};

export default SortCodeField;
