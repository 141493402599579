import React, { useState } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";

const AddressMessage = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;

  const { editableBeneDetails, setEditableBeneDetails } = props;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const [checkedItems, setCheckedItems] = useState(
    editableBeneDetails?.EnableAPIAddress
  );

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;
    // setCheckedItems((prevState) => ({
    //   ...prevState,
    //   [name]: checked,
    // }));
    setCheckedItems(checked);
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      [name]: checked,
    }));
  };

  return (
    <Grid size={{ xs: 12, md: 12 }} className={classes.spacing}>
      {/* <Typography variant="body2" className={classes.headerstyle}>
           Address Message
        </Typography> */}
      {editableBeneDetails?.AddressMessage && (
        <Typography variant="body2" className={classes.textFont}>
          <Checkbox
            name="EnableAPIAddress"
            className={classes.checkboxStyles}
            checked={checkedItems}
            onChange={handleCheckbox}
            style={{ color: "#52459f" }}
          />{" "}
          {editableBeneDetails?.AddressMessage &&
            editableBeneDetails?.AddressMessage}
        </Typography>
      )}
    </Grid>
  );
};

export default AddressMessage;
