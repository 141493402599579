import React from 'react'
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography,TextField,Box } from '@mui/material';
import { useSelector } from "react-redux";

const BeneficiaryBankNameField = (props) => {
    const selector = useSelector((data) => data?.mobileReducer);
    const isMobile = selector?.ismobile;
    const classes = isMobile ? MobileStyles() : WebStyles();
    const { editableBeneDetails,isEditable,flag,handleEditChange,handleInputChange } = props;
    console.log(editableBeneDetails)
  return (
    <>
        <Grid size={{xs:12,md:6}} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank Name
                </Typography>
                <Box className={classes.flagAlignments}>
                  <Typography className={classes.BankAddress}>
                    <span className={classes.contentstyle}>
                      {editableBeneDetails.BeneBankName?editableBeneDetails.BeneBankName:"---"}
                    </span>
                  
                  </Typography>
                  <span className={classes.addressFlag}>
                    {flag && editableBeneDetails?.IsValidBeneAddressURL && (
                      <img
                        src={editableBeneDetails?.IsValidBeneAddressURL}
                        className={classes.checkMark}
                        alt="checkmark"
                      />
                    )}
                  </span>
                </Box>
                </Grid>
    </>
  )
}

export default BeneficiaryBankNameField
