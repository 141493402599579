import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import {
  getDataForSendAmount,
  getDataForReceiverCurrency,
  getDataForReceiverAmount,
  payAgainProcessSubmit,
  sendPaymentFinalSubmit,
} from "../../CommonService/sendPaymentService";
import iso3166 from "iso-3166-1";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import HelpIcon from "@mui/icons-material/Help";
import SelectFeild from "react-select";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputBase,Tooltip
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate, useLocation } from "react-router-dom";
import FinalResponse from "../MobileSendPayment/FinalResponse";
import warningIcon from "../../../assets/images/warningicon.svg";
import CountrySpecificComponent from "../MobileSendPayment/CountrySpecificComponent";
import Loader from "../Loader";
import { Height } from "@mui/icons-material";
import Flag from "react-world-flags";

const RepeatTransaction = () => {
  const classes = useStyles();
  const location = useLocation();

  const { state } = location;
  const [accounts, setAccounts] = useState([]);
  const [payAgainData] = useState(state);
  const [account, setAccount] = useState("");
  const [isShowWarning, setShowWarning] = useState(false);
  const [showExchangeFields, setShowExchangeFields] = useState(false);
  const [purposeCode, setPurposeCode] = useState("");
  const [purposeCodeList, setPurposeCodeList] = useState("");
  const [flagselect, setflagselect] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState({});
  const [icon, seticon] = useState(false);
  const [removeicon, setremoveicon] = useState(false);
  const [accIcon, setaccIcon] = useState(false);
  const [visible, setvisibile] = useState(true);
  const [transaction, settransaction] = useState(true);
  const [accDetails, setaccDetails] = useState(true);
  const navigate = useNavigate();
  const [avaiableToPayCurrencyList, setAvailableToPayCurrencyList] = useState(
    []
  );
  const [setValidateCurrency] = useState("");
  const [nextAssignmentId, setnextAssignmentId] = useState();
  const [caseId, setCaseId] = useState();
  const [eTag, setEtag] = useState();
  const [availableToPayCountryNamesList, setAvailableToPayCountryNamesList] =
    useState([]);
  const [payAgainEtag] = useState(state?.etag);
  const [editableBeneDetails] = useState(
    state?.response?.data.caseInfo?.content?.SelectedBeneDetails
  );
  const [actionName, setActionName] = useState(
    state?.response?.data.caseInfo?.content?.pyViewName
  );
  const [finalResponse, setFinalResponse] = useState();
  const [isFinalResponse, setIsFinalResponse] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if ("RepeatTransaction" === actionName) {
      setDisable(true);
    }
  }, [actionName]);

  const [checkbox_Details] = useState(
    state?.response?.uiResources.resources.views?.[actionName]?.[0]?.children[0]
      .children
  );

  const [accountDetails, setAccountDetails] = useState({
    FromAccount: "",
    AccountBalance: "",
    SendAmount: "",
    ReceiveAmount: "",
    SendCurrency: "",
    CurrencyConvDesc: "",
    FXRateGoodForText: "",
    ReceiversCountryName: "",
    ReceiveCurrency: "",
  });

  const getCountryCode = (countryName) => {
    const normalizedCountry = countryName.trim().toLowerCase();
    let countryObj = iso3166.all().find((item) => {
      const isoCountryName = item.country.toLowerCase();

      return isoCountryName === normalizedCountry;
    });

    if (!countryObj) {
      countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.trim().toLowerCase();
        return isoCountryName.includes(normalizedCountry);
      });
    }

    return countryObj.alpha2;
  };

  const countriesShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      let countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();
        return isoCountryName === normalizedCountry;
      });

      if (!countryObj) {
        countryObj = iso3166.all().find((item) => {
          const isoCountryName = item.country.trim().toLowerCase();
          return isoCountryName.includes(normalizedCountry);
        });
      }

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return { [countryCode]: country };
    })
    .filter(Boolean)
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  const matchCountries = (countryName) => {
    for (const [code, fullCountryName] of Object.entries(countriesShow)) {
      if (countryName === countriesShow[code]) {
        return code;
      }
    }
  };

  useEffect(() => {
    const initialCheckedState = checkbox_Details.reduce((acc, data) => {
      if (data?.type === "Checkbox" && data?.config?.value) {
        acc[data.config.value.replace(/^@P \./, "")] = false;
      }
      return acc;
    }, {});
    setCheckedItems(initialCheckedState); // Set initial checked state
  }, [checkbox_Details]);

  function getAccounts(response) {
    const list = response.shared.D_GetAccountList;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetAccountList_")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  function getCountryNamesList(response) {
    const list = response.shared.D_GetCountryName;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetCountryName")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked, // Toggle the checked state of the checkbox
    }));
  };

  useEffect(() => {
    setnextAssignmentId(payAgainData?.response?.nextAssignmentInfo?.ID);
    setCaseId(payAgainData?.response?.data.caseInfo?.ID.split(" ")[1]);
    setAvailableToPayCountryNamesList(
      getCountryNamesList(payAgainData?.response?.data)
    );
    let pxResults = getAccounts(payAgainData?.response?.data);
    setAccounts(pxResults);
    setAvailableToPayCurrencyList(
      payAgainData?.response?.data.shared.D_AvailabletoPayCurrencyListAPIList
        .D_AvailabletoPayCurrencyListAPIList.pxResults
    );
    setEtag(payAgainEtag);

    setAccount(payAgainData?.response?.data.caseInfo?.content?.FromAccount);
    matchCountries(
      payAgainData?.response?.data.caseInfo?.content?.ReceiversCountryName
    );
    setflagselect(
      matchCountries(
        payAgainData?.response?.data.caseInfo?.content?.ReceiversCountryName
      )
    );
    const option = {
      label: payAgainData?.response?.data.caseInfo?.content?.ReceiveCurrency,
      value: payAgainData?.response?.data.caseInfo?.content?.ReceiveCurrency,
    };
    setSelectedCurrency(option);
    setShowExchangeFields(true);
    if (payAgainData?.response?.data?.shared?.D_PurposeCodeList) {
      setPurposeCodeList(
        getPurposeCodeList(payAgainData?.response.data.shared)
      );
    } else {
      setPurposeCodeList("");
    }
    const newDetails = payAgainData?.response?.data.caseInfo?.content;
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      ...newDetails,
    }));
    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    const selectedvalue = selectedCurrency?.value?.slice(0, 2);
    if (flagselect && selectedvalue) {
      setShowWarning(flagselect !== selectedvalue);
    } else {
      setShowWarning(false);
    }
  }, [flagselect, selectedCurrency]);

  function getPurposeCodeList(response) {
    const list = response.D_PurposeCodeList;
    const keys = Object.keys(list);
    let pxResults = [];
    for (const key of keys) {
      if (key.startsWith("D_PurposeCodeList")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handlePurposeCode = (event) => {
    const purposeCode = event.target.value;
    setPurposeCode(purposeCode);
  };

  const handleAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (value === "" || !isNaN(parsedValue)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        SendAmount: value,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Please enter a valid number.",
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount should not exceed Available balance.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }
  };

  const handleAmountInputBlur = () => {
    const amount = parseFloat(accountDetails.SendAmount);
    if (!isNaN(amount) && !error?.amountYouSendError) {
      getDataForSendAmount(
        account,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            SendAmount: resData.SendAmount,
            ReceiveAmount: resData.ReceiveAmount,
          });
          setShowExchangeFields(true);
        })
        .catch((error) => {
          if (error.status == "401") {
            navigate("/login");
          } else {
            console.log("Promise rejected with error:", error.message);
          }
        });
    }
  };

  const handleReceiveAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (!isNaN(value) || value === "") {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        ReceiveAmount: value,
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError: "Amount Entered must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError:
          "Amount Entered should not exceed Available balance.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }
  };

  const handleReceiveAmountInputBlur = () => {
    const amount = parseFloat(accountDetails.ReceiveAmount);
    if (!isNaN(amount) && accountDetails.ReceiveCurrency) {
      getDataForReceiverAmount(
        account,
        accountDetails.SendAmount,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      ).then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          SendAmount: resData.SendAmount,
        });
        setShowExchangeFields(true);
      });
    }
  };

  const handleReceiverCurrencyChange = (option) => {
    const newCurrency = option.value;
    setSelectedCurrency(option);
    setAccountDetails((prev) => ({
      ...prev,
      ReceiveCurrency: newCurrency,
    }));
    getDataForReceiverCurrency(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      accountDetails.ReceiversCountryName,
      newCurrency,
      flagselect,
      nextAssignmentId,
      eTag,
      actionName
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
        });
        //setSelectedCountryName(resData.ReceiversCountryName);
      })
      .catch((err) => {
        console.log(err.response.data.errorDetails[0].message);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      });
  };

  const countriesToShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      const countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();

        return isoCountryName.includes(normalizedCountry);
      });

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return countryCode;
    })
    .filter(Boolean);

  const validateAmount = () => {
    const sendAmount = parseFloat(accountDetails?.SendAmount);
    const receiveAmount = parseFloat(accountDetails?.ReceiveAmount);
    if (
      isNaN(sendAmount) ||
      sendAmount <= 0 ||
      isNaN(receiveAmount) ||
      receiveAmount <= 0
    ) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError:
          "Send Amount must be a valid number greater than zero.",
        amountEnteredError:
          "Amount Entered must be a valid number greater than zero.",
      }));
    } else if (
      sendAmount > accountDetails?.AccountBalance ||
      receiveAmount > accountDetails?.AccountBalance
    ) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError:
          "Send Amount should not greater than Available balance",
        amountEnteredError:
          "Amount Entered should not greater than Available balance",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }
  };

  const validateRemarks = () => {
    return remarks.trim() === "" ? "Value cannot be blank." : "";
  };

  const validateForm = () => {
    const amountError = validateAmount();
    const remarksError = validateRemarks();

    if (amountError || remarksError) {
      setError({
        amountYouSendError: amountError,
        remarksError: remarksError,
      });

      return false;
    }

    handleSubmit();
    return true;
  };

  const handleSubmit = async () => {
    try {
      const payLoad = {
        content: {
          EnterAmount: "",
          ReceiveAmount: accountDetails.ReceiveAmount,
          ReceiveCurrency: accountDetails.ReceiveCurrency,
          ReceiversCountryName: accountDetails.ReceiversCountryName,
          SendAmount: accountDetails.SendAmount,
          ConfirmAcknowledgement: checkedItems.ConfirmAcknowledgement,
          PayAllCharges: checkedItems.PayAllCharges,
          Remarks: remarks,
        },
        pageInstructions: [],
      };
      const response = await payAgainProcessSubmit(
        payLoad,
        nextAssignmentId,
        eTag,
        actionName
      );
      if (response.status === 200) {
        const res = await sendPaymentFinalSubmit(
          eTag,
          response?.data?.data?.caseInfo?.ID
        );
        if (res?.data?.data?.caseInfo) {
          navigate("/moneysent", {
            state: {
              response: res?.data?.data?.caseInfo,
            },
          });
          // setFinalResponse(res?.data?.data?.caseInfo);
          setIsFinalResponse(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const options = avaiableToPayCurrencyList.map((currency) => ({
    value: currency.CurrencyName,
    label: currency.CurrencyName,
  }));

  const formatOptionLabel = ({ value }) => (
    <div className={classes.option}>
      {value && <CurrencyFlag currency={value} />}
      <span className={classes.optionValue}>{value}</span>
    </div>
  );

  const handleCancel = () => {
    navigate("/recentpayments");
  };

  const handlevisibility = () => {
    if (visible) {
      setvisibile(false);
    } else {
      setvisibile(true);
    }
    seticon((prev) => !prev);
  };

  const TransactioinVisible = () => {
    if (transaction) {
      settransaction(false);
    } else {
      settransaction(true);
    }
    setremoveicon((prev) => !prev);
  };

  const accountVisible = () => {
    if (accDetails) {
      setaccDetails(false);
    } else {
      setaccDetails(true);
    }
    setaccIcon((prev) => !prev);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {!isFinalResponse && (
            <>
              <Box className={classes.rootContainer}>
                <Grid size={{ md: 12, xs: 12 }}>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    color="textPrimary"
                    gutterBottom
                  >
                    {actionName === "RepeatTransaction" && "Repeat Transaction"}
                  </Typography>
                  <Divider />
                </Grid>
                <Grid
                  size={{ md: 12, xs: 12 }}
                  className={`${classes.header} ${classes.headerDetails}`}
                >
                  <Typography variant="body2" className={classes.headerBene}>
                    Account Details
                  </Typography>
                  {!accIcon && (
                    <RemoveCircleRoundedIcon
                      onClick={accountVisible}
                      fontSize="small"
                    />
                  )}
                  {accIcon && (
                    <AddCircleRoundedIcon
                      onClick={accountVisible}
                      fontSize="small"
                    />
                  )}
                </Grid>
                {accDetails && (
                  <Box className={classes.accountDetailsPad}>
                    <div className={classes.helpIconCont}>
                      <HelpIcon className={classes.helpIcon} />
                    </div>
                    <Grid container>
                      <Grid size={{ md: 6, xs: 6 }}>
                        <Typography className={classes.headerstyle}>
                          Account to be Debited
                        </Typography>
                        <Typography className={classes.contentstyle}>
                          {accounts &&
                            accounts.map((acc) => {
                              if (acc.AccountNumber.includes(account)) {
                                return acc.MaskAccountNumber;
                              }
                              return null;
                            })}
                        </Typography>
                      </Grid>
                      <Grid size={{ md: 6, xs: 6 }}>
                        {account && (
                          <>
                            <Typography className={classes.headerstyle}>
                              Available Balance:
                            </Typography>
                            <Typography
                              className={`${classes.contentstyle} ${classes.flexCenterAlign}`}
                            >
                              {accountDetails.AccountBalance}
                              &nbsp;
                              <CurrencyFlag
                                currency={accountDetails?.SendCurrency}
                                className={classes.CurrencyFlag}
                              />
                              <span className={classes.Sendcurrency}>
                                {accountDetails?.SendCurrency}
                              </span>
                            </Typography>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Divider sx={{ marginTop: "18px" }} />
                  </Box>
                )}

                <Grid className={`${classes.header} ${classes.headerDetails}`}>
                  <Typography variant="body2" className={classes.headerBene}>
                    Transaction Details
                  </Typography>
                  {!removeicon && (
                    <RemoveCircleRoundedIcon
                      onClick={TransactioinVisible}
                      fontSize="small"
                    />
                  )}
                  {removeicon && (
                    <AddCircleRoundedIcon
                      onClick={TransactioinVisible}
                      fontSize="small"
                    />
                  )}
                </Grid>

                {account ? (
                  <>
                    {transaction && (
                      <Box className={classes.accountDetailsPad}>
                        <Grid container className={classes.spacing}>
                          <Grid size={{ md: 5, xs: 12 }}>
                            <Typography className={classes.headerstyle}>
                              Pay to Country
                            </Typography>
                            <Typography
                              className={`${classes.contentstyle} ${classes.flexCenterAlign}`}
                            >
                              {accountDetails.ReceiversCountryName}
                              &nbsp;
                              <Flag
                                code={getCountryCode(
                                  accountDetails.ReceiversCountryName
                                )}
                                className={classes.CountryFlag}
                              />
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          className={`${classes.flexCenterAlign} ${classes.spacing}`}
                        >
                          <Grid size={{ md: 5, xs: 5 }}>
                            <Typography className={classes.headerstyle}>
                              Amount You Send&nbsp;
                              <Tooltip title="Enter the amount that you want to send in the currency of your account.The beneficiary will receive the equivalent of this amount.">
                                <HelpOutlineOutlinedIcon
                                  className={classes.InfoOutlinedIcon}
                                />
                              </Tooltip>
                            </Typography>
                            <Typography
                              className={`${classes.contentstyle} ${classes.flexCenterAlign}`}
                            >
                              {accountDetails.SendAmount}
                              &nbsp;
                              <CurrencyFlag
                                currency={accountDetails?.SendCurrency}
                                className={classes.CurrencyFlag}
                              />
                              <span className={classes.Sendcurrency}>
                                {accountDetails?.SendCurrency}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid size={{ md: 2, xs: 2 }}>
                            <SwapHorizIcon className={classes.SwaphorizIcon} />
                          </Grid>
                          <Grid size={{ md: 5, xs: 5 }}>
                            <Typography className={classes.headerstyle}>
                              Beneficiary Gets &nbsp;
                              <Tooltip title="Enter the amount that you want beneficiary to receive.Your account will be debited for the equivalent amount, plus transaction fee.">
                                <HelpOutlineOutlinedIcon
                                  className={classes.InfoOutlinedIcon}
                                />
                              </Tooltip>
                            </Typography>
                            <Typography
                              className={`${classes.contentstyle} ${classes.flexCenterAlign}`}
                            >
                              {accountDetails.ReceiveAmount}
                              &nbsp;
                              <CurrencyFlag
                                currency={accountDetails?.ReceiveCurrency}
                                className={classes.CurrencyFlag}
                              />
                              <span className={classes.Sendcurrency}>
                                {accountDetails?.ReceiveCurrency}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>

                        {loading ? (
                          <Loader />
                        ) : (
                          // flagselect &&
                          showExchangeFields && (
                            <Grid
                              container
                              className={`${classes.spacing} ${classes.conversionFields}`}
                            >
                              <Grid
                                size={{ xs: 12 }}
                                mt={1}
                                className={classes.flexAlignCenter}
                              >
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.textcontent}>
                                    Transaction Fee
                                  </Typography>
                                </Grid>
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.inputvalue}>
                                    &nbsp;&nbsp;
                                    {accountDetails?.TransactionDesc}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                size={{ xs: 12 }}
                                mt={1}
                                className={classes.flexAlignCenter}
                              >
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.textcontent}>
                                    Exchange Rate
                                  </Typography>
                                </Grid>
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.inputvalue}>
                                    &nbsp;&nbsp;
                                    {accountDetails?.CurrencyConvDesc}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                size={{ xs: 12 }}
                                mt={1}
                                className={classes.flexAlignCenter}
                              >
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.textcontent}>
                                    Exchange Rate Valid For
                                  </Typography>
                                </Grid>
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.inputvalue}>
                                    &nbsp;&nbsp;
                                    {accountDetails?.FXRateGoodForText}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                size={{ xs: 12 }}
                                mt={1}
                                mb={1}
                                className={classes.flexAlignCenter}
                              >
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.textcontent}>
                                    Estimated Delivery Time
                                  </Typography>
                                </Grid>
                                <Grid size={{ xs: 6 }}>
                                  <Typography className={classes.inputvalue}>
                                    {accountDetails.EstimatedDeliveryTime}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        )}

                        <Grid container spacing={1}>
                          {purposeCodeList &&
                            accountDetails?.PurposeofPaymentVisibility && (
                              <>
                                <Grid
                                  size={{ xs: 12, md: 5 }}
                                  className={classes.purpose}
                                >
                                  <Typography
                                    variant="body2"
                                    className={classes.headerstyle}
                                  >
                                    Purpose of Payment
                                  </Typography>

                                  <TextField
                                    id="PurposeCode"
                                    variant="outlined"
                                    value={purposeCode}
                                    size="small"
                                    onChange={handlePurposeCode}
                                    slotProps={{
                                      input: {
                                        className: `${classes.purposeCodeDropdown} ${classes.remarks}`,
                                      },
                                    }}
                                    //label="Select Pay Purpose"
                                    select
                                    fullWidth
                                  >
                                    {purposeCodeList &&
                                      purposeCodeList.map((pCode) => (
                                        <MenuItem
                                          key={pCode.PayPurPCodeDesc}
                                          value={pCode.PayPurPCodeDesc}
                                        >
                                          {pCode.PayPurPCode}
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                  {error.purposeCodeError && (
                                    <div className="error-text">
                                      {error.purposeCodeError}
                                    </div>
                                  )}
                                </Grid>
                                <Grid siz={{ md: 2 }}></Grid>
                              </>
                            )}
                          {/* {accountDetails?.PaymentPurposeCode_RVW && (
                            <>
                              <Grid
                                size={{ xs: 12, md: 5 }}
                                className={classes.purpose}
                              >
                                <Typography
                                  variant="body2"
                                  className={classes.headerstyle}
                                >
                                  Purpose of Payment
                                </Typography>
                                <Typography className={classes.contentstyle}>
                                  {accountDetails?.PaymentPurposeCode_RVW}
                                </Typography>
                              </Grid>
                              <Grid siz={{ md: 2 }}></Grid>
                            </>
                          )} */}

                          {/* {flagselect && ( */}
                          <Grid
                            size={{ md: 6, xs: 12 }}
                            className={classes.purpose}
                          >
                            <Typography
                              variant="body2"
                              className={classes.headerstyle}
                            >
                              Remarks
                            </Typography>
                            <TextField
                              id="remarks"
                              //label="Remarks"
                              // multiline
                              // rows={1}
                              size="small"
                              value={remarks}
                              onChange={(e) => setRemarks(e.target.value)}
                              className={classes.remarks}
                              slotProps={{
                                input: {
                                  className: `${classes.borderstyle} ${classes.remarksText}`,
                                },
                              }}
                            />
                            {error.remarksError && (
                              <div className="error-text">
                                {error.remarksError}
                              </div>
                            )}
                          </Grid>
                          {/* )} */}
                        </Grid>

                        <Divider sx={{ marginTop: "24px" }} />
                      </Box>
                    )}
                    <Grid container className={classes.header}>
                      <Grid className={classes.headerDetails}>
                        <Typography
                          variant="body2"
                          fontSize="small"
                          className={classes.headerBene}
                        >
                          {" "}
                          Beneficiary and Bank Details
                        </Typography>
                        {!icon && (
                          <RemoveCircleRoundedIcon
                            onClick={handlevisibility}
                            fontSize="small"
                          />
                        )}
                        {icon && (
                          <AddCircleRoundedIcon
                            onClick={handlevisibility}
                            fontSize="small"
                          />
                        )}
                      </Grid>
                    </Grid>
                    {visible && (
                      <Box className={classes.accountDetailsPad}>
                        <CountrySpecificComponent
                          editableBeneDetails={editableBeneDetails}
                          payAgainOrRepeatTransaction={true}
                          flag={true}
                        />
                      </Box>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <Box sx={{ padding: "20px 20px 0px 20px" }}>
                  {checkbox_Details.map((data, index) => {
                    if (data?.type === "RadioButtons") {
                      return (
                        <Grid
                          container
                          //className={classes.spacing}
                          // spacing={0.5}
                        >
                          <Grid
                            size={{ md: 12 }}
                            className={classes.flexCenterAlign}
                          >
                            <span>
                              <img
                                src={warningIcon}
                                className={classes.warningIcon}
                              />
                            </span>
                            &nbsp;&nbsp;
                            <Typography className={classes.message}>
                              {data.config.label?.replace(/@L\s*/, "")}
                            </Typography>
                          </Grid>
                          <Grid size={{ md: 12 }} ml={5} pr={30}>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                row
                                style={{ display: "flex" }}
                              >
                                <div style={{ display: "flex" }}>
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio size="small" />}
                                    label={
                                      <Typography
                                        className={classes.contentstyle}
                                      >
                                        Yes
                                      </Typography>
                                    }
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio size="small" />}
                                    label={
                                      <Typography
                                        className={classes.contentstyle}
                                      >
                                        No
                                      </Typography>
                                    }
                                  />
                                </div>
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      );
                    }
                    if (data?.type === "Checkbox") {
                      const checkboxName = data?.config?.value.replace(
                        /^@P \./,
                        ""
                      );
                      return (
                        <Box key={data.id} className={classes.spacing}>
                          <Grid className={classes.flex}>
                            <Checkbox
                              name={checkboxName}
                              className={classes.checkboxStyles}
                              checked={!!checkedItems[checkboxName]}
                              onChange={handleCheckbox}
                              style={{ color: "#52459f " }}
                            />
                            <Typography
                              variant="body2"
                              className={classes.textFont}
                            >
                              {data.config.caption.replace(/@L\s*/, "")}
                            </Typography>
                          </Grid>
                        </Box>
                      );
                    }
                    return null;
                  })}

                  <Grid container spacing={2} className={classes.spacingCont}>
                    <Grid
                      size={{ xs: 12, md: 12 }}
                      className={classes.flexBetween}
                    >
                      <Button className={classes.cancel} onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.continue}
                        disabled={!checkedItems.ConfirmAcknowledgement}
                        onClick={validateForm}
                      >
                        Review & Pay
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          )}
          {/* {isFinalResponse && <FinalResponse finalResponse={finalResponse} />} */}
        </>
      )}
    </>
  );
};

export default RepeatTransaction;
