import React from 'react'
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography,TextField,Box } from '@mui/material';
import { useSelector } from "react-redux";

const BeneficiaryBankBICCodeFeild = (props) => {
    const selector = useSelector((data) => data?.mobileReducer);
    const isMobile = selector?.ismobile;
    const classes = isMobile ? MobileStyles() : WebStyles();
    const { editableBeneDetails,isEditable,flag,handleEditChange,handleInputChange } = props;
  return (
    <>
        
        <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank BIC Code
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.BICFI}
                    onChange={handleInputChange}
                    onBlur={handleEditChange}
                    name="BICFI"
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.BICFI}
                  </span>
                )}
                {flag && editableBeneDetails?.IsValidBeneBICCodeURL && (
                  <img
                    src={editableBeneDetails?.IsValidBeneBICCodeURL}
                    className={classes.checkMark}
                    alt="checkmark"
                  />
                )}
              </Grid>

    </>
  )
}

export default BeneficiaryBankBICCodeFeild
