import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import CurrencyFlag from "react-currency-flags";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import HelpIcon from "@mui/icons-material/Help";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  getDataForSendAmount,
  getDataForReceiverCurrency,
  getDataForReceiverAmount,
  payAgainProcessSubmit,
  sendPaymentFinalSubmit,
} from "../../CommonService/sendPaymentService";
import iso3166 from "iso-3166-1";
import SelectFeild from "react-select";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,Tooltip
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate, useLocation } from "react-router-dom";
import FinalResponse from "../SendPayment/FinalResponse";
import warningIcon from "../../../assets/images/warningicon.svg";
import CountrySpecificComponent from "../SendPayment/CountrySpecificComponent";
import Loader from "../Loader";
import Flag from "react-world-flags";

const RepeatTransaction = () => {
  const classes = useStyles();
  const location = useLocation();

  const { state } = location;
  const [accounts, setAccounts] = useState([]);
  const [payAgainData] = useState(state);
  const [account, setAccount] = useState("");
  const [isShowWarning, setShowWarning] = useState(false);
  const [showExchangeFields, setShowExchangeFields] = useState(false);
  const [purposeCode, setPurposeCode] = useState("");
  const [purposeCodeList, setPurposeCodeList] = useState("");
  const [flagselect, setflagselect] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const [avaiableToPayCurrencyList, setAvailableToPayCurrencyList] = useState(
    []
  );
  const [setValidateCurrency] = useState("");
  const [nextAssignmentId, setnextAssignmentId] = useState();
  const [caseId, setCaseId] = useState();
  const [eTag, setEtag] = useState();
  const [availableToPayCountryNamesList, setAvailableToPayCountryNamesList] =
    useState([]);
  const [payAgainEtag] = useState(state?.etag);
  const [editableBeneDetails] = useState(
    state?.response?.data.caseInfo?.content?.SelectedBeneDetails
  );
  const [actionName, setActionName] = useState(
    state?.response?.data.caseInfo?.content?.pyViewName
  );
  const [finalResponse, setFinalResponse] = useState();
  const [isFinalResponse, setIsFinalResponse] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if ("RepeatTransaction" === actionName) {
      setDisable(true);
    }
  }, [actionName]);

  const [checkbox_Details] = useState(
    state?.response?.uiResources.resources.views?.[actionName]?.[0]?.children[0]
      .children
  );

  const [accountDetails, setAccountDetails] = useState({
    FromAccount: "",
    AccountBalance: "",
    SendAmount: "",
    ReceiveAmount: "",
    SendCurrency: "",
    CurrencyConvDesc: "",
    FXRateGoodForText: "",
    ReceiversCountryName: "",
    ReceiveCurrency: "",
  });

  const countriesShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      let countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();
        return isoCountryName === normalizedCountry;
      });

      if (!countryObj) {
        countryObj = iso3166.all().find((item) => {
          const isoCountryName = item.country.trim().toLowerCase();
          return isoCountryName.includes(normalizedCountry);
        });
      }

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return { [countryCode]: country };
    })
    .filter(Boolean)
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  const matchCountries = (countryName) => {
    for (const [code, fullCountryName] of Object.entries(countriesShow)) {
      if (countryName === countriesShow[code]) {
        return code;
      }
    }
  };

  useEffect(() => {
    const initialCheckedState = checkbox_Details.reduce((acc, data) => {
      if (data?.type === "Checkbox" && data?.config?.value) {
        acc[data.config.value.replace(/^@P \./, "")] = false;
      }
      return acc;
    }, {});
    setCheckedItems(initialCheckedState); // Set initial checked state
  }, [checkbox_Details]);

  function getAccounts(response) {
    const list = response.shared.D_GetAccountList;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetAccountList_")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const getCountryCode = (countryName) => {
    const normalizedCountry = countryName.trim().toLowerCase();
    let countryObj = iso3166.all().find((item) => {
      const isoCountryName = item.country.toLowerCase();

      return isoCountryName === normalizedCountry;
    });

    if (!countryObj) {
      countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.trim().toLowerCase();
        return isoCountryName.includes(normalizedCountry);
      });
    }

    return countryObj.alpha2;
  };

  function getCountryNamesList(response) {
    const list = response.shared.D_GetCountryName;
    const keys = Object.keys(list);
    let pxResults = [];

    for (const key of keys) {
      if (key.startsWith("D_GetCountryName")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [name]: checked, // Toggle the checked state of the checkbox
    }));
  };

  useEffect(() => {
    setnextAssignmentId(payAgainData?.response?.nextAssignmentInfo?.ID);
    setCaseId(payAgainData?.response?.data.caseInfo?.ID.split(" ")[1]);
    setAvailableToPayCountryNamesList(
      getCountryNamesList(payAgainData?.response?.data)
    );
    let pxResults = getAccounts(payAgainData?.response?.data);
    setAccounts(pxResults);
    setAvailableToPayCurrencyList(
      payAgainData?.response?.data.shared.D_AvailabletoPayCurrencyListAPIList
        .D_AvailabletoPayCurrencyListAPIList.pxResults
    );
    setEtag(payAgainEtag);

    setAccount(payAgainData?.response?.data.caseInfo?.content?.FromAccount);
    matchCountries(
      payAgainData?.response?.data.caseInfo?.content?.ReceiversCountryName
    );
    setflagselect(
      matchCountries(
        payAgainData?.response?.data.caseInfo?.content?.ReceiversCountryName
      )
    );
    const option = {
      label: payAgainData?.response?.data.caseInfo?.content?.ReceiveCurrency,
      value: payAgainData?.response?.data.caseInfo?.content?.ReceiveCurrency,
    };
    setSelectedCurrency(option);
    setShowExchangeFields(true);
    if (payAgainData?.response?.data?.shared?.D_PurposeCodeList) {
      setPurposeCodeList(
        getPurposeCodeList(payAgainData?.response.data.shared)
      );
    } else {
      setPurposeCodeList("");
    }
    const newDetails = payAgainData?.response?.data.caseInfo?.content;
    setAccountDetails((prevDetails) => ({
      ...prevDetails,
      ...newDetails,
    }));
    setLoading(false);
  }, [navigate]);

  useEffect(() => {
    const selectedvalue = selectedCurrency?.value?.slice(0, 2);
    if (flagselect && selectedvalue) {
      setShowWarning(flagselect !== selectedvalue);
    } else {
      setShowWarning(false);
    }
  }, [flagselect, selectedCurrency]);

  function getPurposeCodeList(response) {
    const list = response.D_PurposeCodeList;
    const keys = Object.keys(list);
    let pxResults = [];
    for (const key of keys) {
      if (key.startsWith("D_PurposeCodeList")) {
        pxResults = list[key].pxResults;
        break;
      }
    }
    return pxResults;
  }

  const handlePurposeCode = (event) => {
    const purposeCode = event.target.value;
    setPurposeCode(purposeCode);
  };

  const handleAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (value === "" || !isNaN(parsedValue)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        SendAmount: value,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Please enter a valid number.",
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "Send Amount should not exceed Available balance.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }
  };

  const handleAmountInputBlur = () => {
    const amount = parseFloat(accountDetails.SendAmount);
    if (!isNaN(amount) && !error?.amountYouSendError) {
      getDataForSendAmount(
        account,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      )
        .then((response) => {
          const resData = response.data.data.caseInfo.content;
          setAccountDetails({
            ...accountDetails,
            CurrencyConvDesc: resData.CurrencyConvDesc,
            FXRateGoodForText: resData.FXRateGoodForText,
            TransactionDesc: resData.TransactionDesc,
            SendAmount: resData.SendAmount,
            ReceiveAmount: resData.ReceiveAmount,
          });
          setShowExchangeFields(true);
        })
        .catch((error) => {
          if (error.status == "401") {
            navigate("/login");
          } else {
            console.log("Promise rejected with error:", error.message);
          }
        });
    }
  };

  const handleReceiveAmountInputChange = (event) => {
    const value = event.target.value;
    const parsedValue = parseFloat(value);
    if (!isNaN(value) || value === "") {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));

      setAccountDetails((prev) => ({
        ...prev,
        ReceiveAmount: value,
      }));
    }

    if (parsedValue < 0) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError: "Amount Entered must be greater than zero.",
      }));
    } else if (parsedValue > parseFloat(accountDetails?.AccountBalance)) {
      setError((prevState) => ({
        ...prevState,
        amountEnteredError:
          "Amount Entered should not exceed Available balance.",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        amountYouSendError: "",
        amountEnteredError: "",
      }));
    }
  };

  const handleReceiveAmountInputBlur = () => {
    const amount = parseFloat(accountDetails.ReceiveAmount);
    if (!isNaN(amount) && accountDetails.ReceiveCurrency) {
      getDataForReceiverAmount(
        account,
        accountDetails.SendAmount,
        amount,
        accountDetails.ReceiveCurrency,
        accountDetails.ReceiversCountryName,
        flagselect,
        nextAssignmentId,
        eTag,
        actionName
      ).then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          SendAmount: resData.SendAmount,
        });
        setShowExchangeFields(true);
      });
    }
  };

  const handleReceiverCurrencyChange = (option) => {
    const newCurrency = option.value;
    setSelectedCurrency(option);
    setAccountDetails((prev) => ({
      ...prev,
      ReceiveCurrency: newCurrency,
    }));
    getDataForReceiverCurrency(
      account,
      accountDetails.SendAmount,
      accountDetails.ReceiveAmount,
      accountDetails.ReceiversCountryName,
      newCurrency,
      flagselect,
      nextAssignmentId,
      eTag,
      actionName
    )
      .then((response) => {
        const resData = response.data.data.caseInfo.content;
        setAccountDetails({
          ...accountDetails,
          CurrencyConvDesc: resData.CurrencyConvDesc,
          FXRateGoodForText: resData.FXRateGoodForText,
          TransactionDesc: resData.TransactionDesc,
          ReceiveAmount: resData.ReceiveAmount,
          ReceiveCurrency: resData.ReceiveCurrency,
          ReceiversCountryName: resData.ReceiversCountryName,
          SendAmount: resData.SendAmount,
        });
        //setSelectedCountryName(resData.ReceiversCountryName);
      })
      .catch((err) => {
        console.log(err.response.data.errorDetails[0].message);
        setValidateCurrency(err.response.data.errorDetails[0].message);
      });
  };

  const countriesToShow = availableToPayCountryNamesList
    .map((item) => item.Country)
    .map((country) => {
      const normalizedCountry = country.trim().toLowerCase();

      const countryObj = iso3166.all().find((item) => {
        const isoCountryName = item.country.toLowerCase();

        return isoCountryName.includes(normalizedCountry);
      });

      if (!countryObj) {
        return null;
      }

      const countryCode = countryObj.alpha2;
      return countryCode;
    })
    .filter(Boolean);

  const validateAmount = () => {
    const sendAmount = parseFloat(accountDetails?.SendAmount);
    let amountError = "";
    if (sendAmount <= 0) {
      amountError = "Send Amount must be a valid number greater than zero.";
    } else if (isNaN(sendAmount)) {
      amountError = "Value cannot be blank.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than Available balance.";
    } else if (sendAmount > accountDetails?.AccountBalance) {
      amountError = "Send Amount should not greater than available balance";
    }
    return amountError;
  };
  const validateReceiveAmount = () => {
    const receiveAmount = parseFloat(accountDetails?.ReceiveAmount);
    let amountError = "";

    if (receiveAmount === 0) {
      amountError = "Amount must be a valid number greater than zero.";
    } else if (isNaN(receiveAmount) || receiveAmount < 0) {
      amountError = "Value cannot be blank.";
      // } else if (receiveAmount > accountDetails?.AccountBalance) {
      //   amountError = "Amount Entered should not greater than available balance.";
    }

    return amountError;
  };

  const validateRemarks = () => {
    return remarks.trim() === "" ? "Value cannot be blank." : "";
  };

  const handleRemarksChange = (event) => {
    //setRemarks(event.target.value);
    if (event.target.value.length <= 140) {
      setRemarks(event.target.value);
      setError((prevState) => ({
        ...prevState,
        remarksError: "",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        remarksError: "Value should be less than 140 characters.",
      }));
    }
  };

  const validateForm = () => {
    const amountError = validateAmount();
    const amountReceiveError = validateReceiveAmount();
    const remarksError = validateRemarks();

    if (amountError || remarksError || amountReceiveError) {
      setError({
        amountYouSendError: amountError,
        remarksError: remarksError,
        amountReceiveError: amountReceiveError,
      });

      return false;
    }

    handleSubmit();
    return true;
  };

  const handleSubmit = async () => {
    try {
      const payLoad = {
        content: {
          EnterAmount: "",
          ReceiveAmount: accountDetails.ReceiveAmount,
          ReceiveCurrency: accountDetails.ReceiveCurrency,
          ReceiversCountryName: accountDetails.ReceiversCountryName,
          SendAmount: accountDetails.SendAmount,
          ConfirmAcknowledgement: checkedItems.ConfirmAcknowledgement,
          PayAllCharges: checkedItems.PayAllCharges,
          Remarks: remarks,
        },
        pageInstructions: [],
      };
      const response = await payAgainProcessSubmit(
        payLoad,
        nextAssignmentId,
        eTag,
        actionName
      );
      if (response.status === 200) {
        const res = await sendPaymentFinalSubmit(
          eTag,
          response?.data?.data?.caseInfo?.ID
        );
        if (res?.data?.data?.caseInfo) {
          navigate("/moneysent", {
            state: {
              response: res?.data?.data?.caseInfo,
            },
          });
          // setFinalResponse(res?.data?.data?.caseInfo);
          setIsFinalResponse(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const options = avaiableToPayCurrencyList.map((currency) => ({
    value: currency.CurrencyName,
    label: currency.CurrencyName,
  }));

  const formatOptionLabel = ({ value }) => (
    <div className={classes.option}>
      {value && <CurrencyFlag currency={value} />}
      <span className={classes.optionValue}>{value}</span>
    </div>
  );

  const handleCancel = () => {
    navigate("/recentpayments");
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {!isFinalResponse && (
            <>
              <Box className={classes.rootContainer}>
                <Grid size={{ md: 12, xs: 12 }}>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    color="textPrimary"
                    gutterBottom
                  >
                    {actionName === "RepeatTransaction" && "Repeat Transaction"}
                  </Typography>
                  <Divider />
                </Grid>
                <Box sx={{ padding: "5px 20px 0px 20px" }}>
                  <div className={classes.helpIconCont}>
                    <HelpIcon className={classes.helpIcon} />
                  </div>
                  <Grid container>
                    <Grid size={{ md: 5, xs: 12 }}>
                      <Typography className={classes.headerstyle}>
                        Account to be Debited
                      </Typography>
                      <Typography className={classes.contentstyle}>
                        {accounts &&
                          accounts.map((acc) => {
                            if (acc.AccountNumber.includes(account)) {
                              return acc.MaskAccountNumber;
                            }
                            return null;
                          })}
                      </Typography>
                    </Grid>
                    <Grid size={{ md: 2 }}></Grid>
                    <Grid size={{ md: 5, xs: 12 }}>
                      {account && (
                        <>
                          <Typography className={classes.headerstyle}>
                            Available Balance:
                          </Typography>
                          <Typography
                            className={`${classes.contentstyle} ${classes.flexCenterAlign}`}
                          >
                            {accountDetails.AccountBalance}
                            &nbsp;
                            <CurrencyFlag
                              currency={accountDetails?.SendCurrency}
                              className={classes.CurrencyFlag}
                            />
                            <span className={classes.Sendcurrency}>
                              {accountDetails?.SendCurrency}
                            </span>
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: "18px" }} />
                  {account ? (
                    <>
                      <Grid
                        container
                        className={classes.spacing}
                        direction={"row"}
                      >
                        <Grid size={{ md: 5, xs: 12 }}>
                          <Grid container>
                            <Grid size={{ md: 5 }}>
                              <Typography className={classes.headerstyle}>
                                Pay-to Country :
                              </Typography>
                            </Grid>
                            <Grid size={{ md: 5 }}>
                              <Typography
                                className={`${classes.contentstyle} ${classes.flexCenterAlign}`}
                              >
                                {accountDetails.ReceiversCountryName}
                                &nbsp;
                                <Flag
                                  code={getCountryCode(
                                    accountDetails.ReceiversCountryName
                                  )}
                                  className={classes.CountryFlag}
                                />
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        className={`${classes.flexCenterAlign} ${classes.spacing}`}
                      >
                        <Grid size={{ md: 5, xs: 12 }}>
                          <Typography className={classes.headerstyle}>
                            Amount You Send &nbsp;
                              <Tooltip title="Enter the amount that you want to send in the currency of your account.The beneficiary will receive the equivalent of this amount.">
                                <HelpOutlineOutlinedIcon
                                  className={classes.InfoOutlinedIcon}
                                />
                              </Tooltip>
                          </Typography>
                          
                          <Typography
                            className={`${classes.contentstyle} ${classes.flexCenterAlign}`}
                          >
                            {accountDetails.SendAmount}
                            &nbsp;
                            <CurrencyFlag
                              currency={accountDetails?.SendCurrency}
                              className={classes.CurrencyFlag}
                            />
                            <span className={classes.Sendcurrency}>
                              {accountDetails?.SendCurrency}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid size={{ md: 2 }}>
                          <SwapHorizIcon className={classes.SwaphorizIcon} />
                        </Grid>
                        <Grid size={{ md: 5, xs: 12 }}>
                          <Typography className={classes.headerstyle}>
                            Beneficiary Gets   &nbsp;
                              <Tooltip title="Enter the amount that you want beneficiary to receive.Your account will be debited for the equivalent amount, plus transaction fee.">
                                <HelpOutlineOutlinedIcon
                                  className={classes.InfoOutlinedIcon}
                                />
                              </Tooltip>
                          </Typography>
                        
                          <Typography
                            className={`${classes.contentstyle} ${classes.flexCenterAlign}`}
                          >
                            {accountDetails.ReceiveAmount}
                            &nbsp;
                            <CurrencyFlag
                              currency={accountDetails?.ReceiveCurrency}
                              className={classes.CurrencyFlag}
                            />
                            <span className={classes.Sendcurrency}>
                              {accountDetails?.ReceiveCurrency}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>

                      {loading ? (
                        <Loader />
                      ) : (
                        // flagselect &&
                        showExchangeFields && (
                          <Grid
                            container
                            spacing={2}
                            className={`${classes.spacing} ${classes.exchangefields}`}
                          >
                            <Grid size={{ md: 5 }}>
                              <Grid container>
                                <Grid size={{ md: 6 }}>
                                  <Typography
                                    variant="body2"
                                    className={classes.label}
                                  >
                                    Foreign Exchange Rate
                                  </Typography>
                                </Grid>
                                <Grid size={{ md: 6 }}>
                                  <Typography
                                    variant="body2"
                                    className={classes.exchangeFeild}
                                  >
                                    {accountDetails.CurrencyConvDesc}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid size={{ md: 2 }}></Grid>
                            <Grid size={{ md: 5 }}>
                              <Grid container>
                                <Grid size={{ md: 6 }}>
                                  <Typography
                                    variant="body2"
                                    className={classes.label}
                                  >
                                    Exchange Rate Valid For
                                  </Typography>
                                </Grid>
                                <Grid size={{ md: 6 }}>
                                  <Typography
                                    variant="body2"
                                    className={classes.exchangeFeild}
                                  >
                                    {accountDetails.FXRateGoodForText}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid size={{ md: 5 }}>
                              <Grid container>
                                <Grid size={{ md: 6 }}>
                                  <Typography
                                    variant="body2"
                                    className={classes.label}
                                  >
                                    Transaction Fee
                                  </Typography>
                                </Grid>
                                <Grid size={{ md: 6 }}>
                                  <Typography
                                    variant="body2"
                                    className={classes.exchangeFeild}
                                  >
                                    {accountDetails.TransactionDesc}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid size={{ md: 2 }}></Grid>
                            <Grid size={{ md: 5 }}>
                              <Grid container>
                                <Grid size={{ md: 6 }}>
                                  <Typography
                                    variant="body2"
                                    className={classes.label}
                                  >
                                    Estimated Delivery Time
                                  </Typography>
                                </Grid>
                                <Grid size={{ md: 6 }}>
                                  <Typography
                                    variant="body2"
                                    className={classes.exchangeFeild}
                                  >
                                    {accountDetails?.EstimatedDeliveryTime}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      )}

                      <Grid container spacing={1}>
                        {purposeCodeList &&
                          accountDetails?.PurposeofPaymentVisibility && (
                            <>
                              <Grid
                                size={{ xs: 12, md: 5 }}
                                className={classes.purpose}
                              >
                                <Typography
                                  variant="body2"
                                  className={classes.headerstyle}
                                >
                                  Purpose of Payment
                                </Typography>

                                <TextField
                                  id="PurposeCode"
                                  variant="outlined"
                                  value={purposeCode}
                                  size="small"
                                  onChange={handlePurposeCode}
                                  slotProps={{
                                    input: {
                                      className: `${classes.purposeCodeDropdown} ${classes.remarks}`,
                                    },
                                  }}
                                  //label="Select Pay Purpose"
                                  select
                                  fullWidth
                                >
                                  {purposeCodeList &&
                                    purposeCodeList.map((pCode) => (
                                      <MenuItem
                                        key={pCode.PayPurPCodeDesc}
                                        value={pCode.PayPurPCodeDesc}
                                      >
                                        {pCode.PayPurPCode}
                                      </MenuItem>
                                    ))}
                                </TextField>
                                {error.purposeCodeError && (
                                  <div className="error-text">
                                    {error.purposeCodeError}
                                  </div>
                                )}
                              </Grid>
                              <Grid siz={{ md: 2 }}></Grid>
                            </>
                          )}
                        {/* {accountDetails?.PaymentPurposeCode_RVW && (
                          <>
                            <Grid
                              size={{ xs: 12, md: 5 }}
                              className={classes.purpose}
                            >
                              <Typography
                                variant="body2"
                                className={classes.headerstyle}
                              >
                                Purpose of Payment
                              </Typography>
                              <Typography className={classes.contentstyle}>
                                {accountDetails?.PaymentPurposeCode_RVW}
                              </Typography>
                            </Grid>
                            <Grid siz={{ md: 2 }}></Grid>
                          </>
                        )} */}
                        {/* {flagselect && ( */}
                        <Grid
                          size={{ md: 5, xs: 12 }}
                          className={classes.purpose}
                        >
                          <Typography
                            variant="body2"
                            className={classes.headerstyle}
                          >
                            Remarks
                          </Typography>
                          <TextField
                            id="remarks"
                            //label="Remarks"
                            size="small"
                            multiline
                            rows={1}
                            value={remarks}
                            onChange={handleRemarksChange}
                            fullWidth
                            slotProps={{
                              input: {
                                maxLength: 140,
                                className: classes.borderstyle,
                              },
                            }}
                            className={classes.remarks}
                          />
                          {error.remarksError && (
                            <div className="error-text">
                              {error.remarksError}
                            </div>
                          )}
                        </Grid>
                        {/* )} */}
                      </Grid>

                      <Divider sx={{ marginTop: "24px" }} />

                      <CountrySpecificComponent
                        editableBeneDetails={editableBeneDetails}
                        payAgainOrRepeatTransaction={true}
                        flag={true}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  {checkbox_Details.map((data, index) => {
                    if (data?.type === "RadioButtons") {
                      return (
                        <Grid
                          container
                          className={classes.spacing}
                          spacing={0.5}
                        >
                          <Grid size={{ md: 12 }} display={"flex"}>
                            <span>
                              <img src={warningIcon} width={20} height={20} />
                            </span>
                            &nbsp;&nbsp;
                            <Typography className={classes.message}>
                              {data.config.label?.replace(/@L\s*/, "")}
                            </Typography>
                          </Grid>
                          <Grid size={{ md: 12 }} ml={5} pr={30}>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                row
                                style={{ display: "flex" }}
                              >
                                <FormControlLabel
                                  value="Yes"
                                  control={<Radio size="small" />}
                                  label={
                                    <Typography
                                      className={classes.contentstyle}
                                    >
                                      Yes
                                    </Typography>
                                  }
                                />
                                <FormControlLabel
                                  value="No"
                                  control={<Radio size="small" />}
                                  label={
                                    <Typography
                                      className={classes.contentstyle}
                                    >
                                      No
                                    </Typography>
                                  }
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      );
                    }
                    if (data?.type === "Checkbox") {
                      const checkboxName = data?.config?.value.replace(
                        /^@P \./,
                        ""
                      );
                      return (
                        <Box key={data.id} className={classes.spacing}>
                          <Grid className={classes.flex}>
                            <Checkbox
                              name={checkboxName}
                              className={classes.checkboxStyles}
                              checked={!!checkedItems[checkboxName]}
                              onChange={handleCheckbox}
                              style={{ color: "#52459f " }}
                            />
                            <Typography
                              variant="body2"
                              className={classes.textFont}
                            >
                              {data.config.caption.replace(/@L\s*/, "")}
                            </Typography>
                          </Grid>
                        </Box>
                      );
                    }
                    return null;
                  })}

                  <Grid container spacing={2} className={classes.spacingCont}>
                    <Grid
                      size={{ xs: 12, md: 12 }}
                      className={classes.flexBetween}
                    >
                      <Button className={classes.cancel} onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.continue}
                        disabled={
                          !checkedItems.ConfirmAcknowledgement &&
                          !checkedItems.PayAllCharges
                        }
                        onClick={validateForm}
                      >
                        Review & Pay
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          )}
          {/* {isFinalResponse && <FinalResponse finalResponse={finalResponse} />} */}
        </>
      )}
    </>
  );
};

export default RepeatTransaction;
