import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import axios from "axios";
import endPoints from "../../../config/Api";
import {
  refreshAccessToken,
  getAccessToken,
  isTokenExpired,
} from "../../Authorization/Services/authService";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModalPopup from "./Dialog";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetInitialState } from "../../../Redux/Reducers/addNewBeneficiaryReducer";
import {
  Avatar,
  Card,
  CardContent,
  TablePagination,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Loader from "../Loader";

const AddModifyBeneficiary = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [newFormFlag, setNewFormFlag] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      let token = getAccessToken();
      if (isTokenExpired(token)) {
        token = await refreshAccessToken(); // Refresh the token
      }
      const response = await axios.post(
        `${endPoints.baseUrl}/data_views/${endPoints.addModifyBeneficiary.D_Page_Name}`,
        {
          dataViewParameters: {},
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response?.data?.data);
      setSearchData(response?.data?.data);
    } catch (err) {
      console.error("Error fetching data:", err);
      localStorage.clear();
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(resetInitialState());
    fetchData();
  }, []);

  const searchHandler = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue);
    if (searchValue === "") {
      fetchData();
    }
    const SearchedData = searchData.filter(
      (row) =>
        row.FullName &&
        row.FullName?.toLowerCase().includes(event.target.value?.toLowerCase())
    );
    setData(SearchedData);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const colors = [
    "#0b8953",
    "#c23824",
    "#2c97de",
    "#00657c",
    "#00aa66",
    "#667700",
    "#e27100",
    "#52459f",
  ];

  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.trim().split(/\s+/);

    return nameArray.length > 1
      ? `${nameArray[0][0]}${nameArray[1][0]}`.toUpperCase()
      : name.substring(0, 2).toUpperCase();
  };

  const getColor = (initials) => {
    if (!initials) return colors[0]; // Default color

    const firstChar = initials[0].toUpperCase();

    if (firstChar >= "A" && firstChar <= "Z") {
      const charCode = firstChar.charCodeAt(0) - "A".charCodeAt(0);
      return colors[Math.floor(charCode / 3) % colors.length];
    } else if (firstChar >= "0" && firstChar <= "9") {
      const num = parseInt(firstChar, 10);
      return colors[Math.floor(num / 3) % colors.length];
    }

    return colors[0]; // Default color for any other characters
  };

  const _handleClick = (data) => {
    setRowData(data);
    setNewFormFlag(false);
    setOpen(!open);
  };
  const handleNewBeneficiary = () => {
    setNewFormFlag(true);
    setOpen(!open);
  };

  const _handleClose = () => {
    fetchData();
    dispatch(resetInitialState());
    setOpen(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid size={{ md: 12, xs: 12 }}>
              <Typography
                className={classes.title}
                variant="h6"
                color="textPrimary"
                gutterBottom
              >
                Add/Modify Beneficiary
              </Typography>
            </Grid>
            <Grid size={{ md: 12, xs: 12 }}>
              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                className={classes.search}
              >
                <Grid size={{ md: 12, xs: 12 }} className={classes.searchbar}>
                  <input
                    placeholder="Search for beneficiary by name"
                    className={classes.SearchInput}
                    onChange={searchHandler}
                    value={searchText}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid size={{ md: 12, xs: 12 }}>
              <div className={classes.addBeneficiary}>
                <Typography variant="h6" className={classes.addtxt}>
                  Add New Beneficiary
                </Typography>
                <AddCircleIcon
                  fontSize="small"
                  className={classes.circleIcon}
                  onClick={handleNewBeneficiary}
                />
              </div>
            </Grid>
            <Grid size={{ md: 12, xs: 12 }} className={classes.headergrid}>
              <Typography
                variant="body1"
                className={classes.beneficiaryheading}
              >
                Available Beneficiaries
              </Typography>
            </Grid>
            <Grid size={{ md: 12, xs: 12 }}>
              <Grid container spacing={2}>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const initials = getInitials(row.FullName);
                    const backgroundColor = getColor(initials);
                    return (
                      <>
                        <Grid size={{ md: 6, xs: 6 }} key={index}>
                          <Card
                            key={row.pxRefObjectInsName || row.pyID}
                            className={classes.accountCard}
                          >
                            <CardContent className={classes.accountContent}>
                              <div className={classes.cardContent}>
                                <Avatar
                                  style={{ backgroundColor }}
                                  className={classes.avatar}
                                >
                                  {initials}
                                </Avatar>
                                <div className={classes.infoContainer}>
                                  <Typography
                                    variant="body1"
                                    className={classes.FullName}
                                  >
                                    {row.FullName}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.Accountnumber}
                                  >
                                    A/C No:{" "}
                                    {row.MaskAccNumb
                                      ? row.MaskAccNumb
                                      : row.MaskIBANNumb}
                                  </Typography>
                                </div>
                              </div>
                              <div className={classes.iconContainer}>
                                <LaunchOutlinedIcon
                                  fontSize="medium"
                                  className={classes.LaunchOutlinedIcon}
                                  onClick={() => {
                                    _handleClick(row);
                                  }}
                                />
                                <DeleteOutlineOutlinedIcon
                                  fontSize="medium"
                                  className={classes.DeleteIcon}
                                />
                              </div>
                            </CardContent>
                          </Card>
                        </Grid>
                      </>
                    );
                  })}
              </Grid>

              {data && data.length > 0 && (
                <TablePagination
                  id="pagination"
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Grid>
          </Grid>
          <ModalPopup
            open={open}
            close={_handleClose}
            rowData={rowData}
            newFormFlag={newFormFlag}
          />
        </>
      )}
    </>
  );
};

export default AddModifyBeneficiary;
