import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";

const BeneficiaryNameField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    beneDetails,
    isEditable,
    flag,
    handleChange,
    handleBlurChange,
    checkErrors,
    disableFlag,
  } = props;

  return (
    <>
      <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Beneficiary Name
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            value={beneDetails?.FullName}
            onChange={handleChange}
            onBlur={handleBlurChange}
            error={Boolean(checkErrors("FullName"))}
            helperText={checkErrors("FullName")}
            name="FullName"
            disabled={disableFlag}
          />
        ) : beneDetails?.FullName ? (
          <span className={classes.contentstyle}>{beneDetails?.FullName}</span>
        ) : (
          "---"
        )}
        {flag && beneDetails?.IsValidBeneNameURL && (
          <img
            src={beneDetails?.IsValidBeneNameURL}
            className={classes.checkMark}
            alt="checkmark"
          />
        )}
      </Grid>
    </>
  );
};

export default BeneficiaryNameField;
