import React from "react";
import CurrencyFlag from "react-currency-flags";
import flagsCode from "../CommonService/flagServies";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { validateEditBeneficiaryFields } from "../CommonService/commonAppService";
import { TextareaAutosize } from "@mui/material";
import { useSelector } from "react-redux";
import BeneficiaryAddress from "./CommonFields/BeneficiaryAddress";
import BeneficiaryAddressReadOnly from "./CommonFields/BeneficiaryAddressReadOnly";
import MobileStyles from "./MobileStyles";
import WebStyles from "./WebStyles";
import NickNameField from "./CommonFields/NickNameField";
import Flag from "react-world-flags";
import AddressMessage from "./CommonFields/AddressMessage";
import { getCountryCode } from "../CommonUtils/CommonUtils";
import BeneficiaryNameField from "./CommonFields/BeneficiaryNameField";

const Japan = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const newBeneData = useSelector((data) => data?.addNewBeneficiary);
  const errors = newBeneData?.error;

  const checkErrors = (value) => {
    if (!errors?.message?.data?.errorDetails) return null;

    const errorDetail = errors.message.data.errorDetails.find((error) =>
      error?.erroneousInputOutputIdentifier.includes(value)
    );

    return errorDetail?.message || null;
  };
  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    nextAssignmentId,
    etag,
    // below is for  add/new beneficiary
    addNewBene,
    newBeneCountryName,
    handleChange,
    formFlag,
    formState,
    setFormState,
    handleRefreshInputBlur,
    handleValidate,
  } = props;

  const handleEditChange = async (event) => {
    const { name, value } = event.target;
    setEditableBeneDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
      Edit: isEditable,
    }));

    const refereshFor = ".SelectedBeneDetails.BICFI";
    try {
      const payLoad = {
        content: {
          SelectedBeneDetails: {
            [name]: value,
            Edit: isEditable,
          },
        },
        pageInstructions: [],
      };
      const response = await validateEditBeneficiaryFields(
        nextAssignmentId,
        payLoad,
        etag,
        refereshFor
      );
      if (name === "BICFI") {
        setEditableBeneDetails((prevDetails) => ({
          ...prevDetails,
          BICFI:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails?.BICFI,
          BeneBankAddress:
            response?.data?.data?.caseInfo?.content?.SelectedBeneDetails
              ?.BeneBankAddress,
          Edit: isEditable,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {review && !addNewBene && (
        <>
          <Grid container size={{ xs: 12, md: 12 }}>
            <BeneficiaryNameField
              beneDetails={reviewDetails?.SelectedBeneDetails}
              isEditable={false}
            />
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Account number
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {reviewDetails?.SelectedBeneDetails?.MaskAccNumb
                  ? reviewDetails?.SelectedBeneDetails?.MaskAccNumb
                  : "---"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Account Type
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {reviewDetails?.SelectedBeneDetails?.CdtrAcctTpPrtry
                  ? reviewDetails?.SelectedBeneDetails?.CdtrAcctTpPrtry
                  : "---"}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Bank BIC Code
              </Typography>
              <Typography className={classes.textFont}>
                {reviewDetails?.SelectedBeneDetails?.BICFI
                  ? reviewDetails?.SelectedBeneDetails?.BICFI
                  : "---"}
              </Typography>
            </Grid>
            <BeneficiaryAddressReadOnly reviewDetails={reviewDetails} />
            <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
              <Typography variant="body2" className={classes.headerstyle}>
                Beneficiary Bank Address
              </Typography>
              <Typography variant="body2" className={classes.textFont}>
                {reviewDetails?.SelectedBeneDetails?.BeneBankName &&
                  reviewDetails?.SelectedBeneDetails?.BeneBankName}
              </Typography>
              <TextareaAutosize
                variant="outlined"
                fullWidth
                size="small"
                value={editableBeneDetails?.BeneBankAddress || "--"}
                readOnly
                className={classes.textarea}
              />
            </Grid>
          </Grid>
        </>
      )}
      {addNewBene ? (
        <>
          {newBeneData?.formFlag && (
            <>
              <Grid container spacing={1}>
                <Box sx={{ minWidth: 120 }}>
                  <Typography
                    pt={2}
                    style={{ color: "#52459f" }}
                    className={classes.headerstyle}
                  >
                    Please enter Beneficiary details:
                  </Typography>
                </Box>
                <NickNameField
                  handleChange={handleChange}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />
                <BeneficiaryNameField
                  beneDetails={newBeneData?.formState}
                  handleChange={handleChange}
                  isEditable={true}
                  checkErrors={checkErrors}
                  flag={true}
                  disableFlag={newBeneData?.submitFlag}
                />
                <Grid size={{ md: 6, xs: 12 }} className={classes.spacing}>
                  <Box>
                    <FormControl>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Account Number
                      </Typography>

                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="CdtrAcctId"
                        error={Boolean(checkErrors("CdtrAcctId"))}
                        helperText={checkErrors("CdtrAcctId")}
                        disabled={newBeneData?.submitFlag}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Box>
                  {newBeneData?.formState?.IsValidAccountTypeURL && (
                    <Box sx={{ pt: 3 }}>
                      <img
                        src={newBeneData?.formState?.IsValidAccountTypeURL}
                        className={classes.checkMark}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid
                  size={{ md: 6, xs: 12 }}
                  className={`${classes.spacing} ${classes.flagAlignments}`}
                >
                  <Box>
                    <FormControl>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Beneficiary Account Type
                      </Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="CdtrAcctTpPrtry"
                        error={Boolean(checkErrors("CdtrAcctTpPrtry"))}
                        helperText={checkErrors("CdtrAcctTpPrtry")}
                        disabled={newBeneData?.submitFlag}
                        onChange={handleChange}
                        size="small"
                        fullWidth
                      >
                        <MenuItem value={"Savings/General"}>
                          Savings/General
                        </MenuItem>
                        <MenuItem value={"Savings/Deposit"}>
                          Savings/Deposit
                        </MenuItem>
                        <MenuItem value={"Checking/Current"}>
                          Checking/Current
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {newBeneData?.formState?.IsValidAccountTypeURL && (
                    <Box sx={{ pt: 3 }}>
                      <img
                        src={newBeneData?.formState?.IsValidAccountTypeURL}
                        className={classes.checkMark}
                      />
                    </Box>
                  )}
                </Grid>
                <BeneficiaryAddress
                  beneDetails={newBeneData?.formState}
                  Country={newBeneData?.countryName}
                  handleChange={handleChange}
                  checkErrors={checkErrors}
                  disableFlag={newBeneData?.submitFlag}
                  isEditable={true}
                />
                <Grid
                  size={{ md: 6, xs: 12 }}
                  className={`${classes.spacing} ${classes.flagAlignments}`}
                >
                  <Box>
                    <FormControl>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Beneficiary Bank BIC Code
                      </Typography>

                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="BICFI"
                        error={Boolean(checkErrors("BICFI"))}
                        helperText={checkErrors("BICFI")}
                        disabled={newBeneData?.submitFlag}
                        onChange={handleChange}
                        onBlur={handleRefreshInputBlur}
                      />
                    </FormControl>
                  </Box>
                  {newBeneData?.formState?.IsValidBeneBICCodeURL && (
                    <Box sx={{ pt: 3 }}>
                      <img
                        src={newBeneData?.formState?.IsValidBeneBICCodeURL}
                        className={classes.checkMark}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid
                  size={{ md: 6, xs: 12 }}
                  className={`${classes.spacing} ${classes.flagAlignments}`}
                >
                  <Box>
                    <FormControl>
                      <Typography
                        variant="body2"
                        className={classes.headerstyle}
                      >
                        Beneficiary Bank Name
                      </Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="BeneBankName"
                        disabled
                        value={
                          newBeneData?.formState?.BeneBankName
                            ? newBeneData?.formState?.BeneBankName
                            : "---"
                        }
                        className={classes.textFieldReadOnly}
                      />
                    </FormControl>
                  </Box>
                </Grid>
                {newBeneData?.formState?.BeneBankAddress && (
                  <Grid
                    size={{ md: 6, xs: 12 }}
                    className={`${classes.spacing} ${classes.flagAlignments}`}
                  >
                    <Box>
                      <FormControl>
                        <Typography
                          variant="body2"
                          className={classes.headerstyle}
                        >
                          Beneficiary Bank Address
                        </Typography>

                        <TextareaAutosize
                          variant="outlined"
                          fullWidth
                          size="small"
                          value={
                            newBeneData?.formState?.BeneBankAddress || "---"
                          }
                          readOnly
                          className={classes.textarea}
                        />
                      </FormControl>
                    </Box>
                    {newBeneData?.formState?.IsValidBeneAddressURL && (
                      <Box sx={{ pt: 3 }}>
                        <img
                          src={newBeneData?.formState?.IsValidBeneAddressURL}
                          className={classes.checkMark}
                        />
                      </Box>
                    )}
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </>
      ) : (
        (payAgainOrRepeatTransaction || !review) && (
          <>
            {!isMobile && !payAgainOrRepeatTransaction && (
              <Grid container className={classes.spacing}>
                <Grid size={{ xs: 6 }} className={classes.flexCenterAlign}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    <b>Pay to Country :</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    className={`${classes.flexCenterAlign} ${classes.Gap}`}
                  >
                    {editableBeneDetails?.Country} &nbsp;
                    <Flag
                      code={getCountryCode(editableBeneDetails?.Country)}
                      className={classes.countryFlag}
                    />
                  </Typography>
                </Grid>

                <Grid size={{ xs: 6 }}></Grid>
              </Grid>
            )}
            <NickNameField
              beneDetails={editableBeneDetails}
              isEditable={isEditable}
              handleChange={handleInputChange}
              handleBlurChange={handleEditChange}
              payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
            />
            <Grid container size={{ xs: 12, md: 12 }}>
              <BeneficiaryNameField
                beneDetails={editableBeneDetails}
                handleChange={handleInputChange}
                handleBlurChange={handleEditChange}
                isEditable={isEditable}
                flag={flag}
                checkErrors={checkErrors}
              />
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Account Number
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.CdtrAcctId}
                    onChange={handleInputChange}
                    onBlur={handleEditChange}
                    name="CdtrAcctId"
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.CdtrAcctId
                      ? editableBeneDetails?.CdtrAcctId
                      : "---"}
                  </span>
                )}

                {flag && editableBeneDetails?.IsValidBeneIBANURL && (
                  <img
                    src={editableBeneDetails?.IsValidBeneIBANURL}
                    alt="checkmark"
                    className={classes.checkMark}
                  />
                )}
              </Grid>
              {!payAgainOrRepeatTransaction && (
                <BeneficiaryAddress
                  beneDetails={editableBeneDetails}
                  handleChange={handleInputChange}
                  handleBlurChange={handleEditChange}
                  isEditable={isEditable}
                  checkErrors={checkErrors}
                />
              )}
              {payAgainOrRepeatTransaction && (
                <BeneficiaryAddressReadOnly
                  reviewDetails={editableBeneDetails}
                />
              )}
              
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Account Type
                </Typography>
                {isEditable ? (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.actDropDown}
                    size="small"
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="CdtrAcctTpPrtry"
                      onChange={handleChange}
                    >
                      <MenuItem value={"Savings/General"}>
                        Savings/General
                      </MenuItem>
                      <MenuItem value={"Savings/Deposit"}>
                        Savings/Deposit
                      </MenuItem>
                      <MenuItem value={"Checking/Current"}>
                        Checking/Current
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.CdtrAcctTpPrtry
                      ? editableBeneDetails?.CdtrAcctTpPrtry
                      : "---"}
                  </span>
                )}
                {flag && editableBeneDetails?.IsValidAccountTypeURL && (
                  <img
                    src={editableBeneDetails?.IsValidAccountTypeURL}
                    alt="checkmark"
                    className={classes.checkMark}
                  />
                )}
              </Grid>
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank BIC Code
                </Typography>
                {isEditable ? (
                  <TextField
                    variant="outlined"
                    size="small"
                    value={editableBeneDetails?.BICFI}
                    onChange={handleInputChange}
                    onBlur={handleEditChange}
                    name="BICFI"
                  />
                ) : (
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.BICFI
                      ? editableBeneDetails?.BICFI
                      : "---"}
                  </span>
                )}

                {flag && editableBeneDetails?.IsValidBeneBICCodeURL && (
                  <img
                    src={editableBeneDetails?.IsValidBeneBICCodeURL}
                    alt="checkmark"
                    className={classes.checkMark}
                  />
                )}
              </Grid>
              {!payAgainOrRepeatTransaction && (
                <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                  <Typography variant="body2" className={classes.headerstyle}>
                    Beneficiary Bank Name
                  </Typography>
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.BeneBankName
                      ? editableBeneDetails?.BeneBankName
                      : "---"}
                  </span>
                  {!isEditable && flag && (
                    <img
                      src={editableBeneDetails?.IsValidBeneAddressURL}
                      alt="checkmark"
                      className={classes.checkMark}
                    />
                  )}
                </Grid>
              )}
              <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
                <Typography variant="body2" className={classes.headerstyle}>
                  Beneficiary Bank Address
                </Typography>
                <TextareaAutosize
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={editableBeneDetails?.BeneBankAddress || "--"}
                  readOnly
                  className={classes.textarea}
                />
                {!isEditable &&
                  flag &&
                  editableBeneDetails?.IsValidBeneAddressURL && (
                    <img
                      src={editableBeneDetails?.IsValidBeneAddressURL}
                      alt="checkmark"
                      className={classes.checkMark}
                    />
                  )}
              </Grid>
            </Grid>
            <AddressMessage
              editableBeneDetails={editableBeneDetails}
              setEditableBeneDetails={setEditableBeneDetails}
            />

            {!isMobile && editableBeneDetails?.message && (
              <Box className={classes.spacing} sty>
                <Typography variant="body2" className={classes.headerstyle}>
                  Validation message
                </Typography>
                <Typography className={classes.textFont}>
                  <span className={classes.contentstyle}>
                    {editableBeneDetails?.message}
                  </span>
                </Typography>
              </Box>
            )}
          </>
        )
      )}
    </>
  );
};
export default Japan;
