import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";

const AccountNumberFeild = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const newBeneData = useSelector((data) => data?.addNewBeneficiary);
  const classes = isMobile ? MobileStyles() : WebStyles();
  const {
    handleChange,
    checkErrors,
    editableBeneDetails,
    isEditable,
    flag,
    handleEditChange,
    handleInputChange,
  } = props;
  return (
    <>
      <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
        <Typography variant="body2" className={classes.headerstyle}>
          Account Number
        </Typography>
        {isEditable ? (
          <TextField
            variant="outlined"
            size="small"
            value={editableBeneDetails?.CdtrAcctId}
            onChange={handleInputChange}
            onBlur={handleEditChange}
            name="CdtrAcctId"
          />
        ) : (
          <span className={classes.contentstyle}>
            {editableBeneDetails?.CdtrAcctId
              ? editableBeneDetails?.CdtrAcctId
              : "---"}
            &nbsp;
          </span>
        )}
        {flag && editableBeneDetails?.IsValidBeneIBANURL && (
          <img
            src={editableBeneDetails?.IsValidBeneIBANURL}
            className={classes.checkMark}
            alt="checkmark"
          />
        )}
      </Grid>
    </>
  );
};

export default AccountNumberFeild;
