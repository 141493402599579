import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { editBeneficiary } from "../../components/CommonService/commonAppService";
import { validateBeneficiaryRefresh ,enterBeneficiaryDetailsFinalSubmit} from "../../components/CommonService/commonAppService";
export const fetchEditBeneficiary = createAsyncThunk(
  "beneficiary/fetchEditBeneficiary",
  async ({ pyGUID }) => {
    const res = await editBeneficiary(pyGUID);
    return {
      etag: res.headers["etag"],
      data: res.data,
    };
  }
);

export const validateEditBeneficiary = createAsyncThunk(
  'beneficiary/validateEditBeneficiary',
  async ({ assignmentID, formState, etag, refreshFor }) => {
    const res = await validateBeneficiaryRefresh(assignmentID, formState, etag, refreshFor);
    return res?.data?.data?.caseInfo?.content?.NewBeneDetails;
  }
);
export const editSubmitBeneficiaryDetails = createAsyncThunk(
  'beneficiary/submitBeneficiaryDetails',
  async ({ assignmentID, formState, etag }) => {
    const res = await enterBeneficiaryDetailsFinalSubmit(assignmentID, formState, etag);
    return res.data;
  }
);
const editBeneficiarySlice = createSlice({
  name: "editBeneficiary",
  initialState: {
    assignmentID:null,
    etag: null,
    responseData: null,
    NewBeneDetails: null,
    submitFlag:false
  },
  reducers: {
    setBICFI: (state, action) => {
      state.selectedBeneDetails.BICFI = action.payload;
    },
    setABARoutingNumber: (state, action) => {
      state.selectedBeneDetails.ClrSysMmbId = action.payload;
    },
    setFullName: (state, action) => {
      state.selectedBeneDetails.FullName = action.payload;
    },
    setAccountNumber: (state, action) => {
      state.selectedBeneDetails.CdtrAcctId = action.payload;
    },
    setNickname: (state, action) => {
      state.selectedBeneDetails.NickName = action.payload;
    },
    setEditSubmitFlag:(state,action)=>{
      state.submitFlag=action.payload;
    },
    setEditableState: (state, action) => {
      state.isEditable = action.payload;
    },
    setNewBeneDetails: (state, action) => {
      state.NewBeneDetails = { ...state.NewBeneDetails, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEditBeneficiary.fulfilled, (state, action) => {
      console.log(action);
      state.responseData = action.payload?.data;
      state.etag = action.payload?.etag;
      state.NewBeneDetails =
        action.payload?.data?.data?.caseInfo?.content?.NewBeneDetails;
      state.assignmentID =
        action.payload?.data?.nextAssignmentInfo?.ID;
    }).addCase(validateEditBeneficiary.fulfilled,(state,action)=>{
      state.NewBeneDetails = { ...state.NewBeneDetails, ...action.payload };
    })
    .addCase(editSubmitBeneficiaryDetails.fulfilled,(state,action)=>{
      state.NewBeneDetails = { ...state.NewBeneDetails, ...action.payload?.data?.data?.caseInfo?.content?.NewBeneDetails };
    })
  },
});

export const {
  setBICFI,
  setABARoutingNumber,
  setFullName,
  setAccountNumber,
  setNickname,
  setEditableState,
  setValidationMessages,
  setNewBeneDetails,setEditSubmitFlag
} = editBeneficiarySlice.actions;

export default editBeneficiarySlice.reducer;
