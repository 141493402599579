const EuroCountries = [
    "DE",
    "FR",
    "ES",
    "IT",
    "NL",
    "BE",
    "AT",
    "FI",
    "PT",
    "IE",
    "GR",
    "CY",
    "LU",
    "MT",
    "SK",
    "SI",
    "EE",
    "LT",
    "LV",
  ]

  export default EuroCountries