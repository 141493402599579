import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import beneficiaryReducer from "../Reducers/addNewBeneficiaryReducer";
import editBeneficiaryReducer from "../Reducers/editBeneficiaryReducer";
import sendPaymentReducer from "../Reducers/SendPaymentReducer";
import mobileReducer from "../Reducers/mobileReducer";

const store = configureStore({
  reducer: {
    addNewBeneficiary: beneficiaryReducer,
    editBeneficiary: editBeneficiaryReducer,
    sendPaymentReducer: sendPaymentReducer,
    mobileReducer: mobileReducer,
  },
});

export default store;
