import { makeStyles } from "@mui/styles";
// import { fontGrid } from "@mui/material/styles/cssUtils";

const WebStyles = makeStyles((theme) => ({
  // Amount Screen Stylings Start -- gopi

  selcectfeild: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
  },
  inputLable: {
    margin: "11px 0 0px 0px !important ",
    fontSize: "14px !important",
    color: "#222222 !important",
  },
  Amountcolor: {
    color: "#52459f",
  },
  Availablebal: {
    fontSize: "14px !important ",
    fontWeight: "600 !important ",
  },
  CurrencyFlag: {
    borderRadius: "50% !important ",
    width: "20px !important ",
    height: "20px !important ",
  },
  Sendcurrency: {
    marginLeft: "5px",
  },
  Fontweight: {
    fontWeight: "600 !important ",
  },
  Infoicon: {
    color: "#ba8e23 !important ",
    fontSize: "1.5rem",
    marginTop: "11px",
  },
  Infotext: {
    lineHeight: "1.5em !important ",
    marginLeft: "7px !important ",
    fontSize: "11px !important",
  },
  info: {
    lineHeight: "1.5em !important ",
    fontSize: "12px !important",
    margin: "8px 0px !important",
  },
  actDropDown: {
    width: "45% !important ",
  },
  textstyle: {
    fontSize: "14px !important ",
    fontWeight: "600 !important ",
  },
  borderstyle: {
    borderRadius: "8px !important ",
  },
  Swaphorizicon: {
    marginTop: "32px !important",
    fill: "#52459f !important",
    width: "2em !important",
    height: "2em !important ",
  },
  exchangefields: {
    background: "#edecf5",
    marginLeft: "0px",
    padding: "8px",
  },
  flexAround: {
    display: "flex",
    justifyContent: "space-around",
  },
  purpose: {
    marginTop: "10px !important ",
  },
  mandatory: {
    color: "red ",
  },
  option: {
    display: "flex !important ",
    alignItems: "center !important ",
  },
  optionValue: {
    marginLeft: "10px !important ",
  },
  exchangeFeild: {
    fontSize: "14px !important ",
    fontWeight: "600 !important",
  },
  exchangeGrid: {
    display: "flex",
    justifyContent: "space-between",
    gap: "25vw",
    width: "100%",
  },
  //Amount Screen Stlylings end -- gopi

  rootContainer: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    boxShadow: theme.shadows[3],
    borderRadius: "30px",
    overflow: "hidden",
  },
  flex: {
    display: "flex",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexAlignCenter: {
    display: "flex",
    alignItems: "flex-start",
  },
  flexCenterAlign: {
    display: "flex",
    alignItems: "center",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  title: {
    fontSize: "1.25rem !important",
    fontWeight: "bold !important",
    color: "#7270a0 !important",
  },
  tabHeading: {
    textTransform: "none !important",
    fontSize: "14px !important",
    "&.Mui-selected": {
      color: "#52459f !important",
    },
  },
  heading: {
    color: "#52459f !important",
    fontWeight: "bold !important",
  },
  formControl: {
    width: "50% !important",
    marginTop: "20px !important",
  },
  inputLabel: {
    fontSize: "14px !important",
    color: "#222222 !important",
  },
  acctInput: {
    padding: "10px !important",
    border: "1px solid #d5d5d5 !important",
    borderRadius: "15px !important",
    fontSize: "14px !important",
  },
  placeholder: {
    background: "none",
    color: "grey",
  },
  sendAmountTextField: {
    //margin: "10px 0px !important",
    borderRadius: "15px !important",
    //paddingTop: '4px !important',
  },
  itemWidth: {
    width: "27vw !important",
  },
  spacingCont: {
    margin: "10px 0px !important",
  },
  text: {
    borderBottom: "none !important",
  },
  editIcon: {
    fill: "#fff !important",
    background: "#52439E !important",
    borderRadius: "7px",
    padding: "3px",
    cursor: "pointer",
    height: "15px",
    width: " 18px",
  },
  textarea: {
    outline: "none !important",
    border: "none !important",
    resize: "none !important",
    padding: "0px !important",
    margin: "0px !important",
    fontFamily: '"Open Sans", sans-serif !important',
  },
  textFieldReadOnly: {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
  },
  helpIcon: {
    marginLeft: "10px",
    color: "#9e9e9e !important",
    position: "relative",
    opacity: 0.5,
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      borderRadius: "50%",
      zIndex: -1,
    },
  },
  beniget: {
    border: "1px solid #d5d5d5",
    height: "7.7vh",
    borderRadius: "10px",
    width: "20vw",
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "0%",
    },
  },
  purposeDropdown: {
    // width: "50%",
    borderRadius: "15px !important",
    marginTop: "10px",
  },
  purposeCodeDropdown: {
    width: "60% !important",
    borderRadius: "15px !important",
  },
  sent: {
    // border: "1px solid #d5d5d5 !important",
    marginTop: "10px",
    width: "18vw !important",
    "& button": {
      borderRadius: "10px !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px !important",
    },
    "& input": {
      border: "none !important",
      // borderBottom: '1px solid #000 !important',
    },
    "& ul": {
      position: "absolute",
      overflowY: "auto",

      // WebKit scrollbar styling
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#52459f",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
    "& .MuiSelect-select": {
      padding: "8px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "0%",
    },
  },
  con: {
    height: "8vh",
    border: "1px solid #d5d5d5",
    borderRadius: "10px",
    "& .MuiSelect-select": {
      marginLeft: "13px",
    },
  },
  currencyDropdown: {
    width: "100%",
  },
  currencyCodeSelect: {
    "& .custom__control": {
      width: "180px",
      borderRadius: "8px",
      border: "none",
      boxShadow: "none",
      "&:hover": {
        border: "none",
      },
    },
    "& .custom__menu-list": {
      height: "160px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#52459f",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
  },
  spacing: {
    marginTop: "17px !important ",
    borderRadius: "15px",
  },
  space: {
    marginTop: "6px !important",
  },
  inputBase: {
    height: "40px",
    padding: "15px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "0px",
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  label: {
    marginBottom: "0",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  cancel: {
    border: "1px solid #BC64C7 !important",
    borderRadius: "40px !important",
    background: "#ffff !important",
    color: "#51459E !important",
    padding: "7px 13px !important",
    textTransform: "none !important",
    marginTop: "20px !important",
    "&:hover": {
      background: "#ededed !important",
    },
  },
  continue: {
    color: "#ffffff !important",
    padding: "7px 13px !important",
    background: "#51459E !important",
    textTransform: "none !important",
    borderRadius: "40px !important",
    "&:hover": {
      backgroundColor: "black !important",
      color: "#ffffff !important",
    },
    marginTop: "20px !important",
  },
  selectedRow: {
    backgroundColor: "#51459e20 !important",
  },
  textfield: {
    marginTop: "3px",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      /* border: 'none',   */
      borderRadius: "20px",
    },
    "& input": {
      paddingLeft: "0px !important",
    },
  },
  customTextField: {
    border: "1px solid #d5d5d5 !important",
    borderRadius: "15px !important",
    /* height:"1% !important", */
    "& .MuiOutlinedInput-root": {
      border: "1px #d5d5d5",
      borderRadius: "15px !important",
      padding: "0px",
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        /* border: 'none',   */
        borderRadius: "20px",
      },
    },
  },
  available: {
    background: "#DDDDDD",
    padding: "4px 8px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#51459E",
    width: "100%",
  },
  searchIcon: {
    position: "absolute",
    right: "38px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  closeIcon: {
    position: "absolute",
    right: "8px", // Adjust to align icon properly
    top: "50%",
    transform: "translateY(-50%)",
    color: "#52459f",
  },
  circleIcon: {
    fill: "#51459E !important",
  },
  addtxt: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  inputInput: {
    border: "1px solid #ddd !important",
    color: "#4F4F4F",
    fontSize: "14px",
    borderRadius: "15px !important",
    lineHeight: "38px",
    height: "40px !important",
    fontWeight: "normal",
    textDecoration: "inherit",
    textTransform: "none",
    // margin: "10px 0px",
    padding: "0 32px 0 16px",
    width: " -webkit-fill-available",
    background: " #FFF !important",
    "&:focus": {
      boxShadow:
        "0 0 0 0.11rem #fff, 0 0 0 0.18rem #295ED9, 0 0 0 0.3rem #295ED91a",
      outline: "none",
    },
  },
  avatar: {
    marginRight: "15px",
  },
  accountCard: {
    border: "1px solid #DDDDDD !important",
    borderRadius: "16px !important",
    paddingBottom: "20px",
    "&:hover": {
      backgroundColor: "#51459e20 !important", // Hover effect for Card
    },
  },
  b_heading: {
    color: "#52459f !important",
    padding: "8px !important",
    marginBottom: "10px",
    fontSize: "14px !important",
    fontWeight: "600 !important",
    backgroundColor: "#dddd",
    display: "flex",
    alignItems: "center",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "end",
    width: "15%",
  },
  acContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent !important", // Ensure no conflicting background color
    padding: "22px 16px 3px 16px !important",
    "& .MuiCardContent-root:last-child ": { paddingBottom: 0 },
  },
  //review page styling
  Reviewheader: {
    color: "#52459f",
    fontWeight: "700 !important",
    fontSize: "15px !important",
  },
  headerstyle: {
    fontWeight: "600 !important",
    fontSize: "14px !important",
    lineHeight: "2em !important",
  },
  contentstyle: {
    fontSize: "13px",
  },
  checkboxStyles: {
    "&.MuiCheckbox-root": {
      color: "#52459F !important",
    },
    "&[class*='MuiCheckbox-root']": {
      color: "#52459F !important",
    },
  },
  checkMark: {
    width: "15px !important",
    paddingLeft: "10px !important",
  },
  outlinedEditable: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#000000de", // Set the border color for editable mode
        borderWidth: "1px", // Make the border thicker when editable
      },
      "&:hover fieldset": {
        borderColor: "#000000de", // Border color on hover when editable
      },
      "&.Mui-focused fieldset": {
        borderColor: "#000000de", // Focus state border color
      },
      backgroundColor: "#ffffff", // White background when editable
    },
    "& .MuiInputBase-input": {
      color: "#000000", // Ensure text color is black when editable
    },
  },
  textfieldNew: {
    width: "80%",
    marginBottom: theme.spacing(2),
  },
  textfieldDisable: {
    marginTop: "3px",
    "& fieldset": {
      border: "none",
    },
  },
  disabled: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none", // Remove the border when disabled
      },
      backgroundColor: "#f0f0f0", // Light gray background when disabled
    },
    "& .MuiInputBase-input": {
      color: "#a0a0a0", // Gray text color when disabled
    },
  },
  successDialog: {
    "&  div[class*='MuiPaper-elevation']": {
      width: "45% !important",
    },
  },
  /* beneficiary screen styles  */
  pageNation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  pageCount: {
    margin: "0 16px !important ",
  },
  searchbar: {
    position: "relative",
    marginTop: "10px",
  },
  newBeneficiary: {
    marginTop: "10px",
  },
  BeneficiaryItems: {
    display: "flex",
    gap: "10px",
  },
  cardContent: {
    display: "flex",
    width: "80%",
  },
  Fullname: {
    color: "#222",
    fontWeight: "600 !important ",
    fontSize: "14px !important ",
  },
  Accountnumber: {
    color: "#52459f",
    fontSize: "13px !important ",
    fontWeight: "400 !important ",
  },
  LaunchOutlinedIcon: {
    color: "#52459f",
    marginRight: "14px",
    cursor: "pointer",
  },
  Deletedicon: {
    color: "#52459f",
    cursor: "pointer",
  },
  cancelStyle: {
    marginLeft: "20px",
  },
  BackStyle: {
    marginLeft: "7px !important ",
  },
  /* country specific feilds */
  textFont: {
    fontSize: "13px !important ",
  },
  Gap: {
    marginLeft: "60px !important",
  },
  countryFlag: {
    width: "20px !important",
    height: "20px !important",
    borderRadius: " 50% !important",
    objectFit: "cover !important ",
  },
  Currency: {
    marginLeft: "5px !important ",
    width: "20px !important ",
    height: "20px !important ",
    borderRadius: "50% !important",
    marginTop: "5px !important ",
  },
  InfoOutlined: {
    color: "#52459f",
    fontSize: "1.2rem !important ",
    marginLeft: "0px !important ",
  },
  BankAddress: {
    fontSize: "13px !important ",
    // marginTop: "16px !important",
  },
  currencyStyle: {
    marginLeft: "5px",
    width: "20px !important ",
    height: "20px !important ",
    borderRadius: "50% !important ",
  },
  InfoOutlinedIcon: {
    color: "blue !important ",
    fontSize: "1rem !important ",
    marginLeft: "5px",
  },
  purposecodeMargin: {
    marginLeft: "34% !important ",
  },
  closeiconPosition: {
    position: "absolute !important ",
    right: 8,
    top: 8,
  },
  textCenter: {
    textAlign: "-webkit-center !important ",
  },
  /* Index styles */
  Tabsborder: {
    borderBottom: "2px solid #ededed !important ",
  },
  CotntentPadding: {
    padding: "0px 20px 10px 20px !important ",
  },
  finalScreen: {
    position: "relative !important ",
    padding: "20px !important ",
    borderRadius: "8px !important ",
  },
  ScreenStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    zIndex: 1,
  },
  TransactionHeader: {
    fontSize: "16px !important ",
    color: "#52459f",
    fontWeight: "700 !important ",
  },
  flagAlignments: {
    display: "flex !important",
    gap: "10px !important",
  },
  addressFlag: {
    // paddingTop: "13px !important",
  },
  infoIcon: {
    fill: "#52439E !important",
    height: "20px",
  },
  required: {
    color: "red",
    fontWeight: "bold",
  },
}));
export default WebStyles;
