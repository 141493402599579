import { createSlice } from "@reduxjs/toolkit";

const mobileSlice = createSlice({
  name: "mobileSlice",
  initialState: {
    ismobile: false,
  },
  reducers: {
    setIsMobile: (state, action) => {
      state.ismobile = action.payload;
    },
  },
});

export const { setIsMobile } = mobileSlice.actions;
export default mobileSlice.reducer;
