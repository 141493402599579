import React from "react";
import Usa from "../../CountrySpecificFields/Usa";
import Japan from "../../CountrySpecificFields/Japan";
import Qatar from "../../CountrySpecificFields/Qatar";
import Europe from "../../CountrySpecificFields/Europe";
import Australia from "../../CountrySpecificFields/Australia";
import Bangladesh from "../../CountrySpecificFields/Bangladesh";
import Brazil from "../../CountrySpecificFields/Brazil";
import Canada from "../../CountrySpecificFields/Canada";
import China from "../../CountrySpecificFields/China";
import Ghana from "../../CountrySpecificFields/Ghana";
import HongKong from "../../CountrySpecificFields/HongKong";
import India from "../../CountrySpecificFields/India";
import Malaysia from "../../CountrySpecificFields/Malaysia";
import Mexico from "../../CountrySpecificFields/Mexico";
import Morocco from "../../CountrySpecificFields/Morocco";
import Philippines from "../../CountrySpecificFields/Philippines";
import Singapore from "../../CountrySpecificFields/Singapore";
import SouthAfrica from "../../CountrySpecificFields/SouthAfrica";
import SriLanka from "../../CountrySpecificFields/SriLanka";
import UnitedArabEmirates from "../../CountrySpecificFields/UnitedArabEmirates";
import Argentina from "../../CountrySpecificFields/Argentina";
import Egypt from "../../CountrySpecificFields/Egypt";
import Georgia from "../../CountrySpecificFields/Georgia";
import Guatemala from "../../CountrySpecificFields/Guatemala";
import Israel from "../../CountrySpecificFields/Israel";
import Turkey from "../../CountrySpecificFields/Turkey";
import UnitedKingdom from "../../CountrySpecificFields/UnitedKingdom";
import { useSelector } from "react-redux";
import AccountNumberFeild from "../../CountrySpecificFields/CommonFields/AccountNumberFeild";

const CountrySpecificComponent = (props) => {
  const {
    editableBeneDetails,
    setEditableBeneDetails,
    payAgainOrRepeatTransaction,
    isEditable,
    review,
    reviewDetails,
    handleInputChange,
    flag,
    nextAssignmentId,

    etag,
    // below is for  add/new beneficiary
    addNewBene,
    // newBeneCountryName,
    handleChange,
    handleRefreshInputBlur,
    handleValidate,
    newCountryName = "",
  } = props;

  const newBeneData = useSelector((data) => data?.addNewBeneficiary);
  console.log(newCountryName);
  let newBeneCountryName = newBeneData?.countryName || newCountryName;
  let countryName = review
    ? reviewDetails?.ReceiversCountryName
    : editableBeneDetails?.Country;

  const europeCountryNames = [
    "Austria",
    "Belgium",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Latvia",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "Ukraine",
  ];

  countryName = europeCountryNames.includes(countryName)
    ? "Europe"
    : countryName;
  newBeneCountryName = europeCountryNames.includes(newBeneCountryName)
    ? "Europe"
    : newBeneCountryName;

  return (
    <>
      {(countryName === "United States" ||
        newBeneCountryName === "United States") && (
        <Usa
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Japan" || newBeneCountryName === "Japan") && (
        <Japan
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Qatar" || newBeneCountryName === "Qatar") && (
        <Qatar
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Europe" || newBeneCountryName === "Europe") && (
        <Europe
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Australia" || newBeneCountryName === "Australia") && (
        <Australia
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Bangladesh" ||
        newBeneCountryName === "Bangladesh") && (
        <Bangladesh
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Brazil" || newBeneCountryName === "Brazil") && (
        <Brazil
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Canada" || newBeneCountryName === "Canada") && (
        <Canada
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "China" || newBeneCountryName === "China") && (
        <China
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Ghana" || newBeneCountryName === "Ghana") && (
        <Ghana
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Hong Kong" || newBeneCountryName === "Hong Kong") && (
        <HongKong
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "India" || newBeneCountryName === "India") && (
        <India
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Malaysia" || newBeneCountryName === "Malaysia") && (
        <Malaysia
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Mexico" || newBeneCountryName === "Mexico") && (
        <Mexico
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Morocco" || newBeneCountryName === "Morocco") && (
        <Morocco
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Philippines" ||
        newBeneCountryName === "Philippines") && (
        <Philippines
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Singapore" || newBeneCountryName === "Singapore") && (
        <Singapore
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "South Africa" ||
        newBeneCountryName === "South Africa") && (
        <SouthAfrica
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Sri Lanka" || newBeneCountryName === "Sri Lanka") && (
        <SriLanka
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "United Arab Emirates" ||
        newBeneCountryName === "United Arab Emirates") && (
        <UnitedArabEmirates
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Argentina" || newBeneCountryName === "Argentina") && (
        <Argentina
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Egypt" || newBeneCountryName === "Egypt") && (
        <Egypt
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Georgia" || newBeneCountryName === "Georgia") && (
        <Georgia
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Guatemala" || newBeneCountryName === "Guatemala") && (
        <Guatemala
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Israel" || newBeneCountryName === "Israel") && (
        <Israel
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "Turkey" || newBeneCountryName === "Turkey") && (
        <Turkey
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
      {(countryName === "United Kingdom" ||
        newBeneCountryName === "United Kingdom") && (
        <UnitedKingdom
          editableBeneDetails={editableBeneDetails}
          setEditableBeneDetails={setEditableBeneDetails}
          reviewDetails={reviewDetails}
          isEditable={isEditable}
          review={review}
          handleInputChange={handleInputChange}
          flag={flag}
          payAgainOrRepeatTransaction={payAgainOrRepeatTransaction}
          nextAssignmentId={nextAssignmentId}
          etag={etag}
          //add new beneficiary props
          addNewBene={addNewBene}
          handleChange={handleChange}
          handleRefreshInputBlur={handleRefreshInputBlur}
          handleValidate={handleValidate}
        />
      )}
    </>
  );
};

export default CountrySpecificComponent;
