import React from "react";
import Grid from "@mui/material/Grid2";
import MobileStyles from "../MobileStyles";
import WebStyles from "../WebStyles";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

const AgenciaNumberField = (props) => {
  const selector = useSelector((data) => data?.mobileReducer);
  const isMobile = selector?.ismobile;
  const classes = isMobile ? MobileStyles() : WebStyles();
  const { editableBeneDetails, SelectedBeneDetails, flag } = props;

  return (
    <Grid size={{ xs: 12, md: 6 }} className={classes.spacing}>
      <Typography variant="body2" className={classes.headerstyle}>
        Agencia Number
      </Typography>
      {editableBeneDetails?.ClrSysMmbId ? (
        (<span className={classes.contentstyle}>
          {editableBeneDetails?.ClrSysMmbId}
        </span>
      )) : SelectedBeneDetails?.ClrSysMmbId ? (
        <span className={classes.contentstyle}>
          {SelectedBeneDetails?.ClrSysMmbId}
        </span>
      ) : (
        "---"
      )}
      {flag && editableBeneDetails?.IsValidBeneAddressURL && (
        <img
          src={editableBeneDetails?.IsValidBeneAddressURL}
          alt="checkmark"
          className={classes.checkMark}
        />
      )}
    </Grid>
  );
};

export default AgenciaNumberField;
